import {Cellule} from "../../../../types/cellule";
import {useDisclosure} from "@mantine/hooks";
import React, {forwardRef, ReactElement, useEffect, useState} from "react";
import ajax from "../../../../services/AxiosInterceptor";
import {Statut_cellule} from "../../../../types/statut_cellule";
import {
    CloseButton,
    Group,
    Modal,
    NumberInput,
    Select, SimpleGrid,
    Switch, TextInput,
} from "@mantine/core";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;
import {Extension} from "../../../../types/extension";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import "./modale_configurer_cellule.scss"
import {
    Modale_horaire_maintenance
} from "../../../../components/Modales/Modale_horaire_maintenance/Modale_horaire_maintenance";
import {horaire_maintenance} from "../../../../types/condition";
import {condition_unit} from "./modale_configurer_conditions";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {IconeCircleInfo} from "../../../../components/icone/solid_react/circle-info";
import {IconeStopwatch} from "../../../../components/icone/solid_react/stopwatch";
import {Sonde} from "../../../../types/sonde";
import {setPasApasEtat} from "../../../../store/pasApasSlice";
import {useDispatch} from "react-redux";

export function Modale_configurer_conditions_rapides(
    {cellule, setCellule, forcer, extension, children, etape}:
        {
            cellule: Cellule,
            setCellule: (cellule: Cellule | null) => void,
            forcer: boolean,
            extension?: string | null,
            children: ReactElement, etape?: number
        }
) {
    const [opened, {open, close}] = useDisclosure(forcer);
    const [btn_loading, set_loading_btn] = useState(false);

    const [status, set_statuts] = useState<Statut_cellule[] | null>(null);

    const [statutChoisi, setStatutChoisi] = useState<Statut_cellule | null>(cellule?.statut_actuel?.statut ?? null)

    type Data_select_extension = { image: string, label: string, value: string, description: string }[];
    const [extensions_toutes, setExtensions_toutes] = useState<Extension[] | null>(null);
    const [data_select_extension, setData_select_extension] = useState<Data_select_extension | null>(null);
    const [value_sonde, setValue_sonde] = useState<string | null>(extension ? extension : null);


    const [nom, set_nom] = useState(cellule.nom === "Nouvelle cellule" ? "" : cellule.nom);
    const [configRapide, setConfigRapide] = useState(cellule.config_rapide);

    const [clientValide, setClientValide] = useState(true);

    const [active, setActive] = useState(etape ?? 0);


    let cr_min_index = cellule.conditions.filter(c => c.slug === 0)[0];
    let cr_max_index = cellule.conditions.filter(c => c.slug === 1)[0];
    let cr_etat_index_normal = cellule.conditions.filter(c => c.slug === 2)[0];
    let cr_etat_index_anormal = cellule.conditions.filter(c => c.slug === 3)[0];

    let cr_etat_index_bool_actif = null;
    if (cr_etat_index_normal) cr_etat_index_bool_actif = cr_etat_index_normal;
    if (cr_etat_index_anormal) cr_etat_index_bool_actif = cr_etat_index_anormal;

    let cr_delais_horaire = cr_min_index ?? (cr_max_index ?? (cr_etat_index_normal ?? cr_etat_index_anormal)); //Si un seul des deux est actif

    const [cr_min, setCRMIN] = useState(cr_min_index ? parseInt(cr_min_index.valeur_a_comparer) : 0);
    const [cr_max, setCRMAX] = useState(cr_max_index ? parseInt(cr_max_index.valeur_a_comparer) : 0);
    const [cr_min_etat, setCRMIN_Etat] = useState(cr_min_index ? true : false);
    const [cr_max_etat, setCRMAX_Etat] = useState(cr_max_index ? true : false);
    const [cr_etat_anormal, setCR_Etat_anormal] = useState(cr_etat_index_normal == null || cr_etat_index_anormal != null);
    const [cr_etat_anormal_texte, setCr_etat_anormal_texte] = useState(cr_etat_index_bool_actif?.texte_a_afficher ?? "");
    const [cr_etat_anormal_active, setCR_Etat_anormal_active] = useState(cr_etat_index_normal != null || cr_etat_index_anormal != null);
    const [cr_duree, setCR_Duree] = useState(cr_delais_horaire ? cr_delais_horaire.delais : 60);
    const [cr_horaires, setCR_Horaires] = useState<horaire_maintenance[]>(cr_delais_horaire ? cr_delais_horaire.horaires_maintenance : []);


    const [horaireInverser, set_horaireInverser] = useState<boolean>(cellule.inverserHorairesMaintenance);
    useEffect(() => {
        set_horaireInverser(cellule.inverserHorairesMaintenance);
    }, [cellule]);

    const [hide_modale, set_hide_modale] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!opened && active > 1) {
            setActive(etape ?? 0);

            set_nom(cellule.nom === "Nouvelle cellule" ? "" : cellule.nom);
            setConfigRapide(cellule.config_rapide);

            let cr_min_index = cellule.conditions.filter(c => c.slug === 0)[0];
            let cr_max_index = cellule.conditions.filter(c => c.slug === 1)[0];
            let cr_etat_index_normal = cellule.conditions.filter(c => c.slug === 2)[0];
            let cr_etat_index_anormal = cellule.conditions.filter(c => c.slug === 3)[0];

            let cr_delais_horaire = cr_min_index ?? (cr_max_index ?? (cr_etat_index_normal ?? cr_etat_index_anormal)); //Si un seul des deux est actif

            setCRMIN(cr_min_index ? parseInt(cr_min_index.valeur_a_comparer) : 0);
            setCRMAX(cr_max_index ? parseInt(cr_max_index.valeur_a_comparer) : 0);
            setCRMIN_Etat(cr_min_index ? true : false);
            setCRMAX_Etat(cr_max_index ? true : false);
            setCR_Etat_anormal(cr_etat_index_normal == null || cr_etat_index_anormal != null);
            setCR_Etat_anormal_active(cr_etat_index_normal != null || cr_etat_index_anormal != null);
            setCR_Duree(cr_delais_horaire ? cr_delais_horaire.delais : 60);
            setCR_Horaires(cr_delais_horaire ? cr_delais_horaire.horaires_maintenance : []);
        }


    }, [cellule])


    useEffect(() => {
        if (opened)
        {
            dispatch(setPasApasEtat("modale_anomalie_open"))
        }
    }, [opened]);

    function handleEnregistrer() {
        set_loading_btn(true);


        let conditions: condition_unit[] = [];
        // let extension = extensions_toutes?.filter(ext => ext.id + "" === value_sonde)[0];
        let extension = cellule.extension;
        const cr_min_index = cellule.conditions.filter(c => c.slug === 0)[0];
        const cr_max_index = cellule.conditions.filter(c => c.slug === 1)[0];
        let cr_bool_index = cellule.conditions.filter(c => c.slug === 2)[0];
        if (!cr_bool_index) cr_bool_index = cellule.conditions.filter(c => c.slug === 3)[0];

        if (extension && extension.sonde?.type_de_donnee === "bool" && extension.cellule) {
            let affichage = cr_etat_anormal ? extension?.cellule.affichage_vrai : extension?.cellule.affichage_faux;
            if (cr_etat_anormal_active) conditions.push(
                {
                    "index": cr_bool_index ? cr_bool_index.id : -1, //On retrouve l'index pour garder les alertes
                    "nom": affichage,
                    "texte_a_afficher": cr_etat_anormal_texte,
                    "type": cr_etat_anormal ? "3" : "2",
                    "valeur": cr_min,
                    "duree": cr_duree,
                    repetition: false,
                    "horaire": cr_horaires,
                    "alertes": [] //Est là pour le front, le back ignore les alertes ici
                }
            )
        }
        if (extension && extension.sonde?.type_de_donnee === "number") {
            if (cr_min_etat) conditions.push(
                {
                    "index": cr_min_index ? cr_min_index.id : -1, //On retrouve l'index pour garder les alertes
                    "nom": "MIN",
                    "texte_a_afficher": "La cellule " + nom + " est en dessous de " + extension?.sonde?.affichage_prefix + cr_min + extension?.sonde?.affichage_suffix + " depuis " + cr_duree + " minutes",
                    "type": "0",
                    "valeur": cr_min,
                    "duree": cr_duree,
                    "horaire": cr_horaires,
                    repetition: false,
                    "alertes": [] //Est là pour le front, le back ignore les alertes ici
                }
            )
            if (cr_max_etat) conditions.push(
                {
                    "index": cr_max_index ? cr_max_index.id : -2,
                    "nom": "MAX",
                    "texte_a_afficher": "La cellule " + nom + " est au dessus de " + extension?.sonde?.affichage_prefix + cr_max + extension?.sonde?.affichage_suffix + " depuis " + cr_duree + " minutes",
                    "type": "1",
                    "valeur": cr_max,
                    "duree": cr_duree,
                    "horaire": cr_horaires,
                    repetition: false,
                    "alertes": [] //Est là pour le front, le back ignore les alertes ici
                }
            )
        }


        ajax.put('/cellules/' + cellule.id + '/conditions', {
            conditions: conditions,
            inverserHoraire: horaireInverser
        })
            .then(response_cond => {
                set_loading_btn(false);

                console.log(response_cond.data.cellule)
                setCellule(response_cond.data.cellule);

                notifications.show({
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'green',
                    title: "Validé!",
                    message: 'Les anomalies sont enregistées.',
                    icon: <IconeCheck/>,
                });

                close();

            }).catch(error => {
            set_loading_btn(false);
            console.log(error);
        });


    }

    function passerEnConfigAvancee()
    {
        ajax.put('/cellules/' + cellule.id + '/statut', {
            "config_rapide": false
        })
            .then(response => {
                notifications.show({
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'green',
                    title: "Configuration avancée activée",
                    message: 'La fenêtre à été remplacé par une nouvelle.',
                    icon: <IconeCheck/>,
                });
                setCellule(response.data.cellule);
                close();
            })
            .catch(err => {});
    }


    return (
        <>
            <Modal opened={opened} onClose={close} withCloseButton={false}
                   className={"mesure_config_modale" + (hide_modale ? " modal_hide" : "")}>
                <div className={"modal-en-tete-custom"}>
                    <p className="titre">Configurer les anomalies</p>

                    <button className={"sec hide_on_mobile"} onClick={passerEnConfigAvancee}>Passer en configuration avancée</button>
                    <Group justify="center">
                        <CloseButton aria-label="Fermer la fenetre" onClick={close}/>
                    </Group>
                </div>


                <Configurer_conditions_rapides cellule_nom={cellule.nom} cellule={cellule} cr_min_etat={cr_min_etat} setCRMIN_Etat={setCRMIN_Etat}
                                               cr_min={cr_min} setCRMIN={setCRMIN} cr_max_etat={cr_max_etat}
                                               setCRMAX_Etat={setCRMAX_Etat} cr_max={cr_max} setCRMAX={setCRMAX}
                                               cr_etat_anormal_active={cr_etat_anormal_active}
                                               setCR_Etat_anormal_active={setCR_Etat_anormal_active}
                                               cr_etat_anormal={cr_etat_anormal} setCR_Etat_anormal={setCR_Etat_anormal}
                                               cr_duree={cr_duree} setCR_Duree={setCR_Duree} cr_horaires={cr_horaires}
                                               setCR_Horaires={setCR_Horaires} set_hide_modale={set_hide_modale}
                                               configRapide={configRapide} sonde={cellule.extension?.sonde} setCR_Etat_anormal_texte={setCr_etat_anormal_texte} cr_etat_anormal_texte={cr_etat_anormal_texte}
                                               set_horaireInverser={set_horaireInverser} horaireInverser={horaireInverser} affichage_vrai={cellule.affichage_vrai} affichage_faux={cellule.affichage_faux}
                />

                <button className={"sec only_mobile"} onClick={passerEnConfigAvancee}>Passer en configuration avancée</button>

                <div className={"en-ligne spacebetween"} style={{marginTop: "2rem"}}>
                    <button className={"sec noMargin big"} onClick={close}>Retour</button>
                    <Loading_button is_loading={btn_loading}
                                    className={"noMargin big"}
                                    onClick={handleEnregistrer}>Enregistrer</Loading_button>
                </div>


            </Modal>

            <button className={"sec"} onClick={open}>{children}</button>

        </>
    );
}


export function Configurer_conditions_rapides(
    {
        cellule, cellule_nom, cr_min, cr_min_etat, setCRMIN_Etat, setCRMIN, setCRMAX_Etat, setCRMAX, cr_max_etat, cr_max,
        setCR_Etat_anormal_active, cr_etat_anormal_active, setCR_Etat_anormal, cr_etat_anormal, cr_duree, affichage_vrai, affichage_faux,
        setCR_Duree, setCR_Horaires, cr_horaires, set_hide_modale, configRapide, sonde, setCR_Etat_anormal_texte, cr_etat_anormal_texte, horaireInverser, set_horaireInverser
    }:
        {
            cellule:Cellule,
            cellule_nom:string,
            cr_min_etat: boolean,
            setCRMIN_Etat: (etat: boolean) => void,
            cr_min: number,
            setCRMIN: (val: number) => void,
            cr_max_etat: boolean,
            setCRMAX_Etat: (etat: boolean) => void,
            cr_max: number,
            setCRMAX: (val: number) => void,
            cr_etat_anormal_active: boolean,
            setCR_Etat_anormal_active: (etat: boolean) => void,
            cr_etat_anormal: boolean,
            setCR_Etat_anormal: (etat: boolean) => void,
            cr_etat_anormal_texte: string,
            setCR_Etat_anormal_texte: (etat: string) => void,
            cr_duree: number,
            setCR_Duree: (etat: number) => void,
            cr_horaires: horaire_maintenance[],
            setCR_Horaires: (etat: horaire_maintenance[]) => void,
            set_hide_modale: (etat: boolean) => void,
            configRapide: boolean,
            sonde:Sonde | undefined,
            horaireInverser: boolean,
            set_horaireInverser: (horaireInverser: boolean) => void,
            affichage_vrai: string,
            affichage_faux: string,
        }
) {

    function genererConditionsRapides() {

        if (sonde && sonde.type_de_donnee === "number") {
            return (
                <>
                    <SimpleGrid type={"container"} cols={{base: 2, '1px': 1, '800px': 2}}>
                        <div style={{
                            padding: "1rem",
                            borderRadius: "var(--border-radius)",
                            border: "solid 1px var(--main-color)"
                        }}>
                            <Switch
                                label={sonde.slug_type[0].toUpperCase() + sonde.slug_type.substring(1) + " minimum"}
                                checked={cr_min_etat}
                                onChange={e => setCRMIN_Etat(e.target.checked)}
                            />
                            <NumberInput
                                description="-	La valeur la plus basse tolérée. Au-delà, une alerte sera envoyée."
                                defaultValue={0}
                                leftSection={"" + sonde.affichage_prefix + sonde.affichage_suffix}
                                value={cr_min}
                                onChange={value => setCRMIN(Number(value))}
                                size="xs"
                                disabled={!cr_min_etat}
                            />
                        </div>
                        <div style={{
                            padding: "1rem",
                            borderRadius: "var(--border-radius)",
                            border: "solid 1px var(--main-color)"
                        }}>
                            <Switch
                                label={sonde.slug_type[0].toUpperCase() + sonde.slug_type.substring(1) + " maximum"}
                                checked={cr_max_etat}
                                onChange={e => setCRMAX_Etat(e.target.checked)}
                            />
                            <NumberInput
                                description="-	La valeur la plus haute tolérée. Au-delà, une alerte sera envoyée."
                                defaultValue={0}
                                leftSection={"" + sonde.affichage_prefix + sonde.affichage_suffix}
                                value={cr_max}
                                onChange={value => setCRMAX(Number(value))}
                                size="xs"
                                disabled={!cr_max_etat}
                            />
                        </div>
                    </SimpleGrid>


                </>
            )
        }
        if (sonde && sonde.type_de_donnee === "bool") {
            return (
                <>
                    <div style={{
                        padding: "1rem",
                        borderRadius: "var(--border-radius)",
                        border: "solid 1px var(--main-color)"
                    }}>
                        <Switch
                            label={"Configurer un état anormal"}
                            checked={cr_etat_anormal_active}
                            onChange={e => {
                                setCR_Etat_anormal_active(e.target.checked)
                                if (cr_etat_anormal_texte == "") {
                                    setCR_Etat_anormal_texte("La cellule " + cellule_nom + " est " + sonde.affichage_prefix + (cr_etat_anormal ? affichage_vrai.toLowerCase() : affichage_faux.toLowerCase()) + sonde.affichage_suffix + " depuis " + cr_duree + " minutes")
                                }
                            }}
                        />

                        <Select
                            label="Il y a un problème quand :"
                            data={[
                                {
                                    value: "faux",
                                    label: 'Les mesures indiquent : "' + affichage_faux + '"'
                                },
                                {
                                    value: "vrai",
                                    label: 'Les mesures indiquent : "' + affichage_vrai + '"'
                                },
                            ]}
                            value={cr_etat_anormal ? "vrai" : "faux"}
                            onChange={value => {
                                setCR_Etat_anormal(value === "vrai")
                                setCR_Etat_anormal_texte("La cellule " + cellule_nom + " est " + sonde.affichage_prefix + (cr_etat_anormal ? affichage_faux.toLowerCase() : affichage_vrai.toLowerCase()) + sonde.affichage_suffix + " depuis " + cr_duree + " minutes")
                            }}
                            className={"match_height"}
                            disabled={!cr_etat_anormal_active}
                        />

                        <TextInput
                            label={"Texte à afficher dans les alertes : "}
                            value={cr_etat_anormal_texte}
                            onChange={e => setCR_Etat_anormal_texte(e.target.value)}
                            disabled={!cr_etat_anormal_active}
                        />
                    </div>
                </>
            )
        }

    }

    return (
        <>
            {configRapide
                ? (
                    <>
                        {genererConditionsRapides()}

                        {(cr_max_etat || cr_min_etat || cr_etat_anormal_active) && (
                            <SimpleGrid type={"container"} cols={{base: 2, '1px': 1, '800px': 2}}
                                        style={{marginTop: "1rem"}}>
                                <NumberInput
                                    label="Durée en minutes avant le déclenchement des alertes"
                                    defaultValue={60}
                                    min={0}
                                    step={15}
                                    leftSection={<IconeStopwatch/>}
                                    value={cr_duree}
                                    onChange={value => setCR_Duree(Number(value))}
                                    size="xs"
                                />


                                <div style={{marginTop: "0.3rem"}}>
                                    <Modale_horaire_maintenance horaires={cr_horaires}

                                                                inverserHoraire={horaireInverser}
                                                                setInverserHoraire={set_horaireInverser}

                                                                setHoraires={values => setCR_Horaires(values)}
                                                                setFlou={set_hide_modale}/>
                                </div>


                            </SimpleGrid>
                        )}

                    </>
                )
                : (
                    <p className={"notice green"} style={{marginTop: "1rem"}}>La configuration avancée
                        est
                        activée.
                        Vous pourrez configurer les anomalies depuis la fenêtre dédiée, située dans le
                        menu
                        sur la page de la cellule, une fois la configuration actuelle terminée. </p>
                )}

            {(cr_max_etat || cr_min_etat || cr_etat_anormal_active) && (

                <p className="inline-tag" style={{marginTop: "1rem"}}>
                    <IconeCircleInfo/>Pensez à activer les alertes pour
                    être informé des anomalies au plus vite : sur la page de la cellule ou en masse
                    depuis la page de votre organisation.
                </p>
            )}
        </>
    )
}