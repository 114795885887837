import {Navbar} from "../../../components/navbar/navbar";
import React, {useEffect, useMemo, useState} from "react";

import './Paiements.scss'
import ajax from "../../../services/AxiosInterceptor";

import {Link, useLocation, useNavigate} from "react-router-dom";
import {IconeLoader} from "../../../components/icone/icone";
import {Bandeau_user} from "../../../components/sidebar/bandeau_user/bandeau_user";
import {useDispatch, useSelector} from "react-redux";
import {setMobile_sidebar_icon} from "../../../store/interfaceSlice";
import {RootState} from "../../../store/store";
import {User} from "../../../types/user";
import {Organisation, Organisation_light, Organisation_paiement, Paiementtype} from "../../../types/organisation";
import {Loading_div} from "../../../components/loading_div/loading_div";
import {
    Autocomplete,
    Collapse,
    Combobox,
    Input,
    InputBase,
    Modal,
    NativeSelect,
    Select, Switch,
    Tabs, TextInput,
    useCombobox
} from "@mantine/core";
import {afficherDate, afficherDateTime, DateTZ} from "../../../services/GestionDates";
import {texte_est_inclus, last_mesure_get_couleur} from "../../../services/GestionTextes";
import {Modale_recharger_balance} from "./modale_recharger_balance";
import {permissionIsGranted} from "../../../services/permissionsService";
import {IconeMagnifyingGlass} from "../../../components/icone/solid_react/magnifying-glass";
import {IconeToggleOff} from "../../../components/icone/solid_react/toggle-off";
import {IconeList} from "../../../components/icone/solid_react/list";
import {IconeListCheck} from "../../../components/icone/solid_react/list-check";
import {IconeEye} from "../../../components/icone/solid_react/eye";
import {IconeCircleInfo} from "../../../components/icone/solid_react/circle-info";
import {Capteur} from "../../../types/capteur";
import {Statut_cellule} from "../../../types/statut_cellule";
import {IconeCaretRight} from "../../../components/icone/solid_react/caret-right";
import {IconeCaretDown} from "../../../components/icone/solid_react/caret-down";
import {Modale_promo_nbe_capteurs} from "../../../components/modale_promo_nbe_capteurs/modale_promo_nbe_capteurs";
import {Modale_credit_sms_tel} from "./modale_credit_sms_tel";
import {editOrganisation, majSimpleOrganisation} from "../../../store/organisationSlice";
import {IconeCreditCard} from "../../../components/icone/solid_react/credit-card";
import {IconeBrakeWarning} from "../../../components/icone/solid_react/brake-warning";
import {IconeTriangleExclamation} from "../../../components/icone/solid_react/triangle-exclamation";
import {Modale_enregistrer_cb} from "./modale_enregistrer_cb";
import {estDesactivee} from "../Orga_admin/Orga_admin";
import {Cellule} from "../../../types/cellule";
import {useDisclosure} from "@mantine/hooks";
import {IconeTag} from "../../../components/icone/solid_react/tag";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {checkSiOrgaAMoyenDePaiement} from "../../../services/OrganisationServices";
import {Modale_transferer_sms} from "./modale_transferer_sms";
import {tva_pourcent} from "../../../services/UserService";
import {IconeDownload} from "../../../components/icone/solid_react/download";


export function Paiements() {

    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list.filter(o => o.orga_parent === null))

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("aucun"))

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const recherche_url = queryParams.get('organisation');

    const [activeTab, setActiveTab] = useState<string | null>(organisations[0] ? "orga-" + organisations[0].id : "pas");
    const [recherche_orga_nom, setRecherche_orga_nom] = useState('');
    const [orga_selectionnee, setOrga_selectionnee] = useState<string>((organisations.filter(o => o.id + "" === recherche_url)[0] && organisations.filter(o => o.id + "" === recherche_url)[0].id + "") ?? organisations[0].id + "")

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });


    useEffect(() => {
        if (organisations.filter(o => texte_est_inclus(o.id + "", orga_selectionnee))[0]){

            setActiveTab("orga-" + organisations.filter(o => texte_est_inclus(o.id + "", orga_selectionnee))[0].id)
        }
    }, [orga_selectionnee]);

    function checkSiDoitAfficherMarqueurPaiementRouge(o:Organisation)
    {
        return (o.etat === "Desac_paiement")

    }

    function checkSiDoitAfficherMarqueurPaiementJaune(o:Organisation)
    {
        if (o.balance > 0) return false;
        let CB_perime_date = o.cBPerime ? new DateTZ(o.cBPerime) : null;
        let CB_perime_comp = CB_perime_date ? CB_perime_date?.getFullYear() + "" + CB_perime_date?.getMonth() : null
        let now_comp = new DateTZ().getFullYear() + "" + new DateTZ().getMonth()
        if (!CB_perime_comp) return true;
        return (CB_perime_comp <= now_comp);

    }

    return (
        <div className={"app"}>
            <Navbar/>

            <div className={"content content-fullwidth en-tete-pages page_paiement"}>


                <div className={"en-ligne en-tete-page"}>
                    <div className={"titre"}>
                        <h1>Paiements</h1>
                        <p>
                            Voici les informations concernant les prélèvements et apport concernant vos organisations
                        </p>
                    </div>

                    <div className={"mini-sidebar"}>
                        <Bandeau_user/>
                    </div>


                </div>
                {organisations === null ? (
                    <div className={"en-ligne en-tete-page"}>

                        <div className={"en-ligne"}><IconeLoader /> Chargement...</div>


                    </div>
                ) : (
                    <div style={{margin: "1rem"}}>
                        {/*ORGA > balance > orgas enfants > liste cellules avec prix > tarifs totaux*/}
                        <div className={"recherche"}>
                            {/*<IconeMagnifyingGlass/>*/}
                            {/*<Select*/}
                            {/*       placeholder={"Recherche d'une organisation..."}*/}
                            {/*       className={"big_input"}*/}
                            {/*       value={orga_selectionnee}*/}
                            {/*       onChange={event => setOrga_selectionnee(event ?? "")}*/}
                            {/*       data={[{group: "Organisations disponible", items: organisations.map(o => {*/}
                            {/*                  return {value: o.id + "", label: "🛈 " + o.nom}*/}
                            {/*              })}]}*/}
                            {/*       leftSection={<IconeMagnifyingGlass/>}*/}
                            {/*       searchable*/}
                            {/*/>*/}
                            <Combobox
                                store={combobox}
                                onOptionSubmit={(val) => {
                                    setOrga_selectionnee(val ?? "")
                                    combobox.closeDropdown();
                                }}
                            >
                                <Combobox.Target>
                                    <Input
                                        component="button"
                                        type="button"
                                        pointer
                                        rightSection={<Combobox.Chevron />}
                                        rightSectionPointerEvents="none"
                                        leftSection={<IconeMagnifyingGlass/>}
                                        className={"paiement_input_recherche"}
                                        onClick={() => combobox.toggleDropdown()}
                                    >
                                        {organisations.find(o => o.id + "" === orga_selectionnee)?.nom
                                            || <Input.Placeholder>Recherche d'une organisation...</Input.Placeholder>}
                                    </Input>
                                </Combobox.Target>

                                <Combobox.Dropdown>
                                    <Combobox.Search
                                        value={recherche_orga_nom}
                                        onChange={(event) => setRecherche_orga_nom(event.currentTarget.value)}
                                        placeholder="Recherche d'une organisation..."
                                    />
                                    <Combobox.Options>
                                        {organisations.filter((o) => texte_est_inclus(o.nom, recherche_orga_nom)).length === 0 && <Combobox.Empty>Aucune organisation trouvée.</Combobox.Empty>}
                                        {organisations.filter((o) => texte_est_inclus(o.nom, recherche_orga_nom)).map((o) => (
                                        <Combobox.Option value={o.id + ""} key={o.id}
                                                         className={"en-ligne"}
                                                         style={checkSiDoitAfficherMarqueurPaiementRouge(o) ? {color: "var(--error-color)"} : checkSiDoitAfficherMarqueurPaiementJaune(o) ? {color: "var(--warning-color)"} : {}}>
                                            {(checkSiDoitAfficherMarqueurPaiementRouge(o) || checkSiDoitAfficherMarqueurPaiementJaune(o)) && (
                                                <IconeCircleInfo />
                                            )}
                                            {o.nom}
                                        </Combobox.Option>
                                    ))}</Combobox.Options>
                                </Combobox.Dropdown>
                            </Combobox>
                        </div>

                        {organisations.length <= 0 ? "Vous ne pouvez gérer aucune des organisations auquelles vous avez accès."
                            : (

                                <>
                                    {organisations.map(organisation => (
                                        <>

                                            {!permissionIsGranted(organisation.id, "Administrer_organisation") &&  (
                                                <>
                                                        <br />
                                                        <br />
                                                        <p className={"notice"}>Vous n'avez pas la permission de consulter ces données. </p>

                                                </>
                                            )}
                                            {permissionIsGranted(organisation.id, "Administrer_organisation") && activeTab && activeTab === "orga-" + organisation.id && (
                                                <Paiement_orga organisation={organisation}
                                                               key={"orga-" + organisation.id}/>
                                            )}
                                        </>
                                    ))}
                                </>
                            )}


                    </div>
                )}


            </div>
        </div>

    );
}

function Paiement_orga({organisation}: { organisation: Organisation }) {
    const navigate = useNavigate();

    const user = useSelector<RootState, User>(state => state.user)

    const [organisation_full, setOrganisationFull] = useState<Organisation_paiement | null>(null)
    //Organisation_toutes ne prend pas la liste de chargement car un user qui n'a pas accès à toutes les orga doit quand même pouvoir savoir les enfants.
    const [organisations_toutes, setOrganisations_toutes] = useState<(Organisation_light)[] | null>(null);
    const [capteurs, setCapteurs] = useState<(Capteur|undefined)[] | null>(null);
    const [capteurs_openned, setCapteurs_openned] = useState<string[]>([]);
    const [modale_paiements, setModalePaiements] = useState<Paiementtype | null>(null)
    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("aucun"))

    useEffect(() => {
        queryOrga();
    }, [organisation.id])

    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list)



    function queryOrga()
    {
        setOrganisationFull(null)
        ajax.get("/organisations/" + organisation.id + "/paiement" + (desac_paiement_veut_recup_mesures_degrade ? "?rattrapage=1" : "")).then(response => {
            let _orga_paiement:Organisation_paiement = response.data.organisation;
            setOrganisationFull(_orga_paiement);
            let _capteurs:(Capteur|undefined)[] = [];
            let _orgas:(Organisation_light)[] = [organisation];
            _orga_paiement.statuts?.map(s => {
                let _cap:Capteur|undefined = s.cellule?.extension?.capteur ? {...s.cellule?.extension?.capteur} : undefined;
                if (!_capteurs.find(c => {
                    return ((_cap === undefined && c === undefined) || (c?.uuid === _cap?.uuid))
                })){
                    if (_cap) _cap.organisation_id = s.cellule.organisation.id
                        if (_cap === undefined) {
                            if (_capteurs.filter(c => c===undefined).length === 0){
                                _capteurs.push(undefined)
                            }

                        }
                        else _capteurs.push(_cap)
                }
                if (!_orgas.find(c => c?.id == s.cellule.organisation.id)){
                    _orgas.push(s.cellule.organisation)
                }
            })
            _orga_paiement.statuts?.map(s => {
                let _cap:Capteur|undefined = s.cellule?.extension?.capteur ? {...s.cellule?.extension?.capteur} : undefined;
                if (!_capteurs.find(c => {
                    return ((_cap === undefined && c === undefined) || (c?.uuid === _cap?.uuid))
                })){
                    if (_cap) _cap.organisation_id = s.cellule.organisation.id
                        if (_cap === undefined) {
                            if (_capteurs.filter(c => c===undefined).length === 0){
                                _capteurs.push(undefined)
                            }

                        }
                        else _capteurs.push(_cap)
                }
                if (!_orgas.find(c => c?.id == s.cellule.organisation.id)){
                    _orgas.push(s.cellule.organisation)
                }
            })


            setCapteurs(_capteurs);
            setOrganisations_toutes(_orgas);

            dispatch(majSimpleOrganisation(_orga_paiement.organisation))


        }).catch(e => {
        })
    }


    const [desac_paiement_veut_recup_mesures_degrade, setDesac_paiement_veut_recup_mesures_degrade] = useState(false);
    useEffect(() => {
        queryOrga();
    }, [desac_paiement_veut_recup_mesures_degrade]);


    if (organisation_full === null) {
        return (
                <Loading_div/>
        )
    }
    
    let dernier_paiement = null;
    if (organisation_full?.paiements) dernier_paiement = organisation_full?.paiements[0]


    function total_par_capteur(orga:Organisation_paiement, capteur_uuid:string|undefined)
    {
        let compt = 0;
        orga.statuts?.filter(s => s.cellule?.extension?.capteur.uuid === capteur_uuid).map(statut => {
            compt += statut.prix_final
        })
        return Math.round(compt*100)/100;
    }


    return (
        <>
            <div className={"accueil_organisation"}>
                <div className={"en-tete-orga"}>
                    <h2>
                        {organisation_full.organisation.desactivation_texte !== null ? organisation_full.organisation.nom : ""}
                        {organisation_full.organisation.desactivation_texte !== null
                            && (!user.roles.includes("ROLE_ADMIN") && !user.roles.includes("ROLE_PRESTATAIRE")) && (
                                <span className={"inline-tag"}
                                      style={{color: "var(--warning-color)"}}>
                                <IconeToggleOff/> Organisation désactivée
                            </span>
                            )}
                    </h2>
                </div>


                {organisation_full.organisation.etat === "New" && organisation_full.organisation.prochainPrelevement !== null && (

                    <div className={"notice green"}>
                        Votre organisation est activée!
                        {organisation_full.organisation.balance > 0 ? (
                            <>
                                <br/>La balance de votre organisation est entière et sera prélevé ce soir à minuit pour
                                le mois à venir.
                            </>
                        ) : (
                            <>
                                <br/>Vous êtes libre de faire les configurations que vous souhaitez
                                jusqu'au {afficherDate(organisation_full.organisation.prochainPrelevement)}.
                                <br/>Vous serez ensuite débité pour les cellules actives, pour le mois à venir.
                            </>

                        )}

                    </div>
                )}


                {organisation_full.organisation.desactivation_texte && (
                    <p className={"notice"}>{organisation_full.organisation.desactivation_texte}</p>
                )}

                <div className={"en-ligne en-tete-page"} style={{margin: "0", width: "100%"}}>
                    <div className={"en-ligne"}>
                        <i className={"inline-tag " + (organisation_full.organisation.balance < 0 ? "red" : (organisation_full.organisation.balance > ((organisation_full?.total && organisation_full?.total.toFixed(2)) ?? 0) ? "green" : (checkSiOrgaAMoyenDePaiement(organisation_full.organisation.id) ? "green" : "orange")))}
                           style={{padding: "0.2rem 0.5rem"}}>
                            Balance actuelle
                            : {organisation_full.organisation.balance.toFixed(2)}€
                        </i>

                        <i
                            className={"inline-tag " + (organisation_full.organisation.credit_SMS === 0 ? "orange" : "green")}
                            style={{padding: "0.2rem 0.5rem"}}>
                            SMS : {organisation_full.organisation.credit_SMS}
                        </i>
                        <i
                            className={"inline-tag " + (organisation_full.organisation.credit_Tel === 0 ? "orange" : "green")}
                            style={{padding: "0.2rem 0.5rem"}}>
                            Appels : {organisation_full.organisation.credit_Tel}
                        </i>
                    </div>

                        <div className={"en-ligne"}>

                            <Modale_credit_sms_tel organisation_full={organisation_full} refresh={() => {
                                queryOrga();
                            }}/>
                            {user.roles.includes("ROLE_ADMIN") && (
                                <Modale_recharger_balance organisation_full={organisation_full} refresh={() => {
                                    queryOrga();
                                }}/>
                            )}

                        </div>

                </div>

                <div className={"en-ligne en-tete-page"} style={{margin: "0", width: "100%"}}>

                    <div>&nbsp;</div>

                    <Modale_promo_nbe_capteurs organisation_id={organisation.id}
                                               children={<button><IconeCircleInfo/> Promotions sur le nombre
                                                   d'enregistreurs</button>}
                    />

                </div>


                <Modale_enregistrer_cb organisation={organisation_full.organisation} refresh={queryOrga}/>



                {organisation_full.enfants.length > 0 && (
                    <div className={"en-ligne en-tete-page"} style={{margin: "0", width: "100%"}}>
                        <div className={"en-ligne"}>
                            Organisations enfants : {organisation_full.enfants.map(enfant => <i
                            className={"inline-tag green"} key={enfant.id}>
                            <strong>{enfant.nom}</strong>
                            {" "} | {enfant.credit_SMS} SMS, {enfant.credit_Tel} appels, <Modale_transferer_sms organisation_parent={organisation_full} organisation_enfant={enfant} refresh={queryOrga} />

                        </i>)}
                        </div>
                    </div>
                )}


                {organisation_full.organisation.etat === "New" && organisation_full.organisation.prochainPrelevement === null && (
                    <div className={"notice grey"}>
                        Votre organisation n'est pas encore activée. Elle s'activera
                        automatiquement dès que vous configurerez une cellule.
                    </div>
                )}

                {organisation_full.organisation.etat === "Desac_paiement" && (
                    <div className={"notice red"}>
                        Votre organisation est bloquée.
                        <>
                            <br/>Une tentative de prélèvement à eu lieu mais à échouée.
                            <br/>Les mesures de vos appareils continues d'être enregistré pendant 1 mois après
                            désactivation, si vous réactiver votre organisation avant ce délais vous pourrez retrouver
                            vos services comme si rien ne s'était passé.
                            <br/>Il vous suffit d'indiquer un nouveau moyen de paiement pour réactiver vos services.
                        </>

                    </div>
                )}


                <Tabs defaultValue="prochain">
                    <Tabs.List>
                        {organisation_full.organisation.etat !== "Desac_paiement" ? (
                            <Tabs.Tab value="prochain" leftSection={<IconeList/>}>
                                Prochain prélevement
                            </Tabs.Tab>
                        ) : (
                            <Tabs.Tab value="prochain" leftSection={<IconeList/>}>
                                Réactivation
                            </Tabs.Tab>
                        )}

                        <Tabs.Tab value="historique" leftSection={<IconeListCheck/>}>
                            Historique des prélèvement
                        </Tabs.Tab>
                    </Tabs.List>


                    {/*{organisation_full.organisation.etat !== "Desac_paiement" ? (*/}
                    <Tabs.Panel value="prochain">
                        {/*prochainPaiement*/}


                        {undefined !== organisation_full.total
                        && undefined !== organisation_full.totalTTC
                        && undefined !== organisation_full.avance
                        && undefined !== organisation_full.remboursement
                        && undefined !== organisation_full.rattrapage ? (
                            <>

                                {estDesactivee(organisation_full.organisation) && (
                                    <div>

                                        <p className={"margintop"}>
                                            Réactiver votre organisation va réactiver la configuration des cellules que
                                            vous aviez lors de la désactivation.
                                            Vous pourrez changer, si vous le souhaitez, votre configuration jusqu'à ce
                                            soir minuit.
                                            Ensuite sera prélevé l'avance des cellules qui seront encore actives.
                                        </p>

                                        <Switch
                                            className={"margintop"}
                                            label="Je veux récupérer les mesures enregistrées durant la période de désactivation"
                                            checked={desac_paiement_veut_recup_mesures_degrade}
                                            onChange={e => {
                                                setDesac_paiement_veut_recup_mesures_degrade(e.target.checked);
                                            }}
                                        />
                                    </div>
                                )}

                                <div className={"zones"} style={{marginTop: "1rem"}}>
                                    {estDesactivee(organisation_full.organisation) ? (
                                        <>
                                            A la réactivation, la somme de {organisation_full.total.toFixed(2)}€ TTC sera
                                            prélevé.
                                        </>
                                    ) : (
                                        <>
                                            Le {afficherDate(organisation_full.organisation.prochainPrelevement)},
                                            la somme de {organisation_full.totalTTC.toFixed(2)}€ TTC sera prélevé.
                                        </>
                                    )}
                                    {" "} Détails :
                                    <div className={"en-ligne"}>
                                        <i className="inline-tag">Total avance
                                            : {organisation_full.avance.toFixed(2)}€ HT</i>
                                        {organisation_full.rattrapage != 0 && (
                                            <i className="inline-tag">Total rattrapage
                                                : {organisation_full.rattrapage.toFixed(2)}€ HT</i>
                                        )}
                                        {organisation_full.remboursement != 0 && (
                                            <i className="inline-tag">Total remboursement
                                                : {organisation_full.remboursement.toFixed(2)}€ HT</i>
                                        )}
                                        {organisation_full.promotion !== undefined && organisation_full.promotion > 0 && (
                                            <i className="inline-tag">Promotion
                                                de {organisation_full.promotion}%
                                                ({organisation_full.promotion_montant?.toFixed(2)}€ HT)
                                                appliquée : {organisation_full.promotion_texte}
                                            </i>
                                        )}
                                        {organisation_full.SMS_a_crediter !== undefined && organisation_full.SMS_a_crediter > 0 && (
                                            <i className="inline-tag">+{organisation_full.SMS_a_crediter} SMS offerts
                                            </i>
                                        )}
                                        {organisation_full.Tel_a_crediter !== undefined && organisation_full.Tel_a_crediter > 0 && (
                                            <i className="inline-tag">+{organisation_full.Tel_a_crediter} Appels offerts
                                            </i>
                                        )}
                                        <i className="inline-tag">TOTAL HT : {organisation_full.total.toFixed(2)} €
                                        </i>
                                        <i className="inline-tag">{tva_pourcent}% TVA : +{(organisation_full.total * (tva_pourcent/100)).toFixed(2)} €
                                        </i>

                                    </div>

                                    {!organisation_full.statuts || organisation_full.statuts.length === 0 && (
                                        <div className={"notice margintop"}>
                                            Aucune cellule actuellement activée.
                                        </div>
                                    )}


                                    {capteurs
                                        ?.filter(c => c || (organisation_full.statuts && organisation_full.statuts.filter(s => !s.capteur_UUID).length > 0))
                                        .map(capteur => (
                                            <div style={{width: "100%"}}>
                                                <div
                                                    className={"carte_capteur_collapse" + (capteurs_openned.includes(capteur?.uuid ?? "undef") ? " open" : "")}
                                                    key={capteur?.uuid ?? "undef"} onClick={() => {
                                                    if (capteur?.uuid) {
                                                        if (capteurs_openned.includes(capteur?.uuid ?? "")) {
                                                            let _edit = capteurs_openned.filter(c => c !== capteur.uuid)
                                                            setCapteurs_openned(_edit)
                                                        } else {
                                                            let _edit = [...capteurs_openned]
                                                            _edit.push(capteur.uuid)
                                                            setCapteurs_openned(_edit)
                                                        }

                                                    } else { // Undefined
                                                        if (capteurs_openned.includes("undef")) {
                                                            let _edit = capteurs_openned.filter(c => c !== "undef")
                                                            setCapteurs_openned(_edit)
                                                        } else {
                                                            let _edit = [...capteurs_openned]
                                                            _edit.push("undef")
                                                            setCapteurs_openned(_edit)
                                                        }
                                                    }
                                                }
                                                }>
                                                    {capteurs_openned.includes(capteur?.uuid ?? "undef") ?
                                                        <IconeCaretDown/> : <IconeCaretRight/>}
                                                    <p>{capteur?.nom}</p>
                                                    <i>({capteur?.uuid ?? "Cellules liées à aucun enregistreur"})</i>

                                                    {capteur && organisation_full.enfants.length > 0 && (
                                                        <i>({organisations_toutes?.find(o => o.id == capteur?.organisation_id)?.nom ?? "Orga inconnue"})</i>
                                                    )}

                                                    <p className={"prix"}>{total_par_capteur(organisation_full, capteur?.uuid)}€ HT</p>
                                                </div>

                                                <Collapse in={capteurs_openned.includes(capteur?.uuid ?? "undef")}>
                                                    <div className={"tableauContainer"}>
                                                        <table>
                                                            <thead className={"hide_on_mobile"}>
                                                            <tr>
                                                                <th>Cellule</th>
                                                                <th>Abonnement</th>
                                                                <th>Debut période actuelle</th>
                                                                <th>Fin période actuelle</th>
                                                                <th>Détails</th>
                                                            </tr>
                                                            </thead>

                                                            <tbody>

                                                            <>
                                                                {organisation_full.statuts?.filter(s => (s.capteur_UUID ?? undefined) === capteur?.uuid).map(statut => (
                                                                    statut.cellule.statut_actuel &&
                                                                    <tr key={statut.cellule.statut_actuel?.id}>
                                                                        {/*{organisation_full.enfants.length > 0 && (*/}
                                                                        {/*    <td>{statut.cellule.organisation.nom}</td>*/}
                                                                        {/*)}*/}
                                                                        <td>
                                                                            <Link
                                                                                to={"/cellule/" + statut.cellule.id}
                                                                                className="btn sec"
                                                                                style={{justifyContent: "space-between"}}>
                                                                                <i className={"inline-tag " + (last_mesure_get_couleur(statut.cellule).color)}>
                                                                                    {last_mesure_get_couleur(statut.cellule).texte}
                                                                                </i>
                                                                                {statut.cellule.nom}
                                                                                <i></i>
                                                                            </Link>
                                                                        </td>
                                                                        <td>
                                                                            {statut.cellule.statut_actuel.statut.nom}
                                                                            {" "}
                                                                            {statut.promo > 0 ? (
                                                                                    <div style={{cursor: "pointer"}}
                                                                                         onClick={() => {
                                                                                             alert("Promotion de " + statut.promo + "% appliquée : \n" + statut.promo_texte)
                                                                                         }
                                                                                         }>
                                                                                        (
                                                                                        <i style={{textDecoration: "line-through"}}>
                                                                                            {statut.cellule.statut_actuel.statut.prix_achat}€ HT/mois
                                                                                        </i>
                                                                                        <i style={{color: "var(--valid-color)"}}>
                                                                                            {" " + Number(statut.cellule.statut_actuel.statut.prix_achat) * (100 - statut.promo) / 100}€ HT/mois
                                                                                        </i>
                                                                                        )
                                                                                    </div>
                                                                                ) :
                                                                                <>
                                                                                    ({statut.cellule.statut_actuel.statut.prix_achat}€ HT/mois)
                                                                                </>
                                                                            }
                                                                            {/*{" " + Math.round(statut.prix_final*100)/100 + "€"}*/}

                                                                        </td>
                                                                        <td>
                                                                            {afficherDateTime(statut.cellule.statut_actuel.debut)}
                                                                        </td>
                                                                        <td>
                                                                            {afficherDateTime(statut.cellule.statut_actuel.fin)}
                                                                        </td>
                                                                        <td style={{textAlign: "left"}}
                                                                            className={"texte-petit"}>
                                                                            {statut.details.split("\n").map(ligne =>
                                                                                <p
                                                                                    className={"espace-entre-items"}>{ligne}</p>)}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </>
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </Collapse>

                                            </div>
                                        ))}

                                </div>


                                {estDesactivee(organisation_full.organisation) && (
                                    <Modale_reactiver_organisation
                                        organisation={organisation_full}
                                        inclure_degrade={desac_paiement_veut_recup_mesures_degrade}
                                        refresh={queryOrga}
                                    />
                                )}

                            </>
                        ) : (
                            <div className={"notice margintop"}>
                                Aucune cellule actuellement activée.
                            </div>
                        )}
                    </Tabs.Panel>
                    {/*) : (*/}
                    {/*    <Tabs.Panel value="prochain" >*/}
                    {/*        /!*repriseApresDesacPaiement*!/*/}
                    {/*        <p></p>*/}
                    {/*    </Tabs.Panel>*/}
                    {/*)}*/}


                    <Tabs.Panel value="historique">
                        <div className={"zones"} style={{marginTop: "1rem"}}>
                            Détails des prélèvements déjà effectués sur l'organisation :
                            <div className={"tableauContainer"}>
                                {!organisation_full.paiements || organisation_full.paiements.length === 0 ? (
                                    <p className={"inline-tag"}>Aucun prélèvement déjà effectué.</p>
                                ) : (
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Titre</th>
                                            <th>Montant</th>
                                            <th>Détails</th>
                                            <th>Facture</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {organisation_full.paiements?.map(paiement => (
                                            <tr key={paiement.id}>
                                                <td>{afficherDateTime(paiement.enregistrement)}</td>
                                                <td>{paiement.titre}</td>
                                                <td>
                                                    {paiement.informations?.total && (
                                                        <>
                                                            {paiement.informations.total?.toFixed(2)}€ HT
                                                            <br />
                                                        </>
                                                    )}
                                                    {paiement.montant}€ TTC
                                                </td>
                                                <td>
                                                    {paiement.informations && (
                                                        <button
                                                            className={"sec"}
                                                            onClick={() => setModalePaiements(paiement)}
                                                        ><IconeEye/></button>
                                                    )}

                                                </td>
                                                <td>
                                                    {paiement.facture_token && (
                                                        <button className={"sec"} onClick={() => {
                                                            window.open("https://api.freshtime.fr/app/dl_fichier/" + paiement.facture_token);
                                                        }}><IconeDownload/>
                                                        </button>
                                                    )}

                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>

                                    </table>
                                )}

                            </div>

                        </div>
                    </Tabs.Panel>
                </Tabs>


                <Modal className={"modale-fullscreen"} opened={modale_paiements !== null} onClose={() => {
                    setModalePaiements(null)
                }} title="Détails">
                    {modale_paiements && modale_paiements.informations && (
                        <div className={"zones"} style={{marginTop: "1rem"}}>
                            Le {afficherDate(modale_paiements.informations.organisation.prochainPrelevement)},
                            la somme
                            de {modale_paiements.montant}€ TTC
                            à été prélevé. Détails :
                            <div className={"en-ligne"}>
                                <i className="inline-tag">Total avance
                                    : {modale_paiements.informations.avance && modale_paiements.informations.avance.toFixed(2)}€
                                    HT</i>
                                <i className="inline-tag">Total rattrapage
                                    : {modale_paiements.informations.rattrapage && modale_paiements.informations.rattrapage.toFixed(2)}€
                                    HT</i>
                                <i className="inline-tag">Total remboursement
                                    : {modale_paiements.informations.remboursement && modale_paiements.informations.remboursement.toFixed(2)}€
                                    HT</i>
                                <i className="inline-tag">Balance de l'organisation après prélèvement
                                    : {modale_paiements.informations.organisation.balance.toFixed(2)}€ HT</i>

                                {modale_paiements.informations.total && (
                                    <>
                                        <i className="inline-tag">TOTAL HT : {modale_paiements.informations.total.toFixed(2)} €
                                        </i>
                                        {modale_paiements.informations.totalTTC && (
                                            <i className="inline-tag">TVA :
                                                +{(modale_paiements.informations.totalTTC - modale_paiements.informations.total).toFixed(2)} €
                                            </i>
                                        )}
                                    </>
                                )}


                                {modale_paiements.transaction && (
                                    <i className="inline-tag">Prélèvement bancaire
                                        : {modale_paiements.transaction.montant.toFixed(2)}€ HT</i>
                                )}

                                {modale_paiements.informations.promotion !== undefined && modale_paiements.informations.promotion > 0 && (
                                    <i className="inline-tag">Promotion
                                        de {modale_paiements.informations.promotion}%
                                        ({modale_paiements.informations.promotion_montant?.toFixed(2)}€)
                                        appliquée : {modale_paiements.informations.promotion_texte}
                                    </i>
                                )}
                                {modale_paiements.informations.SMS_a_crediter !== undefined && modale_paiements.informations.SMS_a_crediter > 0 && (
                                    <i className="inline-tag">+{modale_paiements.informations.SMS_a_crediter} SMS
                                        offerts
                                    </i>
                                )}
                                {modale_paiements.informations.Tel_a_crediter !== undefined && modale_paiements.informations.Tel_a_crediter > 0 && (
                                    <i className="inline-tag">+{modale_paiements.informations.Tel_a_crediter} Appels
                                        offerts
                                    </i>
                                )}
                            </div>
                            <div className={"tableauContainer"} style={{maxWidth: "unset"}}>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Cellule</th>
                                        <th>Détails</th>
                                        <th>Abonnement</th>
                                        <th>Debut période</th>
                                        <th>Fin période</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {modale_paiements.informations.statuts?.map(statut => (
                                        statut.cellule.statut_actuel &&
                                        <tr key={statut.cellule.extension?.capteur?.uuid}>
                                            <td>
                                                <Link to={"/cellule/" + statut.cellule.id}
                                                      className="btn sec">
                                                    {statut.cellule.nom}
                                                </Link>
                                            </td>
                                            <td style={{textAlign: "left"}} className={"texte-petit"}>
                                                {statut.details.split("\n").map(ligne => <p
                                                    className={"espace-entre-items"}>{ligne}</p>)}
                                            </td>
                                            <td>
                                                {statut.cellule.statut_actuel.statut.nom} ({statut.cellule.statut_actuel.statut.prix_achat}€/mois)
                                            </td>
                                            <td>
                                                {afficherDateTime(statut.cellule.statut_actuel.debut)}
                                            </td>
                                            <td>
                                                {afficherDateTime(statut.cellule.statut_actuel.fin)}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    )}
                </Modal>

            </div>

        </>
    )
}


export function Modale_reactiver_organisation({
                                                  organisation, inclure_degrade, refresh
                                              }: {
    organisation: Organisation_paiement,
    inclure_degrade: boolean,
    refresh: () => void
}) {
    const [opened, handlers] = useDisclosure(false);
    const [save_btn_loading, set_save_btn_loading] = useState(false);

    // if (forcer) handlers.open();

    function handleEnregistrer() {
        set_save_btn_loading(true);
        ajax.post("/organisations/" + organisation.organisation.id + "/reactiver" + (inclure_degrade ? "?rattrapage=1" : "") ).then(response => {

            handlers.close();
            refresh();
            set_save_btn_loading(false);
        }).catch(error => {
        })

    }

    const [forcer_valide_paiement, setForcer_valide_paiement] = useState(false);

    return (
        <>
            <button onClick={handlers.open} className={"big margintop"}>Réactiver l'organisation.</button>


            <Modal opened={opened} onClose={handlers.close} title="Réactiver la cellule">
                <div className={"form"}>

                    {!checkSiOrgaAMoyenDePaiement(organisation.organisation.id) && !forcer_valide_paiement ? (
                        <>
                            <p>Pour pouvoir réactiver votre organisation, il faut nous indiquer un moyen de paiement valide : </p>
                            <Modale_enregistrer_cb organisation={organisation.organisation} refresh={(resultat) => {
                                setForcer_valide_paiement(resultat ?? false);
                            }} />
                        </>
                    ) : (
                        <>
                            <p>{inclure_degrade
                                ? "Vous souhaitez régler et réactiver les cellules durant la période de désactivation."
                                : "Vous ne souhaitez pas réactiver les cellules durant la période de désactivation."
                            }
                            </p>

                            <Loading_button is_loading={save_btn_loading}
                                            onClick={handleEnregistrer}>Réactiver la cellule</Loading_button>
                        </>
                    )}


                </div>

            </Modal>
        </>

    )
}