import React, {useEffect, useState} from "react";

import './Capteurs_liste_admin.scss'
import {Tableau} from "../../../../components/tableau/Tableau";
import ajax from "../../../../services/AxiosInterceptor";
import {
    createColumnHelper,
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel, Row, SortingState,
    useReactTable
} from "@tanstack/react-table";
import {Autocomplete, Checkbox, Modal, NativeSelect, NumberInput, Switch, Tooltip} from "@mantine/core";
import {Capteur} from "../../../../types/capteur";
import {afficherDate, afficherDateTime, DateTZ} from "../../../../services/GestionDates";
import {Icone_signal} from "../../../../components/icone/icone_signal/Icone_signal";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {Organisation} from "../../../../types/organisation";
import {Link, useLocation} from "react-router-dom";
import {Modale_editer_capteur} from "./modale_editer_capteur";
import {Hub} from "../../../../types/hub";
import {
    texte_date_compare_tableau,
    texte_est_inclus,
    texte_simple_compare_tableau
} from "../../../../services/GestionTextes";
import {IconePenToSquare} from "../../../../components/icone/solid_react/pen-to-square";
import {useDisclosure} from "@mantine/hooks";
import {IconeUserTie} from "../../../../components/icone/solid_react/user-tie";
import {getAffichageNom} from "../../../../services/UserService";
import {Commercial} from "../../../../types/commercial";
import {UserLight} from "../../../../types/user";
import {IconeEye} from "../../../../components/icone/solid_react/eye";
import {IconeCircle} from "../../../../components/icone/solid_react/circle";
import {IconeStar} from "../../../../components/icone/solid_react/star";
import {IconeCircleQuestion} from "../../../../components/icone/solid_react/circle-question";
import {IconeCircleStar} from "../../../../components/icone/solid_react/circle-star";
import {IconeReceipt} from "../../../../components/icone/solid_react/receipt";
import {IconeEuroSign} from "../../../../components/icone/solid_react/euro-sign";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {Loading_button} from "../../../../components/loading_button/loading_button";


export function Capteurs_liste_admin() {

    const [donnees, setDonnees] = useState<Capteur[] | null>(() => null);

    const [sorting, setSorting] = React.useState<SortingState>([])
    const [columnVisibility, setColumnVisibility] = React.useState({})


    const columnHelper = createColumnHelper<Capteur>()

    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list)

    const [edited_capteur, setEdited_capteur] = useState<Capteur|null>(null);





    // function handleSupprimerUser(user:Capteur)
    // {
    //     if (window.confirm("Vous êtes sur le point de supprimer l'utilisateur " + user.nom_affichage + "(" + user.email + ")"))
    //     {
    //         setBtn_loading(true)
    //         ajax.delete("/users/" + user.id).then(response => {
    //             setBtn_loading(false)
    //             if (donnees) setDonnees(donnees?.filter(d => d.id !== user.id))
    //         }).catch(error => {
    //             setBtn_loading(false)});
    //     }
    // }

    const columns = [
        columnHelper.accessor(row => row, {
            id: 'Selection',
            header: ({table}) => (
                <span className={"aligne_left"}>
                    {'\u00A0'}
                    <Checkbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </span>

            ),
            cell: ({row}) => (
                <div className="px-1">
                    <Checkbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),

            enableColumnFilter: false,
            enableSorting: false
        }),
        columnHelper.accessor(row => row, {
            id: "Organisation",
            header: () => "Organisation",
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Organisation : </strong>
                        <Link to={"/organisation/" + info.getValue().organisation_id }>
                            {organisations.filter(o => o.id === info.getValue().organisation_id)[0]?.nom }
                        </Link>

                </span>
                ),
            filterFn: (row: Row<Capteur>,
                       columnId: string,
                       filterValue) => {
                let data:Capteur =  row.getValue(columnId)
                if (data.organisation_id) {
                    return texte_est_inclus(organisations.filter(o => o.id === data.organisation_id)[0]?.nom, filterValue)
                }
                return false
            },
        }),
        columnHelper.accessor(row => row.uuid, {
            id: 'UUID',
            header: () => 'UUID',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>UUID : </strong>
                        <Link to={"/capteur/" + info.getValue()}>{info.getValue()}</Link>
                </span>
                ),
        }),
        columnHelper.accessor(row => row.ref, {
            id: 'Réf',
            header: () => 'Réf',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Réf : </strong>
                        {info.getValue()}
                </span>
                ),
        }),
        columnHelper.accessor(row => row.numero_serie, {
            id: 'numero_serie',
            header: () => 'Numéro de série fabricant',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Numéro de série fabricant: </strong>
                        {info.getValue()}
                </span>
                ),
        }),
        columnHelper.accessor(row => row.produit.nom, {
            id: 'Produit',
            header: () => 'Produit',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Produit : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.produit.ref, {
            id: 'Produit réf',
            header: () => 'Produit réf',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Produit réf : </strong>
                        {info.getValue()}
                </span>
                ),
        }),
        columnHelper.accessor(row => row.categorie_abonnement, {
            id: 'categorie_abonnement',
            header: () => 'Catégorie d\'abonnement',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Catégorie d'abonnement : </strong>
                        {info.getValue()}
                </span>
                ),
        }),
        columnHelper.accessor(row => row.nom, {
            id: 'Nom',
            header: () => 'Nom',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Nom : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.configuration_hash, {
            id: "Config hash",
            header: () => "Config hash",
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Config hash : </strong>
                        {info.getValue()}
                </span>
                ),
            enableColumnFilter: false,
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.niveau_batterie, {
            id: "Niveau batterie",
            header: () => "Niveau batterie",
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Niveau batterie : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.error_code, {
            id: "Code Erreur",
            header: () => "Code Erreur",
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Code Erreur : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.rssi, {
            id: "RSSI",
            header: () => "RSSI",
            cell: info =>
                info.getValue() != null && (
                <span className={"en-ligne paddingZero justify_center"}>
                    <strong className={"th_mobile"}>SNR : </strong>
                        <Icone_signal rssi={info.getValue()}/> {info.getValue()}dBm
                </span>
                ),
        }),
        columnHelper.accessor(row => row.snr, {
            id: "SNR",
            header: () => "SNR",
            cell: info =>
                info.getValue() != null && (
                <span className={"en-ligne paddingZero justify_center"}>
                    <strong className={"th_mobile"}>SNR : </strong>
                        <Icone_signal snr={info.getValue()}/> {info.getValue()}dBm
                </span>
                ),
        }),
        columnHelper.accessor(row => row.creation, {
            id: "Création",
            header: () => 'Création',
            cell: info =>
                <span>
                    <strong className={"th_mobile"}>Création : </strong>
                    {afficherDateTime(new DateTZ(info.getValue()))}
                </span>,
            enableColumnFilter: true,
            sortingFn: (rowA: Row<Capteur>,
                        rowB: Row<Capteur>,
                        columnId: string) => {
                let dateA = new DateTZ(rowA.getValue(columnId))
                let dateB = new DateTZ(rowB.getValue(columnId))
                if (dateA < dateB) return -1
                else if (dateA == dateB) return 0
                else return 1
            },
            filterFn: texte_date_compare_tableau,
        }),
        columnHelper.accessor(row => (row.derniere_mesure ? row.derniere_mesure : null), {
            id: "Dernière mesure",
            header: () => 'Dernière mesure',
            cell: info =>
                <span>
                    <strong className={"th_mobile"}>Dernière mesure : </strong>
                    {afficherDateTime(info.getValue())}
                </span>,
            enableColumnFilter: true,
            sortingFn: (rowA: Row<Capteur>,
                        rowB: Row<Capteur>,
                        columnId: string) => {
                let dateA = new DateTZ(rowA.getValue(columnId))
                let dateB = new DateTZ(rowB.getValue(columnId))
                if (dateA < dateB) return -1
                else if (dateA == dateB) return 0
                else return 1
            },
            filterFn: texte_date_compare_tableau,
        }),
        columnHelper.accessor(row => row.hub, {
            id: "Hub",
            header: () => "Hub",
            cell: info =>
                info.getValue() != null && (
                    <span className={"en-ligne paddingZero justify_center"}>
                    <strong className={"th_mobile"}>Hub : </strong>
                            <Tooltip label={info.getValue().nom} >
                                <Link to={"/hubs?uuid=" + info.getValue().uuid}>{info.getValue().uuid}</Link>
                            </Tooltip>
                </span>
            ),
            sortingFn: (rowA: Row<Capteur>,
                        rowB: Row<Capteur>,
                        columnId: string) => {
                let dateA:Hub = rowA.getValue(columnId)
                let dateB:Hub = rowB.getValue(columnId)
                if (!dateA && !dateB) return 0
                if (!dateA) return -1
                if (!dateB) return 1
                if (dateA.uuid < dateB.uuid) return -1
                else if (dateA.uuid == dateB.uuid) return 0
                else return 1
            },
            filterFn: (row: Row<Capteur>,
                       columnId: string,
                       filterValue) => {
                let hub:Hub = row.getValue(columnId)
                if (hub) {
                    return hub.uuid.toLowerCase().includes(filterValue.toLowerCase()) || hub.nom.toLowerCase().includes(filterValue.toLowerCase())
                }
                return false
            },
        }),
        columnHelper.accessor(row => row.notes, {
            id: 'Notes',
            header: () => 'Notes',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Notes : </strong>
                        {info.getValue().length < 60 ? info.getValue() : info.getValue().slice(0, 57) + "..."}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => JSON.stringify(row.commercial), {
            id: 'Commercial',
            header: () => 'Commercial',
            cell: info => {
                let row:UserLight = JSON.parse(info.getValue());
                return (
                    row != null && (
                        <span>
                        <strong className={"th_mobile"}>Commercial : </strong>

                            {// @ts-ignore
                                getAffichageNom(row)}
                    </span>
                    )
                )
            },
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.dateDebutRelationCommercial ? afficherDate(row.dateDebutRelationCommercial) : null, {
            id: 'dateDebutRelationCommercial',
            header: () => 'Debut Relation Commercial',
            cell: info => {
                return (
                    info.getValue() != null && (
                        <span>
                        <strong className={"th_mobile"}>Debut relation commercial : </strong>
                            {info.getValue()}
                    </span>
                    )
                )
            },
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.dateDeVente ? afficherDate(row.dateDeVente) : null, {
            id: 'dateDeVente',
            header: () => 'Date De Vente',
            cell: info => {
                return (
                    info.getValue() != null && (
                        <span>
                        <strong className={"th_mobile"}>Date de vente : </strong>
                            {info.getValue()}
                    </span>
                    )
                )
            },
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.prixDeVente, {
            id: 'prixDeVente',
            header: () => 'Prix De Vente HT',
            cell: info => {
                return (
                    info.getValue() != null && info.getValue() != 0 && (
                        <span>
                        <strong className={"th_mobile"}>Prix de vente : </strong>
                            {info.getValue()}€
                    </span>
                    )
                )
            },
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.venteValide ? "Oui" : "Non", {
            id: 'venteValide',
            header: () => 'Vente validé',
            cell: info => {
                return (
                    info.getValue() != null && (
                        <span>
                        <strong className={"th_mobile"}>Vente validé : </strong>
                            {info.getValue()}
                    </span>
                    )
                )
            },
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row, {
            id: 'indicateur_vente',
            header: () => <IconeReceipt />,
            cell: info => {
                let indicateur = <p style={{color: "grey"}}><IconeCircle /></p>;
                let row:Capteur = info.getValue();
                if (row.dateDeVente && row.venteValide) indicateur = <p style={{color: "var(--valid-color)"}}><IconeCircleStar /></p>
                if (row.dateDeVente && !row.venteValide) indicateur = <p style={{color: "var(--warning-color)"}}><IconeCircleQuestion /></p>
                return (
                    info.getValue() != null && (
                        <span>
                            {indicateur}
                        </span>
                    )
                )
            },
            enableColumnFilter: false,
            sortingFn: (rowA, rowB) => {
                let venteA = 0;
                if (rowA.original.dateDeVente && rowA.original.venteValide) venteA = 1;
                if (rowA.original.dateDeVente && !rowA.original.venteValide) venteA = 2;
                let venteB = 0;
                if (rowB.original.dateDeVente && rowB.original.venteValide) venteB = 1;
                if (rowB.original.dateDeVente && !rowB.original.venteValide) venteB = 2;


                if (venteA === venteB) {
                    if (new DateTZ(rowA.original.dateDeVente) == new DateTZ(rowB.original.dateDeVente)) return 0;
                    if (new DateTZ(rowA.original.dateDeVente) < new DateTZ(rowB.original.dateDeVente)) return 1;
                    else return -1
                }
                if (venteA < venteB) return 1;
                else return -1
            },

        }),
        columnHelper.accessor(row => row, {
            id: 'Edit',
            header: () => '',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <button onClick={() => setEdited_capteur(info.getValue())}><IconePenToSquare/></button>

                </span>
                ),
            enableColumnFilter: false
        }),
    ]

    const [modal_ajouter_commercial_openned,handlers_modal_ajouter_commercial] = useDisclosure(false);
    const [modal_editer_categorie_abonnement_openned,handlers_modal_editer_categorie_abonnement] = useDisclosure(false);
    const [modal_prix_de_vente_appareils,handlers_modal_prix_de_vente_appareils] = useDisclosure(false);

    type Prix_appareils = {
        classic: number,
        evolution: number
    }
    const [prix_ventes_appareils, setPrix_ventes_appareils] = useState<Prix_appareils>({classic: 0, evolution: 0});

    const [affichage_btn_loading_vente_validation, setAffichage_btn_loading_vente_validation] = useState<string|null>(null);
    async function validerVenteCapteursSelectionnes()
    {
        let capteurs = table.getSelectedRowModel().rows.map(r => r.original);
        setAffichage_btn_loading_vente_validation("0/" + capteurs.length)
        for (let i = 0; i < capteurs.length; i++) {
            let continuer = true;
            let remplir_a_ajourdhui = false;
            if (!capteurs[i].dateDeVente || !capteurs[i].prixDeVente){
                continuer = window.confirm("L'enregisteur " + capteurs[i].uuid + " n'a pas de date de vente ou de prix." +
                    "\nRemplir automatiquement à aujourd'hui ? (Si non, la ligne sera ignoré)");
                remplir_a_ajourdhui = true;
            }
            if (continuer && !capteurs[i].commercial){
                continuer = window.confirm("L'enregisteur " + capteurs[i].uuid + " n'a pas de commercial attitré." +
                    "\nValider quand même sa vente ?");
            }
            if (continuer) {
                await ajax.put("/capteurs/" + capteurs[i].uuid, {
                    venteValide: true,
                    commercial_remplir_auto: remplir_a_ajourdhui
                }).then(response => {
                    setAffichage_btn_loading_vente_validation((i + 1) + "/" + capteurs.length)
                })
            }

        }
        setAffichage_btn_loading_vente_validation(null);
        query_capteurs();
    }

    function query_capteurs()
    {
        ajax.get("/capteurs").then(response => {
            setDonnees(response.data.capteurs)
            setPrix_ventes_appareils(response.data.prix_appareils)
        }).catch(e => {
        })
    }

    useEffect(() => {
        query_capteurs()
    }, [])

    const [commerciaux, setCommerciaux] = useState<Commercial[]>([]);
    const [commercial_input, setCommercial_input] = useState<string|null>(null);

    useEffect(() => {
        ajax.get("/commercial").then(response => setCommerciaux(response.data.commerciaux)).catch(e => {
        })
    }, []);

    async function handleAjouterCommercial()
    {
        let capteurs = table.getSelectedRowModel().rows.map(r => r.original);
        setModale_categorie_abonnement_afficheur("0/" + capteurs.length)
        for (let i = 0; i < capteurs.length; i++) {
            await ajax.put("/capteurs/" + capteurs[i].uuid, {
                commercial_user_id: commercial_input
            }).then(response => {
                setModale_categorie_abonnement_afficheur((i + 1) + "/" + capteurs.length)
            })
        }
        setModale_categorie_abonnement_afficheur(null);
        handlers_modal_ajouter_commercial.close();
        query_capteurs();
    }

    const table = useReactTable<Capteur>({
        data: donnees ? donnees : [],
        columns: columns,
        state: {
            sorting,
            columnVisibility,
        },
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        enableFilters: true,
        enableColumnFilters: true
    })

    const [categorie_abonnement_input, setCategorie_abonnement_input] = useState("");

    const [modale_categorie_abonnement_afficheur, setModale_categorie_abonnement_afficheur] = useState<null|string>(null);
    async function handleModifierCategorieAbonnement()
    {
        let capteurs = table.getSelectedRowModel().rows.map(r => r.original);
        setModale_categorie_abonnement_afficheur("0/" + capteurs.length)
        for (let i = 0; i < capteurs.length; i++) {
            await ajax.put("/capteurs/" + capteurs[i].uuid, {
                categorie_abonnement: categorie_abonnement_input
            }).then(response => {
                setModale_categorie_abonnement_afficheur((i + 1) + "/" + capteurs.length)
            })
        }
        setModale_categorie_abonnement_afficheur(null);
        handlers_modal_editer_categorie_abonnement.close();
        query_capteurs();
    }

    const [btn_loading, setBtn_loading] = useState(false);
    function handleModifierPrixAppareils()
    {
        setBtn_loading(true);
        ajax.put("/changer_prix_appareils", prix_ventes_appareils).then(response => {
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé",
                message: '',
                icon: <IconeCheck/>,
            });
            handlers_modal_prix_de_vente_appareils.close();
            setBtn_loading(false);
        }).catch(e => {setBtn_loading(false);});
    }


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const vue = queryParams.get('vue');

    useEffect(() => {
        if (vue === "commercial")
        {
            afficherVueCommercial();
        }
    }, []);

    function afficherVueCommercial()
    {
        table.getAllLeafColumns().map(col => {
            if (['Selection', 'Organisation', 'UUID', 'Produit', 'categorie_abonnement', 'Commercial', 'dateDebutRelationCommercial', 'dateDeVente', 'prixDeVente', 'venteValide', 'indicateur_vente', 'Edit'].includes(col.id)) {
                col.toggleVisibility(true);
            } else {
                col.toggleVisibility(false);
            }
        })
        table.setSorting([{"id":"indicateur_vente","desc":false}])
    }

    return (
        <div className={"en-tete-pages"}>
            <div className={"en-ligne en-tete-page justify_end"}>

                <div className={"titre fullwidth"}>
                    <h1>Tous les enregistreurs de la plateforme</h1>
                </div>
            </div>

            <div className={"en-ligne en-tete-page justify_end"}>


                <div className={"flex-row"}>
                    <button onClick={handlers_modal_prix_de_vente_appareils.toggle}>
                        <IconeEuroSign/>Prix de vente des Enregistreurs
                    </button>
                    <button disabled={table.getSelectedRowModel().rows.length === 0}
                            onClick={handlers_modal_editer_categorie_abonnement.toggle}>
                        <IconePenToSquare/>Catégorie abonnement
                    </button>
                    <button disabled={table.getSelectedRowModel().rows.length === 0}
                            onClick={handlers_modal_ajouter_commercial.toggle}>
                        <IconeUserTie/>Lier à un commercial
                    </button>
                    <button disabled={table.getSelectedRowModel().rows.length === 0 || affichage_btn_loading_vente_validation !== null}
                            onClick={validerVenteCapteursSelectionnes}>
                        <IconeCircleStar/>{affichage_btn_loading_vente_validation ?? "Valider les ventes"}
                    </button>
                </div>




            </div>

            <div className={"en-ligne en-tete-page justify_end"}>

                <div className={"flex-row"}>
                    <button onClick={() => {
                        table.getAllLeafColumns().map(col => {
                            if (['Selection', 'Organisation', 'UUID', 'numero_serie', 'Produit', 'categorie_abonnement', 'Dernière mesure', 'Edit'].includes(col.id)) {
                                col.toggleVisibility(true);
                            } else {
                                col.toggleVisibility(false);
                            }
                        })
                    }}>Afficher minimum
                    </button>
                    <button onClick={() => {
                        afficherVueCommercial();
                    }}>Afficher Vue Commercial
                    </button>
                    <button onClick={() => {
                        table.getAllLeafColumns().map(col => {
                            col.toggleVisibility(true);
                        })
                    }}>Afficher tout
                    </button>
                    <div>{table.getRowModel().rows.length} lignes affichés</div>
                    <NativeSelect
                        className={"select_nbe_lignes match_height"}
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value))
                        }}
                        data={["5", "10", "20", "50", "100"]}
                    />
                </div>
            </div>

            <Tableau table={table} donnees={donnees}/>


            <Modale_editer_capteur capteur={edited_capteur} setEditedCapteur={setEdited_capteur} capteurs={donnees}
                                   setCapteurs={setDonnees} commerciaux={commerciaux}/>
            {donnees && (
                <Modal opened={modal_editer_categorie_abonnement_openned}
                       onClose={handlers_modal_editer_categorie_abonnement.close}
                       title="Modifier la catégorie d'abonnement">

                    <p className={"en-ligne"}>{table.getSelectedRowModel().rows.length} appareils sélectionnés
                        : {table.getSelectedRowModel().rows.map(r => <span
                            className={"inline-tag"}>{r.original.uuid}</span>)}</p>

                    <Autocomplete
                        label="Catégorie d'abonnement à appliquer"
                        data={[...new Set(donnees.map(d => d.categorie_abonnement))]}
                        value={categorie_abonnement_input}
                        onChange={e => setCategorie_abonnement_input(e)}
                    />

                    <p>&nbsp;</p>
                    <button onClick={handleModifierCategorieAbonnement}
                            disabled={modale_categorie_abonnement_afficheur !== null}>
                        {modale_categorie_abonnement_afficheur ?? "Valider"}
                    </button>

                </Modal>
            )}

            <Modal opened={modal_prix_de_vente_appareils}
                   onClose={handlers_modal_prix_de_vente_appareils.close}
                   title="Modifier le prix des appareils">


                <NumberInput
                    label="Prix de vente Freshtime Classic"
                    value={prix_ventes_appareils.classic}
                    onChange={e => {
                        let edit = {...prix_ventes_appareils}
                        edit.classic = Number(e);
                        setPrix_ventes_appareils(edit);
                    }}
                />
                <NumberInput
                    label="Prix de vente Freshtime Evolution"
                    value={prix_ventes_appareils.evolution}
                    onChange={e => {
                        let edit = {...prix_ventes_appareils}
                        edit.evolution = Number(e);
                        setPrix_ventes_appareils(edit);
                    }}
                />

                <p>&nbsp;</p>
                <Loading_button is_loading={btn_loading} onClick={handleModifierPrixAppareils} >
                    Valider
                </Loading_button>

            </Modal>

            {donnees && (
                <Modal opened={modal_ajouter_commercial_openned} onClose={handlers_modal_ajouter_commercial.close}
                       title="Indiquer le commercial lié aux appareils">

                    <p className={"en-ligne"}>{table.getSelectedRowModel().rows.length} appareils sélectionnés
                        : {table.getSelectedRowModel().rows.map(r => <span
                            className={"inline-tag"}>{r.original.uuid}</span>)}</p>

                    <Switch
                        label={"Ces appareils ont un commercial lié"}
                        checked={commercial_input !== null}
                        onChange={e => {
                            if (e.target.checked && commerciaux && commerciaux[0]?.user) {
                                setCommercial_input(commerciaux[0].user.id + "")
                            } else {
                                setCommercial_input(null)
                            }
                        }}
                    />

                    <NativeSelect
                        label="Commercial à appliquer"
                        disabled={commercial_input === null}
                        data={commerciaux.map(commercial => {
                            return {
                                label: commercial.user ? getAffichageNom(commercial.user) : commercial.id + "",
                                value: (commercial.user?.id ?? "0") + ""
                            }
                        })}
                        value={commercial_input ?? ""}
                        onChange={e => setCommercial_input(e.target.value)}
                    />

                    <p>{commercial_input}</p>

                    <p>&nbsp;</p>
                    <button onClick={handleAjouterCommercial}
                            disabled={modale_categorie_abonnement_afficheur !== null}>
                        {modale_categorie_abonnement_afficheur ?? "Valider"}
                    </button>

                </Modal>
            )}


        </div>

    );
}
