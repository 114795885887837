import React, {useEffect, useRef, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../services/AxiosInterceptor";
import {Checkbox, Input, Modal, NumberInput, Switch, TextInput} from "@mantine/core";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {
    Organisation,
    Organisation_facturation, organisation_facturation_init,
    Organisation_paiement,
    Organisation_paiement_orga_detail
} from "../../../types/organisation";
import {notifications} from "@mantine/notifications";
import env_config from "../../../env_config";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeMessageSms} from "../../../components/icone/solid_react/message-sms";
import {IconePhone} from "../../../components/icone/solid_react/phone";
import {IconeCreditCard} from "../../../components/icone/solid_react/credit-card";
import {IconeTriangleExclamation} from "../../../components/icone/solid_react/triangle-exclamation";
import {afficherDate, DateTZ} from "../../../services/GestionDates";
import {IconeCross} from "../../../components/icone/solid_react/cross";
import {IconeLink} from "../../../components/icone/solid_react/link";
import {IconeXmark} from "../../../components/icone/solid_react/xmark";
import {permissionIsGranted} from "../../../services/permissionsService";

export function Modale_enregistrer_cb({
                                          organisation, refresh, vue_config_cellule
                                      }: {
                    organisation: Organisation|Organisation_paiement_orga_detail,
                    refresh: (resultat?:boolean) => void,
                    vue_config_cellule?:boolean
                }) {
    const [opened, handlers] = useDisclosure(false);
    const [save_btn_delete_loading, set_save_btn_delete_loading] = useState(false);
    const [save_btn_loading, set_save_btn_loading] = useState(false);

    const [cgv, setCgv] = useState(false);

    const [step, setStep] = useState(0);

    // if (forcer) handlers.open();

    const [infosFacturation, setInfosFacturation] = useState<Organisation_facturation>(organisation.infosFacturation ?? organisation_facturation_init);

    type ResponseBanque = {
        "redirectionUrl": string,
        "redirectionVersion": string,
        "redirectionData": string,
    }

    const [responseBanque, setResponseBanque] = useState<ResponseBanque|null>(null);

    function handleGetBanque() {
        set_save_btn_loading(true);
        ajax.post("/organisations/" + organisation.id + "/paiement_enregistrement", {
            "infosFacturation": infosFacturation,
        }).then(response => {

            let _responseBanque: ResponseBanque = response.data
            setResponseBanque(_responseBanque);

            if (iframeRef.current && _responseBanque) {
                // Create a hidden form programmatically
                const form = document.createElement('form');
                form.action = _responseBanque.redirectionUrl;
                form.method = 'POST';
                // @ts-ignore
                form.target = iframeRef.current.name; // Target the iframe by name

                // Append post data as hidden inputs
                const inputa = document.createElement('input');
                inputa.type = 'hidden';
                inputa.name = "redirectionVersion";
                inputa.value = _responseBanque.redirectionVersion;
                form.appendChild(inputa);

                const inputz = document.createElement('input');
                inputz.type = 'hidden';
                inputz.name = "redirectionData";
                inputz.value = _responseBanque.redirectionData;
                form.appendChild(inputz);

                document.body.appendChild(form); // Append form to the body
                form.submit(); // Submit the form to the iframe
                document.body.removeChild(form); // Remove the form from the DOM
            }

            set_save_btn_loading(false);
        }).catch(error => {
            set_save_btn_loading(false);
        })


    }

    function handleSupprimerMoyenDePaiement() {

        if (!window.confirm("Etes vous sûr de vouloir supprimer votre moyen de paiement ? Sans moyen de paiement, vos services deviendrons indisponible lors du prochain renouvellement, et vous risquez de perdre les données de vos appareils."))
        {
            return;
        }
        set_save_btn_delete_loading(true);
        ajax.delete("/organisations/" + organisation.id + "/paiement_enregistrement").then(response => {

            if (!permissionIsGranted(undefined, "ADMIN")) {
                alert("Votre demande à été transmise à un administrateur. Nous reviendrons vers vous au plus vite. Merci.")
            }
            handleClose();

            set_save_btn_delete_loading(false);
        }).catch(error => {
            set_save_btn_delete_loading(false);
        })


    }




    const iframeRef = useRef(null);

    const [isPaymentDone, setIsPaymentDone] = useState<0|1|-1>(0);
    useEffect(() => {
        const handleMessage = (event:any) => {
            if (event.data === 'payment-complete') {
                setIsPaymentDone(1);
                setResponseBanque(null);
            }
            if (event.data === 'payment-error') {
                setIsPaymentDone(-1);
                setResponseBanque(null);
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, []);


    let CB_perime_date = organisation.cBPerime ? new DateTZ(organisation.cBPerime) : null;
    let CB_perime_comp = CB_perime_date ? CB_perime_date?.getFullYear()+""+CB_perime_date?.getMonth() : null
    let now_comp = new DateTZ().getFullYear()+""+new DateTZ().getMonth()

    let affichage_expiration = CB_perime_date ? ((CB_perime_date.getMonth()+1)+"").padStart(2, '0') + "/" + CB_perime_date.getFullYear() : "Jamais";


    function handleClose()
    {
        handlers.close();
        setTimeout(() => {
            setResponseBanque(null);
            setStep(0);
            refresh(isPaymentDone === 1)
        }, 400);
    }

    return (
        <>

            <Modal opened={opened}
                   onClose={handleClose}
                   title="Enregistrer un moyen de paiement"
                   fullScreen={responseBanque !== null}
                   className="Modale_enregistrer_cb"
            >

                <div className={"form"}>

                    {isPaymentDone !== 0 ? (
                        <>
                        {isPaymentDone === 1 ? (
                            <>
                                <p className={"notice green en-ligne"}><IconeCheck/> Votre moyen de paiement à été
                                    validé.</p>
                                <p>Le résultat peut être instantané ou prendre jusqu'à quelques heures.</p>
                                <p>Vous recevrez un email de confirmation automatique quand la banque sera revenu
                                    vers nous.</p>
                                <p>Merci de votre confiance.</p>
                                <button onClick={handleClose}>Continuer</button>
                            </>
                        ) : (
                            <>
                                <p className={"notice red en-ligne"}><IconeXmark/> Votre moyen de paiement n'a pas été
                                    validé.</p>
                                <p>Si vous pensez qu'il s'agit d'une erreur, contactez nous directement : </p>
                                <a className={"en-ligne"} href="https://freshtime.fr/fr/contact" target={"_blank"}><IconeLink /> Freshtime - Contact</a>
                                <div className={"en-ligne"}>
                                    <button className={"sec"} onClick={() => {
                                        handlers.close();
                                        setIsPaymentDone(0);
                                        setResponseBanque(null);
                                    }}>Annuler</button>
                                    <button onClick={() => {
                                        setIsPaymentDone(0);
                                        setResponseBanque(null);
                                    }}>Recommencer</button>
                                </div>
                            </>
                        )}
                        </>
                    ) : (
                        <>

                            <iframe
                                ref={iframeRef}
                                name="paymentIframe" // Name matches the form target
                                style={{
                                    border: 'none',
                                    display: responseBanque ? 'unset' : 'none',
                                    width: "100%", height: "100%"
                                }}
                            ></iframe>

                            {(!responseBanque) && (
                                <>
                                    {step === 0 && (
                                        <>
                                            <div className={"en-ligne"}>
                                                <p>Le moyen de paiement que vous allez renseigner sera celui utilisé
                                                    pour
                                                    les
                                                    prélèvements de Freshtime, principalement les abonnements mensuels
                                                    des
                                                    cellules que
                                                    vous avez configurés mais aussi si vous souhaitez ajouter du crédit
                                                    SMS.</p>
                                                <p>Aucun frais ne sera prélevé immédiatement. Ici n'est fait qu'une
                                                    authorisation
                                                    bancaire pour permettre d'activer l'abonnement. Vous serez prélevé
                                                    une
                                                    fois votre
                                                    configuration terminée, sous 24h au plus tôt.</p>
                                                <p className={"inline-tag"}>Vos informations bancaires sont conservés
                                                    par
                                                    notre
                                                    prestataire de paiement LCL, aucune information n'est conservé sur
                                                    nos
                                                    serveur.</p>
                                            </div>


                                            <Checkbox
                                                label={<>J'ai lu les <a href={env_config.url_cg_vente} target="_blank">conditions
                                                    générales de
                                                    ventes</a> et j'y adhère sans réserve.</>}
                                                checked={cgv}
                                                onChange={e => setCgv(e.target.checked)}
                                            />

                                            {organisation.cBLabel ? (
                                                <div className={"en-ligne no-wrap mobile-wrap"}>
                                                    <Loading_button className={"sec rouge"} is_loading={save_btn_delete_loading}
                                                                    onClick={handleSupprimerMoyenDePaiement}
                                                                    disabled={!cgv}> Supprimer le moyen de
                                                        paiement actuel</Loading_button>
                                                    <Loading_button is_loading={save_btn_loading}
                                                                    onClick={() => setStep(1)} disabled={!cgv}>Enregistrer un
                                                        nouveau moyen de paiement</Loading_button>
                                                </div>
                                            ) : (
                                                <Loading_button is_loading={save_btn_loading}
                                                                onClick={() => setStep(1)} disabled={!cgv}>Continuer</Loading_button>
                                            )}

                                        </>
                                    )}
                                    {step === 1 && (
                                        <>
                                            <div className={"info-facturation"}>
                                                <p>Informations de facturation : </p>
                                                <Switch
                                                    className={"margintop"}
                                                    label={"Professionnel"}
                                                    checked={infosFacturation.estPro}
                                                    onChange={e => {
                                                        let edit = {...infosFacturation};
                                                        edit.estPro = e.target.checked;
                                                        if (!e.target.checked) {
                                                            edit.numero_tva = undefined;
                                                            edit.numero_siret = undefined;
                                                        }

                                                        setInfosFacturation(edit);
                                                    }}
                                                />
                                                <TextInput
                                                    label={infosFacturation.estPro ? "Dénomination sociale" : "Nom et prénom"}
                                                    value={infosFacturation.denominationSociale}
                                                    onChange={e => {
                                                        let edit = {...infosFacturation};
                                                        edit.denominationSociale = e.target.value;
                                                        setInfosFacturation(edit);
                                                    }}
                                                />
                                                {infosFacturation.estPro && (
                                                    <>
                                                        <TextInput
                                                            label={"Numéro de TVA"}
                                                            value={infosFacturation.numero_tva}
                                                            onChange={e => {
                                                                let edit = {...infosFacturation};
                                                                edit.numero_tva = e.target.value;
                                                                setInfosFacturation(edit);
                                                            }}
                                                        />
                                                        <TextInput
                                                            label={"Numéro de siret"}
                                                            value={infosFacturation.numero_siret}
                                                            onChange={e => {
                                                                let edit = {...infosFacturation};
                                                                edit.numero_siret = e.target.value;
                                                                setInfosFacturation(edit);
                                                            }}
                                                        />
                                                    </>
                                                )}

                                                <p className={"margintop"}>Adresse de facturation : </p>
                                                <TextInput
                                                    label={"Rue"}
                                                    value={infosFacturation.addresse?.rue}
                                                    onChange={e => {
                                                        let edit = {...infosFacturation};
                                                        if (!edit.addresse) {
                                                            edit.addresse = {
                                                                rue: "",
                                                                ville: "",
                                                                code_postal: "",
                                                                pays: "France",
                                                            };
                                                        }
                                                        edit.addresse.rue = e.target.value;
                                                        setInfosFacturation(edit);
                                                    }}
                                                />
                                                <TextInput
                                                    label={"Ville"}
                                                    value={infosFacturation.addresse?.ville}
                                                    onChange={e => {
                                                        let edit = {...infosFacturation};
                                                        if (!edit.addresse) {
                                                            edit.addresse = {
                                                                rue: "",
                                                                ville: "",
                                                                code_postal: "",
                                                                pays: "France",
                                                            };
                                                        }
                                                        edit.addresse.ville = e.target.value;
                                                        setInfosFacturation(edit);
                                                    }}
                                                />
                                                <TextInput
                                                    label={"Code postal"}
                                                    value={infosFacturation.addresse?.code_postal}
                                                    onChange={e => {
                                                        let edit = {...infosFacturation};
                                                        if (!edit.addresse) {
                                                            edit.addresse = {
                                                                rue: "",
                                                                ville: "",
                                                                code_postal: "",
                                                                pays: "France",
                                                            };
                                                        }
                                                        edit.addresse.code_postal = e.target.value;
                                                        setInfosFacturation(edit);
                                                    }}
                                                />
                                                <TextInput
                                                    label={"Pays"}
                                                    value={infosFacturation.addresse?.pays}
                                                    onChange={e => {
                                                        let edit = {...infosFacturation};
                                                        if (!edit.addresse) {
                                                            edit.addresse = {
                                                                rue: "",
                                                                ville: "",
                                                                code_postal: "",
                                                                pays: "France",
                                                            };
                                                        }
                                                        edit.addresse.pays = e.target.value;
                                                        setInfosFacturation(edit);
                                                    }}
                                                />
                                            </div>

                                            <Loading_button is_loading={save_btn_loading}
                                                                onClick={handleGetBanque}
                                                            disabled={!cgv
                                                                || infosFacturation.denominationSociale === ""
                                                                || infosFacturation.numero_tva === ""
                                                                // || infosFacturation.numero_siret === "" //Pas obligatoire
                                                                || !infosFacturation.addresse
                                                                || infosFacturation.addresse.rue === ""
                                                                || infosFacturation.addresse.ville === ""
                                                                || infosFacturation.addresse.code_postal === ""
                                                                || infosFacturation.addresse.pays === ""
                                                            }
                                            >Continuer vers
                                                    LCL</Loading_button>
                                        </>
                                    )}


                                </>
                            )}
                        </>
                    )}

                </div>

            </Modal>


            <div className={vue_config_cellule ? "wrapper_enregistrer_cb" : "en-ligne en-tete-page"}
                 style={{margin: "0", width: "100%", flexWrap: "wrap"}}>
                {CB_perime_date === null && (
                    <div className={"inline-tag orange"}>
                        <IconeTriangleExclamation/>
                        Aucun moyen de paiement renseigné.
                    </div>
                )}
                {CB_perime_date && CB_perime_comp && CB_perime_comp > now_comp && (
                    <div className={"inline-tag green"}>
                        <IconeCreditCard/>
                        Un moyen de paiement est actif : {organisation.cBLabel} (expire : {affichage_expiration})
                    </div>
                )}
                {CB_perime_date && CB_perime_comp && CB_perime_comp == now_comp && (
                    <div className={"inline-tag orange"}>
                        <IconeTriangleExclamation/>
                        Votre moyen de paiement ({organisation.cBLabel}) est sur le point d'expirer
                        ({affichage_expiration}).
                        <br/>Merci d'en renseigner un nouveau sans
                        attendre.
                    </div>
                )}
                {CB_perime_date && CB_perime_comp && CB_perime_comp < now_comp && (
                    <div className={"inline-tag red"}>
                        <IconeTriangleExclamation/>
                        Le moyen de paiement ({organisation.cBLabel}) à expiré ({affichage_expiration}).
                        <br/>Merci d'en renseigner un nouveau sans attendre.
                    </div>
                )}


                <button style={{margin: "unset"}} onClick={handlers.open}><IconeCreditCard/>
                    {vue_config_cellule ? "Ajouter un moyen de paiement" : "Moyen de paiement"}
                </button>


            </div>


        </>

    )
}