import {Navbar} from "../../../components/navbar/navbar";
import React, {useEffect, useMemo, useState} from "react";

import './Anomalies.scss'
import '../Mesures/Modales/modale_configurer_conditions.scss'
import {Tableau} from "../../../components/tableau/Tableau";
import ajax from "../../../services/AxiosInterceptor";
import {
    createColumnHelper,
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel, Row, SortingState,
    useReactTable
} from "@tanstack/react-table";
import {afficherDateTime, afficherHeure, DateTZ} from "../../../services/GestionDates";
import {Checkbox, NativeSelect, Tooltip} from "@mantine/core";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Icone, IconeLoader} from "../../../components/icone/icone";
import {Hub} from "../../../types/hub";
import {Organisation} from "../../../types/organisation";
import {Bandeau_user} from "../../../components/sidebar/bandeau_user/bandeau_user";
import env_config from "../../../env_config";
import {Condition, horaire_maintenance} from "../../../types/condition";
import {useDispatch} from "react-redux";
import {setMobile_sidebar_icon} from "../../../store/interfaceSlice";
import {Modale_edit_anomalies_en_masse} from "./modale_edit_anomalies_en_masse";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {
    Modale_alertes_users_condition
} from "../../../components/Modales/modale_alertes_users/modale_alertes_users_condition";
import {notifications} from "@mantine/notifications";
import {tab} from "@testing-library/user-event/dist/tab";
import {texte_est_inclus} from "../../../services/GestionTextes";
import {getAffichageNom, getAffichageNomShort} from "../../../services/UserService";
import {permissionIsGranted} from "../../../services/permissionsService";
import {IconeArrowDownToLine} from "../../../components/icone/solid_react/arrow-down-to-line";
import {IconeArrowUpToLine} from "../../../components/icone/solid_react/arrow-up-to-line";
import {IconeSquareExclamation} from "../../../components/icone/solid_react/square-exclamation";
import {IconeLinkSlash} from "../../../components/icone/solid_react/link-slash";
import {IconeSirenOn} from "../../../components/icone/solid_react/siren-on";
import {IconeUser} from "../../../components/icone/solid_react/user";
import {IconeEye} from "../../../components/icone/solid_react/eye";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeTrashCan} from "../../../components/icone/solid_react/trash-can";
import {IconeWifiExclamation} from "../../../components/icone/solid_react/wifi-exclamation";
import {IconeMobileSignalOut} from "../../../components/icone/solid_react/mobile-signal-out";
import {IconeBatteryLow} from "../../../components/icone/solid_react/battery-low";
import {IconeArrowRotateLeft} from "../../../components/icone/solid_react/arrow-rotate-left";
import {Anomalie_en_masse_tuto} from "../../../components/Tours/anomalie_en_masse_tuto";


export function Anomalies() {

    let orgaId = useParams().orgaId;
    const [organisation, setOrganisation] = useState<Organisation | null>(() => null);
    const [donnees, setDonnees] = useState<Condition[] | null>(() => null);

    const [sorting, setSorting] = React.useState<SortingState>([])
    const [columnVisibility, setColumnVisibility] = React.useState({})

    const [is_loading, setIs_loading] = useState(false);
    const navigate = useNavigate();

    const columnHelper = createColumnHelper<Condition>()

    const [rowSelection, setRowSelection] = React.useState<Record<string, boolean>>({})


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const url_param_cellule = queryParams.get('cellule'); //dateheure

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("aucun"))

    function genererToolTipSlug(condition:Condition, only_texte:boolean = false){
        let texte = "";
        let icone = <></>;
        let sonde = condition.helper_cellule?.sonde;
        if (condition.slug === 0) {
            texte = "(MIN) Plus petit que " + sonde?.affichage_prefix + condition.valeur_a_comparer + sonde?.affichage_suffix;
            icone = <IconeArrowDownToLine />;
        }
        if (condition.slug === 1) {
            texte = "(MAX) Plus grand que " + sonde?.affichage_prefix + condition.valeur_a_comparer + sonde?.affichage_suffix;
            icone = <IconeArrowUpToLine />;
        }
        if (condition.slug === 9) {
            texte = "(ERR) Sonde débranché";
            icone = <IconeSquareExclamation />;
        }
        if (condition.slug === 2) {
            texte = "Est : " + sonde?.affichage_faux_s;
            icone = <p>{sonde?.affichage_faux_s}</p>;
        }
        if (condition.slug === 3) {
            texte = "Est : " + sonde?.affichage_vrai_s;
            icone = <p>{sonde?.affichage_vrai_s}</p>;
        }
        if (condition.slug === 8 && !condition.is_hidden) {
            texte = "Capteur déconnecté (avancée)";
            icone = <IconeLinkSlash />;
        }
        if (condition.slug === 8 && condition.is_hidden) {
            texte = "Capteur déconnecté (auto)";
            icone = <IconeLinkSlash />;
        }
        if (condition.slug === 10) {
            texte = "Hub déconnecté";
            icone = <Icone nom={"signal-slash"} />;
        }
        if (condition.slug === 11) {
            texte = "Batterie faible";
            icone = <IconeBatteryLow />;
        }
        if (only_texte) return texte;
        return <Tooltip label={texte}><div>{icone}</div></Tooltip>
    }

    const columns = useMemo(() => [
        columnHelper.accessor('id', {
            id: 'Selection',
            header: ({table}) => (
                <span className={"aligne_left"}>
                    {'\u00A0'}
                    <Checkbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </span>

            ),
            cell: ({row}) => (
                <div className="px-1">
                    <Checkbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),

            enableColumnFilter: false,
            enableSorting: false
        }),
        columnHelper.accessor(row => row, {
            id: 'Cellule',
            header: () => 'Cellule',
            cell: info =>
                info.getValue().helper_cellule != null && (
                    <span>
                        {/*{info.getValue().helper_zone?.nom === "base.config.appareils" ? (*/}
                        {/*    <>*/}
                        {/*        <strong className={"th_mobile"}>Appareil : </strong>*/}
                        {/*        <p>{info.getValue().helper_cellule?.nom.replace("capteur.", "").replace("hub.", "")}</p>*/}
                        {/*    </>*/}
                        {/*) : (*/}
                            <>
                                <strong className={"th_mobile"}>Cellule : </strong>
                                <Link to={"/cellule/" + info.getValue().helper_cellule?.id}>{info.getValue().helper_cellule?.nom.replace("capteur.", "").replace("hub.", "")}</Link>
                            </>
                        {/*)}*/}
                    </span>
                ),
            enableColumnFilter: true,
            filterFn: (row: Row<Condition>,
                       columnId: string,
                       filterValue) => {
                if (row.getValue(columnId)) {
                    let condition:Condition = row.getValue(columnId);
                    if (condition.helper_cellule)
                        return texte_est_inclus(condition.helper_cellule.nom, filterValue)
                }
                return false;
            },
        }),
        columnHelper.accessor(row => row.nom, {
            id: 'Nom',
            header: () => 'Nom',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Nom : </strong>
                        {info.getValue()}
                </span>
                ),
        }),
        columnHelper.accessor(row => row.texte_a_afficher, {
            id: 'Texte',
            header: () => 'Texte',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Texte : </strong>
                        {info.getValue()}
                </span>
                ),
        }),
        columnHelper.accessor(row => row, {
            id: 'Type',
            header: () => 'Type',
            cell: info =>
                info.getValue() != null && (
                    <span className={"flex_align_center"} style={{gap: "0.5rem"}}>
                    <strong className={"th_mobile"}>Type : </strong>
                        {genererToolTipSlug(info.getValue())}
                        <i className={"th_mobile"}>{genererToolTipSlug(info.getValue(), true)}</i>
                        {info.getValue().repetition ? (
                            <>

                                <Tooltip label={"Répétition activée jusqu'à résolution"}><div><IconeArrowRotateLeft /></div></Tooltip>
                                <i className={"th_mobile"}>Répétition activée jusqu'à résolution</i>
                            </>
                        ) : ""}


                </span>
                ),
            sortingFn: (rowA: Row<Condition>,
                        rowB: Row<Condition>,
                        columnId: string) => {
                let condA = rowA.getValue<Condition>(columnId)
                let condB = rowB.getValue<Condition>(columnId)
                if (condA.slug > condB.slug) return 1
                if (condA.slug < condB.slug) return -1
                return 0
            },
            filterFn: (row: Row<Condition>,
                       columnId: string,
                       filterValue) => {
                let texte = genererToolTipSlug(row.getValue<Condition>(columnId), true);
                if (typeof texte === "string") {
                    return texte_est_inclus(texte + (row.getValue<Condition>(columnId).repetition ? " Répétition activée jusqu'à résolution" : ""), filterValue)
                }
                return false
            },
        }),
        columnHelper.accessor(row => row, {
            id: 'Valeur',
            header: () => 'Valeur',
            cell: info =>
                <span>
                    <strong className={"th_mobile"}>Valeur : </strong>
                    {info.getValue().slug < 2 && (
                        <>
                            {info.getValue().helper_cellule?.sonde?.affichage_prefix}
                            {info.getValue().valeur_a_comparer}
                            {info.getValue().helper_cellule?.sonde?.affichage_suffix}
                        </>
                    )}
                    {info.getValue().slug === 11 && (
                        <>
                            {"<" + info.getValue().valeur_a_comparer}%
                        </>
                    )}
                </span>,
            sortingFn: (rowA: Row<Condition>,
                        rowB: Row<Condition>,
                        columnId: string) => {
                let condA = rowA.getValue<Condition>(columnId)
                let condB = rowB.getValue<Condition>(columnId)
                let _valA = condA.valeur_a_comparer
                let _valB = condB.valeur_a_comparer


                if (condA.slug > 1) _valA = "-9999"
                if (condB.slug > 1) _valB = "-9999"

                if (parseInt(_valA) > parseInt(_valB)) return 1
                if (parseInt(_valA) < parseInt(_valB)) return -1
                return 0
            },
            filterFn: (row: Row<Condition>,
                       columnId: string,
                       filterValue) => {
                let texte =
                    row.getValue<Condition>(columnId).helper_cellule?.sonde?.affichage_prefix +
                    row.getValue<Condition>(columnId).valeur_a_comparer +
                    row.getValue<Condition>(columnId).helper_cellule?.sonde?.affichage_suffix
                return texte_est_inclus(texte, filterValue)
            },
        }),
        columnHelper.accessor(row => row.delais, {
            id: 'Durée',
            header: () => 'Durée',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Durée : </strong>
                        {info.getValue()} minutes
                </span>
                ),
        }),
        columnHelper.accessor(row => row.horaires_maintenance, {
            id: "Horaires de maintenance",
            header: () => 'Horaires de maintenance',
            cell: info =>
                <span className={"flex_align_center"}>
                    <strong className={"th_mobile"}>Horaires de maintenance : </strong>
                    {info.getValue().length === 0 && (
                        <div className={"inline-tag"}>Aucune</div>
                    )}
                    <div className={"horaires_maintenance"} style={{flexFlow: "column nowrap", fontSize: 'var(--texte-size-petit)'}}>
                                            {info.getValue()?.map(horaire => {
                                                return (
                                                    <div className={"horaire"} style={{cursor:"unset"}}>
                                                        <div className={"jour"}>{horaire.j.map(value => {
                                                            switch (value) {
                                                                case 1:
                                                                    return " L";
                                                                case 2:
                                                                    return " Ma";
                                                                case 3:
                                                                    return " Me";
                                                                case 4:
                                                                    return " J";
                                                                case 5:
                                                                    return " V";
                                                                case 6:
                                                                    return " S";
                                                                case 7:
                                                                    return " D";
                                                            }
                                                            return "?";
                                                        })}</div>
                                                        <div className={"heure"}>{
                                                            afficherHeure(
                                                                Math.floor(horaire.d / 60),
                                                                horaire.d % 60
                                                            )
                                                        } à {afficherHeure(
                                                            Math.floor(horaire.f / 60),
                                                            horaire.f % 60
                                                        )}</div>
                                                    </div>
                                                )
                                            })}
                    </div>

                </span>,
            enableColumnFilter: true,
            sortingFn: (rowA: Row<Condition>,
                        rowB: Row<Condition>,
                        columnId: string) => {
                let condA = rowA.getValue<horaire_maintenance[]>(columnId)
                let condB = rowB.getValue<horaire_maintenance[]>(columnId)
                if (condB && condA) {
                    if (condA.length > condB.length) return 1
                    if (condA.length < condB.length) return -1
                }
                if (condA && condB) return 1
                if (condB && !condA) return -1

                return 0
            },
            filterFn: (row: Row<Condition>,
                       columnId: string,
                       filterValue) => {
                let texte = "";
                row.getValue<horaire_maintenance[]>(columnId).map(horaire => {
                    horaire.j.map(value => {
                        switch (value) {
                            case 1:
                                texte += " Lundi ";
                                return " L"
                            case 2:
                                texte += " Mardi ";
                                return " Ma";
                            case 3:
                                texte += " Mercredi ";
                                return " Me";
                            case 4:
                                texte += " Jeudi ";
                                return " J";
                            case 5:
                                texte += " Vendredi ";
                                return " V";
                            case 6:
                                texte += " Samedi ";
                                return " S";
                            case 7:
                                texte += " Dimanche ";
                                return " D";
                        }
                        return "";
                    })
                    texte += " " + afficherHeure(
                        Math.floor(horaire.d / 60),
                        horaire.d % 60
                    )
                    texte += " " + afficherHeure(
                        Math.floor(horaire.f / 60),
                        horaire.f % 60
                    )
                })


                return texte_est_inclus(texte, filterValue)

            },
        }),
        columnHelper.accessor(row => row, {
            id: "Statut",
            header: () => 'Statut',
            cell: info =>
                <span className={"flex_align_center"}>
                    <strong className={"th_mobile"}>Abonnement : </strong>

                    {info.getValue().statut_validation > 0
                        ?
                        <Tooltip label={"Anomalie actuellement détectée"}>
                            <i className={"inline-tag red"} style={{width: "2rem"}}>
                                <IconeSirenOn/>
                            </i>
                        </Tooltip>

                        : <i className={"inline-tag grey"} style={{width: "2rem"}}>RAS</i>}

                </span>,
            enableColumnFilter: true,
            sortingFn: (rowA: Row<Condition>,
                        rowB: Row<Condition>,
                        columnId: string) => {
                let condA = rowA.getValue<Condition>(columnId)
                let condB = rowB.getValue<Condition>(columnId)
                if (condA.statut_validation > condB.statut_validation) return 1
                if (condA.statut_validation < condB.statut_validation) return -1
                return 0
            },
            filterFn: (row: Row<Condition>,
                       columnId: string,
                       filterValue) => {
                if (row.getValue<Condition>(columnId).statut_validation === 0) {
                    return "RAS".toLowerCase().includes(filterValue.toLowerCase())
                }
                return texte_est_inclus("Anomalie actuellement détectée", filterValue)
            },

        }),
        columnHelper.accessor(row => row, {
            id: "Dernier changement d'état",
            header: () => 'Dernier changement d\'état',
            cell: info =>
                <span>
                    <strong className={"th_mobile"}>Dernier changement d'état : </strong>
                    {afficherDateTime(new DateTZ(info.getValue().validation))}
                </span>,
            enableColumnFilter: true,
            sortingFn: (rowA: Row<Condition>,
                        rowB: Row<Condition>,
                        columnId: string) => {
                let dateA = new DateTZ(rowA.getValue<Condition>(columnId).validation)
                let dateB = new DateTZ(rowB.getValue<Condition>(columnId).validation)
                if (dateA < dateB) return -1
                else if (dateA == dateB) return 0
                else return 1
            },
            filterFn: (    row: Row<Condition>,
                           columnId: string,
                           filterValue) => {
                if (row.getValue<Hub>(columnId).derniere_connexion)
                {
                    return afficherDateTime(new DateTZ(row.getValue<Condition>(columnId).validation)).includes(filterValue)
                }
                return false;
            },
        }),
        columnHelper.accessor(row => row.alertes, {
            id: "Alertes",
            header: () => 'Alertes',
            cell: info =>
                <span>
                    <strong className={"th_mobile"}>Alertes : </strong>
                    {info.getValue().length === 0 ? (
                        <span className={"en-ligne no-wrap"} style={{padding: "0", width: "unset"}}>{info.getValue().length} <IconeUser/></span>
                    ) : (
                        <Tooltip multiline label={info.getValue().map(user => <p>- {getAffichageNom(user.utilisateur)}</p>)}>
                            <span className={"en-ligne no-wrap"} style={{padding: "0", width: "unset"}}>{info.getValue().length} <IconeUser/></span>
                        </Tooltip>
                    )}


                </span>,
            enableColumnFilter: false,
        }),
    ], [])

    const [btn_loading_delete, set_btn_loading_delete] = useState(false);

    function handleSupprimer() {
        if (!organisation || organisation.zones === undefined || organisation.zones?.length === 0) return;
        if (window.confirm("Vous allez supprimer ces conditions : \n"
                                + selectedRows.map(c => "\n - \"" + c.nom + "\" de la cellule \"" + c.helper_cellule?.nom + "\"")
                                + "\n\nEtes vous sûr de vouloir continuer ?"
                            ))
        {
            ajax.delete("/organisations/" + organisation.zones[0].organisation_id + "/conditions", {
                data: {
                    "ids" : selectedRows.map(r => r.id)
                }
            })
                .then(response => {
                    queryLastInfos()
                    table.resetRowSelection(false)
                    set_btn_loading_delete(false);
                }).catch(error => {
                set_btn_loading_delete(false);
            })
            set_btn_loading_delete(true);
        }

    }

    useEffect(() => {
        queryLastInfos();
        const interval_clear = setInterval(() => {
            queryLastInfos();
        }, env_config.refresh_rate);
        
        return () => {
            clearInterval(interval_clear);
        }
    }, [])

    function queryLastInfos() {
        setIs_loading(true)
        ajax.get('/organisations/' + orgaId + '/lastInfos').then(response => {
            let orga: Organisation = response.data;
            setOrganisation(orga);
            let conditions: Condition[] = [];
            let conditions_deconnexion:number[] = [];
            orga.zones?.map(z => z.cellules?.map(cell => cell.conditions.map(cond => {

                if (cond.slug === 8 || cond.slug === 11 ) {
                    if (conditions_deconnexion.includes(cond.id)) return false;
                    conditions_deconnexion.push(cond.id);
                }
                let _cond = {...cond};
                _cond.helper_cellule = {
                    "id": cell.id,
                    "nom": cell.nom,
                    "sonde": cell.sonde_type
                }
                _cond.helper_zone = {
                    "id": z.id,
                    "nom": z.nom,
                }
                conditions.push(_cond);
            })))
            setDonnees(conditions);
            setIs_loading(false);
        }).catch(e => {
            setIs_loading(false)
        })
    }

    const table = useReactTable<Condition>({
        data: donnees ? donnees : [],
        columns: columns,
        state: {
            sorting,
            columnVisibility,
            rowSelection,
        },
        enableRowSelection: true, //enable row selection for all rows
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        enableFilters: true,
        enableColumnFilters: true
    })

    const [run_once, setRun_once] = useState(false);
    useEffect(() => {
        if (url_param_cellule && !run_once){
            table.setColumnFilters([{"id":"Cellule","value":url_param_cellule}])
            // table.setRowSelection({
            //     "Cellule": true
            // })
            // let cellule = organisation?.zones?.find(z => z.nom === "base.config.appareils")?.cellules?.find(c => c.nom === "hub." + url_param_cellule);
            // alert(JSON.stringify(table.getRowModel().rows))
            // if (cellule) table.getRowModel().rows.find(r => r.getValue("Cellule") === cellule?.id )?.toggleSelected(true)

            let row = table.getRowModel().rows.find(r => r.original.helper_cellule?.nom === "hub." + url_param_cellule );
            row?.toggleSelected(true)
            if (row) setRun_once(true)
        }
    }, [donnees]);

    let selectedRows = table.getSelectedRowModel().rows.map(row => {
        return row.original;
    });

    let compteur_anomalies:number[] = [];
    donnees?.map(condition => {
        if (condition.statut_validation > 0) {
            if (!compteur_anomalies.includes(condition.id)) compteur_anomalies.push(condition.id);
        }
    })

    return (
        <div className={"app"}>
            <Navbar/>

            <div className={"content content-fullwidth en-tete-pages"}>

                <div className={"en-ligne en-tete-page"}>
                    <div className={"titre"}>
                        <h1>Vos anomalies</h1>
                        <p>
                            Voici les anomalies renseignées dans les cellules de vos organisations
                        </p>
                    </div>

                    <div className={"mini-sidebar"}>
                        <Bandeau_user/>
                    </div>


                </div>
                <div className={"en-ligne en-tete-page"}>

                    {is_loading ? (<div className={"en-ligne"}><IconeLoader /> Chargement...</div>) : (
                        <div></div>)}


                    <div className={"flex-row"}>
                        {donnees && organisation && (
                            <>
                                <button onClick={() => {
                                    // alert(JSON.stringify(table.getState().columnFilters))
                                    // table.setSorting([{"id":"Statut","desc":true}])
                                    if (table.getState().columnFilters.find(c => 'Détectée' === c.value))
                                    {
                                        table.setColumnFilters([])
                                    }
                                    else{
                                        table.setColumnFilters([{"id":"Statut","value":"Détectée"}])
                                    }

                                }} className={compteur_anomalies.length > 0 ? "red" : ""}  id={"btn_voir_actives"}>
                                    <IconeEye/>
                                    {(table.getState().columnFilters.find(c => 'Détectée' === c.value)) ? <>Voir tout ({donnees?.length})</> : <>Voir les anomalies actives ({compteur_anomalies.length})</>}
                                </button>

                                <div>|</div>


                                {permissionIsGranted(Number(orgaId), "Gestion_alertes_toutes") && (
                                    <Modale_alertes_users_condition organisation_id={parseInt(orgaId ? orgaId : "0")} conditions={selectedRows} forcer={url_param_cellule !== null && run_once} refreshParent={() => {
                                        notifications.show({
                                            withCloseButton: true,
                                            autoClose: env_config.notifications_duree_affichage,
                                            color: 'green',
                                            title: "Appliqué",
                                            message: 'Les alertes ont été appliquées',
                                            icon: <IconeCheck/>,
                                        });
                                        queryLastInfos();
                                    }} />
                                )}

                                {permissionIsGranted(Number(orgaId), "Administrer_anomalies") && (
                                    <Modale_edit_anomalies_en_masse
                                        organisation={organisation}
                                        conditionsAModifier={selectedRows.filter(c => !c.is_hidden)}
                                        refreshParent={() => {
                                            notifications.show({
                                                withCloseButton: true,
                                                autoClose: env_config.notifications_duree_affichage,
                                                color: 'green',
                                                title: "Appliqué",
                                                message: 'Les anomalies ont été modifiées',
                                                icon: <IconeCheck/>,
                                            });
                                            queryLastInfos();
                                        }} />
                                )}

                                {permissionIsGranted(Number(orgaId), "Administrer_anomalies") && (
                                    <Loading_button is_loading={btn_loading_delete}
                                                    onClick={handleSupprimer}
                                                    disabled={selectedRows.filter(c => !c.is_hidden && ![10,11].includes(c.slug)).length === 0}
                                                    className={"red"}
                                    >
                                        <IconeTrashCan/> Supprimer les anomalies sélectionnées
                                    </Loading_button>
                                )}

                            </>

                        )}

                        <div>|</div>

                        <div>{table.getRowModel().rows.length} lignes affichés</div>
                        <NativeSelect
                            className={"select_nbe_lignes match_height"}
                            value={table.getState().pagination.pageSize}
                            onChange={e => {
                                table.setPageSize(Number(e.target.value))
                            }}
                            data={["5", "10", "20", "50", "100"]}
                        />
                    </div>
                </div>


                <Tableau table={table} donnees={donnees}/>
            </div>

            {!url_param_cellule && <Anomalie_en_masse_tuto />}

        </div>

    );
    return (
        <></>
    );
}
