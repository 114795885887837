import {Cellule} from "../../../../types/cellule";
import React, {useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../../services/AxiosInterceptor";
import {FileButton, Modal, NumberInput, TextInput} from "@mantine/core";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {IconeTag} from "../../../../components/icone/solid_react/tag";
import {IconeFileCsv} from "../../../../components/icone/solid_react/file-csv";
import {IconeFileCheck} from "../../../../components/icone/solid_react/file-check";
import {IconeFileImport} from "../../../../components/icone/solid_react/file-import";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {DateTZ} from "../../../../services/GestionDates";

export function Modale_charger_csv_mesures({
                                       cellule,
                                       refresh
                                   }: { cellule: Cellule, refresh: () => void }) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);
    const [nom, set_nom] = useState(cellule.nom);
    const [fichier, setFichier] = useState<File|null>(null);

    // if (forcer) handlers.open();

    const [correction_timezone, setCorrection_timezone] = useState<number>(new DateTZ().getTimezoneOffset());

    function handleEnregistrer() {
        const formData = new FormData();
        if (!fichier) {
            alert("Merci d'uploader un fichier.");
            return
        }
        set_btn_loading(true);
        if (fichier) formData.append("fichier", fichier);
        formData.append("correction_timezone", correction_timezone+"");

        ajax.post("/cellules/" + cellule.id + "/uploadCSV", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(response => {
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'Fichier traité : ' + response.data.nbe_traitees + " lignes traitées, " + response.data.nbe_erreurs + " lignes en erreur",
                icon: <IconeCheck/>,
            });
            handlers.close();
            set_btn_loading(false);
        }).catch(error => {
            set_btn_loading(false);
        })

    }

    return (
        <>
            <button className={"sec"} onClick={handlers.open}><IconeFileCsv/> Charger un CSV</button>
            <Modal opened={opened} onClose={handlers.close} title="Charger un fichier CSV">
                <div className={"form"}>

                    <NumberInput name={"correction_timezone"} id={"correction_timezone"} required
                               label="Timezone en minutes : (prérempli avec le fuseau actuel du compte)"
                               value={correction_timezone}
                               onChange={(e) => { setCorrection_timezone(Number(e))}}
                                 step={1}
                    />

                    <FileButton onChange={setFichier}>
                        {(props) => <button className={"" + (fichier?"vert":"sec")} {...props}>
                            {fichier ? (
                                <><IconeFileCheck /> {fichier.name}</>
                            ) : (<><IconeFileImport /> Upload le rapport d'étalonnage?</>)}
                        </button>}
                    </FileButton>

                    <Loading_button is_loading={btn_loading}
                                    onClick={handleEnregistrer}>Enregistrer</Loading_button>
                </div>

            </Modal>
        </>

    )
}