import React, {useEffect, useRef, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../services/AxiosInterceptor";
import {Checkbox, Modal, NativeSelect, NumberInput} from "@mantine/core";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {Organisation_paiement} from "../../../types/organisation";
import {notifications} from "@mantine/notifications";
import env_config from "../../../env_config";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeMessageSms} from "../../../components/icone/solid_react/message-sms";
import {IconePhone} from "../../../components/icone/solid_react/phone";
import {PrixSMS} from "../../../types/codes_promo";
import {Loading_div} from "../../../components/loading_div/loading_div";
import {permissionIsGranted} from "../../../services/permissionsService";
import {IconeXmark} from "../../../components/icone/solid_react/xmark";
import {IconeLink} from "../../../components/icone/solid_react/link";
import {tva} from "../../../services/UserService";

export function Modale_credit_sms_tel({
                                          organisation_full, refresh
                                      }: { organisation_full: Organisation_paiement, refresh: () => void }) {
    const [opened, handlers] = useDisclosure(false);
    const [save_btn_loading, set_save_btn_loading] = useState(false);

    const [prixSMS, setPrixSMS] = useState<PrixSMS[]|null>(null);
    const [prixTel, setPrixTel] = useState<PrixSMS[]|null>(null);

    const [TEL_a_recharger, setTEL_a_recharger] = useState<string | null>('0');
    const [SMS_a_recharger, setSMS_a_recharger] = useState<string | null>('0');

    const [valider_sans_payer, setValider_sans_payer] = useState(false);
    const [cgv, setCgv] = useState(false);

    type ResponseBanque = {
        "redirectionUrl": string,
        "redirectionVersion": string,
        "redirectionData": string,
    }
    const [responseBanque, setResponseBanque] = useState<ResponseBanque|null>(null);

    const iframeRef = useRef(null);
    const [isPaymentDone, setIsPaymentDone] = useState<0|1|-1>(0);
    useEffect(() => {
        const handleMessage = (event:any) => {
            if (event.data === 'payment-complete') {
                setIsPaymentDone(1);
                setResponseBanque(null);
            }
            if (event.data === 'payment-error') {
                setIsPaymentDone(-1);
                setResponseBanque(null);
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, []);

    // if (forcer) handlers.open();
    let organisation = organisation_full

    function handleEnregistrer() {
        set_save_btn_loading(true);
        ajax.post("/organisations/" + organisation.organisation.id + "/creditSMS", {
            "credit_SMS": Number(SMS_a_recharger),
            "credit_Tel": Number(TEL_a_recharger),
            "valider_sans_payer": valider_sans_payer,
        }).then(response => {
            set_save_btn_loading(false);

            if (valider_sans_payer)
            {
                notifications.show({
                    // id: 'enregistre',
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'green',
                    title: "Validé!",
                    message: 'Les crédits ont étés ajoutés à l\'organisation.',
                    icon: <IconeCheck/>,

                    // className: 'my-notification-class',
                    loading: false,
                });
                handlers.close();
                refresh();
                set_save_btn_loading(false);
            }
            else{
                let _responseBanque: ResponseBanque = response.data
                setResponseBanque(_responseBanque);

                if (iframeRef.current && _responseBanque) {
                    // Create a hidden form programmatically
                    const form = document.createElement('form');
                    form.action = _responseBanque.redirectionUrl;
                    form.method = 'POST';
                    // @ts-ignore
                    form.target = iframeRef.current.name; // Target the iframe by name

                    // Append post data as hidden inputs
                    const inputa = document.createElement('input');
                    inputa.type = 'hidden';
                    inputa.name = "redirectionVersion";
                    inputa.value = _responseBanque.redirectionVersion;
                    form.appendChild(inputa);

                    const inputz = document.createElement('input');
                    inputz.type = 'hidden';
                    inputz.name = "redirectionData";
                    inputz.value = _responseBanque.redirectionData;
                    form.appendChild(inputz);

                    document.body.appendChild(form); // Append form to the body
                    form.submit(); // Submit the form to the iframe
                    document.body.removeChild(form); // Remove the form from the DOM
                }

                set_save_btn_loading(false);
            }


        }).catch(error => {
            set_save_btn_loading(false);
        })
    }


    useEffect(() => {
        ajax.get("/be/prixSMS").then(response => {
            setPrixSMS(response.data.prixSMS)
            setPrixTel(response.data.prixTel)
        }).catch(e => {
        })
    }, []);

    function handleClose()
    {
        handlers.close();
        setResponseBanque(null);
        setTimeout(() => {
            refresh()
        }, 400);
    }


    return (
        <>


            <Modal opened={opened}
                   onClose={handlers.close}
                   title="Recharger les SMS et Appels automatisés"
                   fullScreen={responseBanque !== null}
                   className="Modale_enregistrer_cb"

            >

                <div className={"form"}>



                    {!prixSMS || !prixTel ? (
                        <Loading_div />
                        ) : (
                        <>

                            {isPaymentDone !== 0 ? (
                                <>
                                    {isPaymentDone === 1 ? (
                                        <>
                                            <p className={"notice green en-ligne"}><IconeCheck/> Votre paiement à été
                                                validé.</p>
                                            <p>Le résultat peut être instantané ou prendre jusqu'à quelques heures.</p>
                                            <p>Vous recevrez un email de confirmation automatique quand la banque sera revenu
                                                vers nous.</p>
                                            <p>Merci de votre confiance.</p>
                                            <button onClick={handleClose}>Continuer</button>
                                        </>
                                    ) : (
                                        <>
                                            <p className={"notice red en-ligne"}><IconeXmark/> Votre paiement n'a pas été
                                                validé.</p>
                                            <p>Si vous pensez qu'il s'agit d'une erreur, contactez nous directement : </p>
                                            <a className={"en-ligne"} href="https://freshtime.fr/fr/contact" target={"_blank"}><IconeLink /> Freshtime - Contact</a>
                                            <div className={"en-ligne"}>
                                                <button className={"sec"} onClick={() => {
                                                    handlers.close();
                                                    setIsPaymentDone(0);
                                                    setResponseBanque(null);
                                                }}>Annuler</button>
                                                <button onClick={() => {
                                                    setIsPaymentDone(0);
                                                    setResponseBanque(null);
                                                }}>Recommencer</button>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>

                                    <iframe
                                        ref={iframeRef}
                                        name="paymentIframe" // Name matches the form target
                                        style={{
                                            border: 'none',
                                            display: responseBanque ? 'unset' : 'none',
                                            width: "100%", height: "100%"
                                        }}
                                    ></iframe>

                                    {(!responseBanque) && (
                                        <>

                                            <div className={"en-ligne"}>
                                                <p className={"inline-tag"}>Actuellement, {organisation_full.organisation.credit_SMS} SMS
                                                    restant.</p>
                                                <p className={"inline-tag"}>Actuellement, {organisation_full.organisation.credit_Tel} Appels
                                                    restant.</p>
                                            </div>

                                            <NativeSelect
                                                data={[{label: "Aucun SMS", value: "0"}, ...prixSMS.map(ligne => {
                                                    return {
                                                        label: ligne.nbe + " SMS pour " + ligne.prix + "€ HT ("+tva(ligne.prix)+")",
                                                        value: ligne.nbe + ""
                                                    }
                                                })]}
                                                value={Number(SMS_a_recharger)}
                                                onChange={e => setSMS_a_recharger(e.target.value + "")}
                                                label={"Combien de SMS à ajouter à l'organisation ?"}
                                                leftSection={<IconeMessageSms/>}
                                            />
                                            <NativeSelect
                                                data={[{label: "Aucun appel", value: "0"}, ...prixTel.map(ligne => {
                                                    return {
                                                        label: ligne.nbe + " appels pour " + ligne.prix + "€ HT ("+tva(ligne.prix)+")",
                                                        value: ligne.nbe + ""
                                                    }
                                                })]}
                                                value={Number(TEL_a_recharger)}
                                                onChange={e => setTEL_a_recharger(e.target.value + "")}
                                                label={"Combien d'appels automatisés à ajouter à l'organisation ?"}
                                                leftSection={<IconePhone/>}
                                            />
                                            {/*<NumberInput value={Number(SMS_a_recharger)}*/}
                                            {/*             onChange={e => setSMS_a_recharger(e + "")}*/}
                                            {/*             label={"Combien de SMS à ajouter à l'organisation ?"}*/}
                                            {/*             leftSection={<IconeMessageSms/>}*/}
                                            {/*/>*/}
                                            {/*<NumberInput value={Number(TEL_a_recharger)}*/}
                                            {/*             onChange={e => setTEL_a_recharger(e + "")}*/}
                                            {/*             label={"Combien d'appels automatisés à ajouter à l'organisation ?"}*/}
                                            {/*             leftSection={<IconePhone/>}*/}
                                            {/*/>*/}

                                            <Checkbox
                                                label={<>J'ai lu les <a href={env_config.url_cg_vente} target="_blank">conditions
                                                    générales de
                                                    ventes</a> et j'y adhère sans réserve.</>}
                                                checked={cgv}
                                                onChange={e => setCgv(e.target.checked)}
                                            />

                                            {permissionIsGranted(undefined, "ADMIN") && (
                                                <Checkbox
                                                    label={"ADMIN : Valider sans payer"}
                                                    checked={valider_sans_payer}
                                                    onChange={e => setValider_sans_payer(e.target.checked)}
                                                />
                                            )}


                                            <Loading_button is_loading={save_btn_loading}
                                                            onClick={handleEnregistrer} disabled={!cgv}>Valider la
                                                recharge</Loading_button>
                                        </>
                                    )}
                                </>
                            )}

                        </>
                    )}

                </div>

            </Modal>
            <button className={""} onClick={handlers.open}><IconeMessageSms/> Recharger les SMS et Appels</button>


        </>

    )
}