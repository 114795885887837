import {Cellule} from "../../../../types/cellule";
import {useDisclosure} from "@mantine/hooks";
import React, {forwardRef, ReactElement, useEffect, useState} from "react";
import ajax from "../../../../services/AxiosInterceptor";
import {Offre_abonnement, Statut_cellule} from "../../../../types/statut_cellule";
import {
    Accordion,
    CloseButton, Combobox,
    Group, InputBase, Input,
    Modal,
    NumberInput,
    Select, SimpleGrid,
    Stepper,
    Switch,
    Table,
    TextInput,
    Tooltip, useCombobox, Box, Checkbox, NativeSelect
} from "@mantine/core";
import {IconeLoader} from "../../../../components/icone/icone";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;
import {Extension} from "../../../../types/extension";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import "./modale_configurer_cellule.scss"
import {current} from "@reduxjs/toolkit";
import {
    Modale_horaire_maintenance
} from "../../../../components/Modales/Modale_horaire_maintenance/Modale_horaire_maintenance";
import {horaire_maintenance} from "../../../../types/condition";
import {condition_unit} from "./modale_configurer_conditions";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {IconeToggleOff} from "../../../../components/icone/solid_react/toggle-off";
import {IconeCircleInfo} from "../../../../components/icone/solid_react/circle-info";
import {IconeStopwatch} from "../../../../components/icone/solid_react/stopwatch";
import {IconePlus} from "../../../../components/icone/solid_react/plus";
import {afficherDate, DateTZ} from "../../../../services/GestionDates";
import {Capteur} from "../../../../types/capteur";
import {Sonde} from "../../../../types/sonde";
import {Configurer_conditions_rapides} from "./modale_configurer_conditions_rapides";
import {IconeArrowRight} from "../../../../components/icone/solid_react/arrow-right";
import {IconeFloppyDisk} from "../../../../components/icone/solid_react/floppy-disk";
import {IconeMessageSms} from "../../../../components/icone/solid_react/message-sms";
import {IconeAt} from "../../../../components/icone/solid_react/at";
import {IconePhoneVolume} from "../../../../components/icone/solid_react/phone-volume";
import {IconeBell} from "../../../../components/icone/solid_react/bell";
import {
    Modale_alertes_users_cellules
} from "../../../../components/Modales/modale_alertes_users/modale_alertes_users_cellules";
import {IconeInfo} from "../../../../components/icone/solid_react/info";
import {useNavigate} from "react-router-dom";
import {IconeToggleOn} from "../../../../components/icone/solid_react/toggle-on";
import {permissionIsGranted} from "../../../../services/permissionsService";
import {useDispatch, useSelector} from "react-redux";
import {setPasApasEtat} from "../../../../store/pasApasSlice";
import {texte_est_inclus} from "../../../../services/GestionTextes";
import {IconeGifts} from "../../../../components/icone/solid_react/gifts";
import {IconeGift} from "../../../../components/icone/solid_react/gift";
import {setNePlusAfficherModalePromoOrdreCellules} from "../../../../store/interfaceSlice";
import {RootState} from "../../../../store/store";
import {Historique_statut} from "../../../../types/historique_statut";
import {Organisation} from "../../../../types/organisation";
import {editOrganisation, majSimpleOrganisation, Orga_state} from "../../../../store/organisationSlice";
import {Loading_div} from "../../../../components/loading_div/loading_div";
import {IconeCreditCard} from "../../../../components/icone/solid_react/credit-card";
import {Modale_enregistrer_cb} from "../../Paiements/modale_enregistrer_cb";
import {checkSiOrgaAMoyenDePaiement} from "../../../../services/OrganisationServices";

export function Modale_configurer_cellule(
    {cellule, setCellule, forcer, extension_url_param, capteur_url_param, children, etape, ouvrirModaleAlertes}:
    {
        cellule: Cellule,
        setCellule: (cellule: Cellule | null) => void,
        forcer: boolean,
        extension_url_param?: string | null,
        capteur_url_param?: string | null,
        children: ReactElement, etape?: number
        ouvrirModaleAlertes?: () => void
    }
) {
    const [opened, {open, close}] = useDisclosure(forcer);
    const [btn_loading, set_loading_btn] = useState(false);

    const [offres_abonnements, set_offres_abonnements] = useState<Offre_abonnement[] | null>(null);
    const [status, set_statuts] = useState<Statut_cellule[] | null>(null);

    const [statutChoisi, setStatutChoisi] = useState<Statut_cellule | null>(null)//cellule?.statut_actuel?.statut ?? null

    type Data_select_extension = {
        image: string,
        label: string,
        value: string,
        description?: string,
        group: string,
        extension?: Extension,
        sonde?: Sonde
    }[];
    const [extensions_toutes, setExtensions_toutes] = useState<Extension[] | null>(null);
    const [data_select_capteur, setData_select_capteur] = useState<Data_select_extension | null>(null);
    const [data_select_sonde, setData_select_sonde] = useState<Data_select_extension | null>(null);
    const [data_select_extension, setData_select_extension] = useState<Data_select_extension | null>(null);
    const [value_capteur, setValue_capteur] = useState<string | null>(capteur_url_param ? capteur_url_param : null);
    const [value_extension, setValue_extension] = useState<string | null>(extension_url_param ? extension_url_param : null);
    const [value_sonde, setValue_sonde] = useState<string | null>(null);

    const [recherche_abonnement_admin, setRecherche_abonnement_admin] = useState("");

    const [modale_desactiver_cellule_opened, handlers_modale_desactiver_cellule] = useDisclosure(false);

    const [sondes_toutes, setSondes_toutes] = useState<Sonde[] | null>(null);

    const navigate = useNavigate();


    const [nom, set_nom] = useState(cellule.nom === "Nouvelle cellule" ? "" : cellule.nom);
    const [configRapide, setConfigRapide] = useState(cellule.config_rapide);

    const [clientValideDesac, setClientValideDesac] = useState(false);
    const [clientValide, setClientValide] = useState(true);

    const [active, setActive] = useState(etape ?? 0);


    let cr_min_index = cellule.conditions.filter(c => c.slug === 0)[0];
    let cr_max_index = cellule.conditions.filter(c => c.slug === 1)[0];
    let cr_etat_index_normal = cellule.conditions.filter(c => c.slug === 2)[0];
    let cr_etat_index_anormal = cellule.conditions.filter(c => c.slug === 3)[0];

    let cr_etat_index_bool_actif = null;
    if (cr_etat_index_normal) cr_etat_index_bool_actif = cr_etat_index_normal;
    if (cr_etat_index_anormal) cr_etat_index_bool_actif = cr_etat_index_anormal;

    let cr_delais_horaire = cr_min_index ? cr_min_index : cr_max_index; //Si un seul des deux est actif

    const [cr_min, setCRMIN] = useState(cr_min_index ? parseInt(cr_min_index.valeur_a_comparer) : 0);
    const [cr_max, setCRMAX] = useState(cr_max_index ? parseInt(cr_max_index.valeur_a_comparer) : 0);
    const [cr_min_etat, setCRMIN_Etat] = useState(cr_min_index ? true : false);
    const [cr_max_etat, setCRMAX_Etat] = useState(cr_max_index ? true : false);
    const [cr_etat_anormal, setCR_Etat_anormal] = useState(cr_etat_index_normal == null || cr_etat_index_anormal != null);
    const [cr_etat_anormal_texte, setCr_etat_anormal_texte] = useState(cr_etat_index_bool_actif?.texte_a_afficher ?? "");
    const [cr_etat_anormal_active, setCR_Etat_anormal_active] = useState(cr_etat_index_normal != null || cr_etat_index_anormal != null);
    const [cr_duree, setCR_Duree] = useState(cr_delais_horaire ? cr_delais_horaire.delais : 60);
    const [cr_horaires, setCR_Horaires] = useState<horaire_maintenance[]>(cr_delais_horaire ? cr_delais_horaire.horaires_maintenance : []);

    const [horaireInverser, set_horaireInverser] = useState<boolean>(cellule.inverserHorairesMaintenance);
    useEffect(() => {
        set_horaireInverser(cellule.inverserHorairesMaintenance);
    }, [cellule]);

    const [hide_modale, set_hide_modale] = useState(false);


    const organisations_state = useSelector<RootState, Orga_state>(state => state.organisation)


    function getID_step(step: ("nom" | "capteur" | "statut" | "enregistrement_CB" | "anomalies" | "resume_et_confirmation")) {
        let index_compteur = 0;

        ["nom", "capteur", "statut", "enregistrement_CB", "anomalies", "resume_et_confirmation"].every(page => {
            if (step === page) {
                return false; //With every(), return false is equivalent to a break
            }
            if (page === "enregistrement_CB" && checkSiOrgaAMoyenDePaiement(cellule.organisation.id)) {
                // Step caché, donc index_compteur ne s'incremente pas
                // Ce step est visible que quand on est dessus.
                // index_compteur--;
            } else if (page === "anomalies" && !statutChoisi?.can_use_alertes) {
                // Step caché, donc index_compteur ne s'incremente pas
            } else {
                index_compteur++;
            }

            console.log("PAGE " + page + " : " + index_compteur);

            return true; //With every(), return true is equivalent to a continue.
        })

        return index_compteur;
    }


    function checkNextStep() {
        if (active === getID_step("nom")) { //Nom
            return (!nom || nom === "")
        }
        if (active === getID_step("capteur")) { // Capteur
            return (!value_sonde || !value_extension)
        }
        if (active === getID_step("statut")) { //Statut
            return (!statutChoisi)
        }
        if (active === getID_step("enregistrement_CB")) { //Enregistrement CB
            return (!checkSiOrgaAMoyenDePaiement(cellule.organisation.id) && !forcer_valide_paiement)
        }
        if (active === getID_step("anomalies")) { //Anomalies
            return (!true)
        }
        if (active === getID_step("resume_et_confirmation")) { //Résumé et confirmation
            return (!clientValide)
        }
        if (active > getID_step("resume_et_confirmation")) {
            return false
        }
    }

    const [forcer_valide_paiement, setForcer_valide_paiement] = useState(false);



    function nextStep(validerEtConfigAlertes?: boolean) {
        if (!checkNextStep()) {
            let add_active = 1;
            // if (active + add_active === getID_step("enregistrement_CB") && checkSiOrgaAMoyenDePaiement()) {
            //     add_active += 1; //Si moyen de paiement, on skip
            // }
            // if (active + add_active === getID_step("anomalies") && !statutChoisi?.can_use_alertes) {
            //     add_active += 1; //Si pas d'alerte dans l'abonnement, on skip
            // }

            setActive(active + add_active);

            if (active + 1 > getID_step("resume_et_confirmation")) handleEnregistrer(validerEtConfigAlertes);
            else {
                setClientValide(statutChoisi?.id === cellule.statut_actuel?.statut.id)
            }
        } else {
            if (active === getID_step("nom")) alert("Merci de renseigner un nom pour la cellule.");
            if (active === getID_step("capteur")) alert("Merci d'indiquer la sonde qui doit être utilisée.");
            if (active === getID_step("statut")) alert("Merci de choisir un abonnement pour votre cellule.");
            if (active === getID_step("enregistrement_CB")) alert("Merci de valider un moyen de paiement.");
            if (active === getID_step("anomalies")) alert("Checker les infos des anomalies");
            if (active === getID_step("resume_et_confirmation")) alert("Merci de valider les modifications.");
        }
    }

    useEffect(() => {
        if (active === getID_step("capteur")) {
            dispatch(setPasApasEtat("modale_config_cellule_page2"))
        }
        if (active === getID_step("statut")) {
            dispatch(setPasApasEtat("modale_config_cellule_page3"))
            if (cellule.extension?.capteur.produit.ref === "evolution" && !modal_promo_ne_plus_afficher) {
                setModal_promo_openned(true)
            }
            getStatutsDispoAvecExtensionChoisie();
        }
        if (active === getID_step("enregistrement_CB")) {
            dispatch(setPasApasEtat("modale_config_cellule_page3-cb"))
        }
        if (active === getID_step("anomalies")) {
            dispatch(setPasApasEtat("modale_config_cellule_page3b"))
        }
        if (active === getID_step("resume_et_confirmation")) {
            dispatch(setPasApasEtat("modale_config_cellule_page4"))
        }
    }, [active]);


    function prevStep() {
        if (active == 0) close();
        let add_active = 1;
        // if (active - add_active === getID_step("anomalies") && !statutChoisi?.can_use_alertes) {
        //     add_active += 1;
        // }
        // if (active - add_active === getID_step("enregistrement_CB") && checkSiOrgaAMoyenDePaiement()) {
        //     add_active += 1;
        // }

        setActive(active > 0 ? active - add_active : active);
    }

    function display_notice_abonnement(marginBottom: string) {
        if (!((statutChoisi?.duree_engagement && statutChoisi?.duree_engagement > 0) && statutChoisi.id !== cellule.statut_actuel?.statut.id)
            //&& !((cellule.statut_actuel?.statut.duree_engagement && cellule.statut_actuel?.statut.duree_engagement > 0)
            && !(new DateTZ(cellule.statut_actuel?.engage_jusqua).getTime() > new DateTZ().getTime())) return <></>

        return <div style={{marginBottom: marginBottom}}>
            {(statutChoisi?.duree_engagement && statutChoisi?.duree_engagement > 0) && statutChoisi.id !== cellule.statut_actuel?.statut.id ? (
                <p className={"notice green"} style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>

                    L'abonnement que vous avez choisi
                    comporte un engagement, ce qui veux dire que
                    vous ne pourrez
                    désactiver ou changer l'abonnement de votre cellule pour un niveau inférieur avant
                    le {calcDateFinEngagement()}. Après cette date, vous ne serez plus engagé mais le tarif mensuel
                    restera le même.
                    {/*<a style={{color: "white", textDecoration: "underline", margin: "0 0.5rem"}} href="#">En savoir plus</a>*/}
                </p>
            ) : ""}
            {//(cellule.statut_actuel?.statut.duree_engagement && cellule.statut_actuel?.statut.duree_engagement > 0)&&
                new DateTZ(cellule.statut_actuel?.engage_jusqua).getTime() > new DateTZ().getTime() ? (
                    <p className={"notice grey"} style={{marginTop: "0.5rem"}}>

                        L'abonnement actuellement appliqué ({cellule.statut_actuel?.statut?.nom})
                        est engagé jusqu'au {afficherDate(cellule.statut_actuel?.engage_jusqua)}
                        {/*de {cellule.statut_actuel?.statut.duree_engagement} jours*/}
                        , vous ne pouvez
                        désactiver ou changer l'abonnement de votre cellule avant cette date.
                        {/*<a style={{color: "white", textDecoration: "underline", margin: "0 0.5rem"}} href="#">En savoir plus</a>*/}
                    </p>
                ) : ""}
        </div>
    }


    const combobox_capteur = useCombobox({
        onDropdownClose: () => combobox_capteur.resetSelectedOption(),
    });
    const selectedCapteur = data_select_capteur?.find((item) => item.value === value_capteur);

    const combobox_extension = useCombobox({
        onDropdownClose: () => combobox_extension.resetSelectedOption(),
    });
    const selectedExtension = data_select_extension?.find((item) => item.value === value_extension);

    const combobox_sonde = useCombobox({
        onDropdownClose: () => combobox_sonde.resetSelectedOption(),
    });
    const selectedSonde = data_select_sonde?.find((item) => item.value === value_sonde);

    useEffect(() => {
        if (!opened && active > getID_step("capteur")) {
            setActive(etape ?? 0);

            setStatutChoisi(null)

            set_nom(cellule.nom === "Nouvelle cellule" ? "" : cellule.nom);
            setConfigRapide(cellule.config_rapide);

            let cr_min_index = cellule.conditions.filter(c => c.slug === 0)[0];
            let cr_max_index = cellule.conditions.filter(c => c.slug === 1)[0];
            let cr_etat_index_normal = cellule.conditions.filter(c => c.slug === 2)[0];
            let cr_etat_index_anormal = cellule.conditions.filter(c => c.slug === 3)[0];

            let cr_delais_horaire = cr_min_index ? cr_min_index : cr_max_index; //Si un seul des deux est actif

            setCRMIN(cr_min_index ? parseInt(cr_min_index.valeur_a_comparer) : 0);
            setCRMAX(cr_max_index ? parseInt(cr_max_index.valeur_a_comparer) : 0);
            setCRMIN_Etat(cr_min_index ? true : false);
            setCRMAX_Etat(cr_max_index ? true : false);
            setCR_Etat_anormal(cr_etat_index_normal == null || cr_etat_index_anormal != null);
            setCR_Etat_anormal_active(cr_etat_index_normal != null || cr_etat_index_anormal != null);
            setCR_Duree(cr_delais_horaire ? cr_delais_horaire.delais : 60);
            setCR_Horaires(cr_delais_horaire ? cr_delais_horaire.horaires_maintenance : []);
        }


    }, [cellule])

    const [statut_precedent, setStatut_precedent] = useState<Statut_cellule | null>(null);

    useEffect(() => {
        if (opened && extensions_toutes === null) { //
            ajax.get('/cellules/' + cellule.id + "/extensions")
                .then(response => {
                    let sondes: Sonde[] = response.data.sondes;
                    setSondes_toutes(sondes);

                    let extensions: Extension[] = response.data.extensions;
                    setExtensions_toutes(extensions);

                    let liste_capteur: Capteur[] = [];


                    extensions.filter(e => { //Filtrer les types de sonde pour ne pas mélanger dans la cellule
                        if (!cellule.sonde_type) return true;
                        // alert(cellule.sonde_type?.categorie_de_sonde_id + " === " + e.extension_dispo.categorie_de_sonde.id + "("+e.nom+")")
                        return cellule.sonde_type?.categorie_de_sonde_id === e.extension_dispo.categorie_de_sonde.id;
                        // return cellule.sonde_type?.slug_type === e.extension_dispo;
                    }).map(e => {
                        if (liste_capteur.filter(lc => lc.uuid === e.capteur.uuid).length === 0) liste_capteur.push(e.capteur)
                    });
                    setData_select_capteur(liste_capteur.map(capteur => {
                        return {
                            image: '/logo192.png',
                            label: "Enregistreur " + capteur.nom,
                            value: "" + capteur.uuid,
                            group: capteur.produit.nom,
                            description: "Réf " + capteur.uuid
                        }
                    }))


                    setData_select_extension(
                        extensions
                            .filter(e => { //Filtrer les types de sonde pour ne pas mélanger dans la cellule
                                if (!cellule.sonde_type) return true;
                                // alert(cellule.sonde_type?.categorie_de_sonde_id + " === " + e.extension_dispo.categorie_de_sonde.id + "("+e.nom+")")
                                return cellule.sonde_type?.categorie_de_sonde_id === e.extension_dispo.categorie_de_sonde.id;
                            }).map(extension => {
                            return {
                                extension: extension,
                                image: '/logo192.png',
                                label: extension.nom,
                                value: "" + extension.id,
                                description: extension.extension_dispo.nom,
                                group: "Enregistreur \"" + extension.capteur.nom + "\" (réf " + extension.capteur.uuid + ")",
                            }
                        }))


                    // Préremplir les selects
                    extensions.map(e => {
                        if (e.id === cellule.extension?.id) {
                            setValue_extension("" + e.id)
                            setValue_capteur(e.capteur.uuid + "")
                            if (e.sonde && sondes && cellule.sonde_type) {
                                setValue_sonde(e.sonde.id + "")
                                setSondesPourSelectedExtension(sondes, e);
                            }

                        }
                    })
                }).catch(error => {
                console.log(error);
            });
            getStatutsDispoAvecExtensionChoisie(true);
        }

    }, [opened])

    useEffect(() => {
        // Recup auto de l'organisation pour les cas où le client à donné un moyen de paiement lors de la config
        // précédente mais que l'orga a pas encore été actualisé

        let cette_organisation = organisations_state.list.find(o => o.id === cellule.zone.organisation_id);

        if (!cette_organisation || !opened) return;

        if (!checkSiOrgaAMoyenDePaiement(cellule.organisation.id)) {
            ajax.get('/organisations/' + cette_organisation.id).then(res => {
                let orga_maj:Organisation = res.data.organisation
                dispatch(majSimpleOrganisation(orga_maj))

            }).catch(err => {})
        }

    }, [opened]);

    function getStatutsDispoAvecExtensionChoisie(utiliser_capteur_de_base?: boolean) {
        set_statuts(null)
        set_offres_abonnements(null)
        setStatutChoisi(null)
        let capteur_uuid = getSelectedCapteur()?.uuid;
        if (utiliser_capteur_de_base) capteur_uuid = cellule.extension?.capteur.uuid;
        if (!capteur_uuid) {
            set_statuts([])
            set_offres_abonnements([])
            return;
        }
        ajax.get('/cellule/' + cellule.id + '/statutscellule/' + capteur_uuid)
            .then(response => {

                setStatut_precedent(response.data.statut_avant_pause ?? null);

                let statuts_dispo: Statut_cellule[] = response.data.statutcellules;
                let sorted = statuts_dispo.sort((a, b) => {
                    if (Number(a.prix_achat) < Number(b.prix_achat)) return 1
                    return -1
                })
                set_statuts(sorted)
                let offres: Offre_abonnement[] = [];
                let statut_actuel_est_dispo = false;
                let statut_actuel = cellule?.statut_actuel?.statut;
                sorted.map(s => {
                    if (statut_actuel && statut_actuel.id === s.id) statut_actuel_est_dispo = true;

                    if (s.offre_abonnement && !offres.find(o => o.id === s.offre_abonnement?.id) && s.is_affichable_achat) {
                        offres.push(s.offre_abonnement);
                    }
                })
                if (statut_actuel_est_dispo && statut_actuel) setStatutChoisi(statut_actuel)
                set_offres_abonnements(offres)
            }).catch(error => {
            console.log(error);
        });
    }

    const dispatch = useDispatch();

    useEffect(() => {
        if (opened) {
            dispatch(setPasApasEtat("modale_config_cellule_opened"))
        }
    }, [opened]);

    const [modal_promo_openned, setModal_promo_openned] = useState(false);
    const modal_promo_ne_plus_afficher = useSelector<RootState, boolean>(state => state.interface.promo_ordre_cellule_modal_ne_plus_afficher)

    const [infos_capteur, setInfos_capteur] = useState<Capteur | null>(null);


    const [affichage_faux_input, setAffichage_faux_input] = useState(cellule.affichage_faux)
    const [affichage_vrai_input, setAffichage_vrai_input] = useState(cellule.affichage_vrai)

    useEffect(() => {
        if (opened && infos_capteur === null && cellule.extension?.capteur.uuid) {
            ajax.get("/capteurs/" + cellule.extension.capteur.uuid).then(response => setInfos_capteur(response.data.capteur)).catch(e => {
            })
        }
    }, [opened]);

    function handleEnregistrer(validerEtConfigAlertes?: boolean) {
        set_loading_btn(true);
        dispatch(setPasApasEtat("modale_config_cellule_enregistré"))
        let put_data = {
            "config_rapide": configRapide,
            "extension_id": Number(value_extension),
            "sonde_id": Number(value_sonde),
            "nom": nom,
            "affichage_vrai": affichage_vrai_input,
            "affichage_faux": affichage_faux_input,
            "statut_id": (statutChoisi?.id !== cellule.statut_actuel?.statut.id) ? statutChoisi?.id : null,
        };
        ajax.put('/cellules/' + cellule.id + '/statut', put_data)
            .then(response => {

                if (configRapide) {
                    let conditions: condition_unit[] = [];
                    //let extension = extensions_toutes?.filter(ext => ext.id + "" === value_extension)[0];
                    const cr_min_index = cellule.conditions.filter(c => c.slug === 0)[0];
                    const cr_max_index = cellule.conditions.filter(c => c.slug === 1)[0];
                    let cr_bool_index = cellule.conditions.filter(c => c.slug === 2)[0];
                    if (!cr_bool_index) cr_bool_index = cellule.conditions.filter(c => c.slug === 3)[0];

                    let sondeChoisi = sondes_toutes?.find(s => s.id + "" === value_sonde)


                    if (sondeChoisi?.type_de_donnee === "bool") {
                        let affichage = cr_etat_anormal ? affichage_vrai_input : affichage_faux_input;
                        if (cr_etat_anormal_active) conditions.push(
                            {
                                "index": cr_bool_index ? cr_bool_index.id : -1, //On retrouve l'index pour garder les alertes
                                "nom": affichage,
                                "texte_a_afficher": cr_etat_anormal_texte,
                                "type": cr_etat_anormal ? "3" : "2",
                                "valeur": cr_min,
                                "duree": cr_duree,
                                "horaire": cr_horaires,
                                repetition: false,
                                "alertes": [] //Est là pour le front, le back ignore les alertes ici
                            }
                        )
                    }
                    if (sondeChoisi?.type_de_donnee === "number") {
                        if (cr_min_etat) conditions.push(
                            {
                                "index": cr_min_index ? cr_min_index.id : -1, //On retrouve l'index pour garder les alertes
                                "nom": "MIN",
                                "texte_a_afficher": "La cellule " + nom + " est en dessous de " + sondeChoisi?.affichage_prefix + cr_min + sondeChoisi?.affichage_suffix + " depuis " + cr_duree + " minutes",
                                "type": "0",
                                "valeur": cr_min,
                                "duree": cr_duree,
                                "horaire": cr_horaires,
                                repetition: false,
                                "alertes": [] //Est là pour le front, le back ignore les alertes ici
                            }
                        )
                        if (cr_max_etat) conditions.push(
                            {
                                "index": cr_max_index ? cr_max_index.id : -2,
                                "nom": "MAX",
                                "texte_a_afficher": "La cellule " + nom + " est au dessus de " + sondeChoisi?.affichage_prefix + cr_max + sondeChoisi?.affichage_suffix + " depuis " + cr_duree + " minutes",
                                "type": "1",
                                "valeur": cr_max,
                                "duree": cr_duree,
                                "horaire": cr_horaires,
                                repetition: false,
                                "alertes": [] //Est là pour le front, le back ignore les alertes ici
                            }
                        )
                    }


                    ajax.put('/cellules/' + cellule.id + '/conditions', {
                        conditions: conditions,
                        inverserHoraire: horaireInverser
                    })
                        .then(response_cond => {
                            set_loading_btn(false);

                            //console.log(response_cond.data.cellule)
                            setCellule(response_cond.data.cellule);

                            notifications.show({
                                withCloseButton: true,
                                autoClose: env_config.notifications_duree_affichage,
                                color: 'green',
                                title: "Validé!",
                                message: 'La cellule est configurée.',
                                icon: <IconeCheck/>,
                            });

                            close();
                            setExtensions_toutes(null)
                            if (validerEtConfigAlertes && ouvrirModaleAlertes) ouvrirModaleAlertes();

                        }).catch(error => {
                        set_loading_btn(false);
                        console.log(error);
                    });
                } else { // Config rapide désactivée

                    setCellule(response.data.cellule)

                    set_loading_btn(false);

                    notifications.show({
                        withCloseButton: true,
                        autoClose: env_config.notifications_duree_affichage,
                        color: 'green',
                        title: "Validé!",
                        message: 'La cellule est configurée.',
                        icon: <IconeCheck/>,
                    });

                    close();
                    setExtensions_toutes(null)
                }


            }).catch(error => {
            set_loading_btn(false);
            console.log(error);
        });
    }

    useEffect(() => {


        if (value_extension != null && value_sonde == null && extensions_toutes != null && sondes_toutes != null) {


            setValue_sonde(extension_url_param ? extensions_toutes.find(e => e.id + "" === extension_url_param)?.sonde?.id + "" : null)

            let ext = extensions_toutes.find(e => e.id === selectedExtension?.extension?.id)

            if (ext) setSondesPourSelectedExtension(sondes_toutes, ext)
        }


    }, [extensions_toutes, sondes_toutes]);

    function setSondesPourSelectedExtension(sondes: Sonde[], extension: Extension) {

        setData_select_sonde(
            sondes.filter(sonde => sonde.categorie_de_sonde_id === extension.extension_dispo.categorie_de_sonde.id).map(sonde => {
                return {
                    sonde: sonde,
                    image: '/logo192.png',
                    label: sonde.nom,
                    value: "" + sonde.id,
                    description: '',
                    group: sonde.affichage_nom_mesure,
                }
            }))
    }

    function handleDesactiverCellule() {
        set_loading_btn(true);
        ajax.post('/cellules/' + cellule.id + '/desactiver', {})
            .then(response => {
                setCellule(response.data.cellule)
                set_loading_btn(false);
                close();
                handlers_modale_desactiver_cellule.close()
                setExtensions_toutes(null)
                setValue_sonde(null);
                setValue_capteur(null);
                setValue_extension(null);
                notifications.show({
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'orange',
                    // title: "Cellule désactivée",
                    message: 'Cellule désactivée.',
                    icon: <IconeCheck/>,
                });
            }).catch(error => {
            console.log(error);
            set_loading_btn(false);
        });
    }

    function handleMettreEnPauseCellule() {
        set_loading_btn(true);
        ajax.post('/cellules/' + cellule.id + '/pause', {})
            .then(response => {
                setCellule(response.data.cellule)
                set_loading_btn(false);
                close();
                handlers_modale_desactiver_cellule.close()
                setExtensions_toutes(null)
                setValue_sonde(null);
                setValue_capteur(null);
                setValue_extension(null);
                notifications.show({
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'orange',
                    // title: "Cellule désactivée",
                    message: 'Cellule mise en pause.',
                    icon: <IconeCheck/>,
                });
            }).catch(error => {
            console.log(error);
            set_loading_btn(false);
        });
    }

    function handleReactiverCellule() {

        if (!statut_precedent) alert("Le statut que vous aviez avant la pause n'est plus disponible.")
        else {
            setStatutChoisi(statut_precedent)
            if (checkSiOrgaAMoyenDePaiement(cellule.organisation.id)) setActive(getID_step("resume_et_confirmation"))
            else setActive(getID_step("enregistrement_CB"))
        }

        // set_loading_btn(true);
        // ajax.post('/cellules/' + cellule.id + '/reactiver', {})
        //     .then(response => {
        //         setCellule(response.data.cellule)
        //         set_loading_btn(false);
        //         close();
        //         handlers_modale_desactiver_cellule.close()
        //         setExtensions_toutes(null)
        //         setValue_sonde(null);
        //         setValue_capteur(null);
        //         setValue_extension(null);
        //         notifications.show({
        //             withCloseButton: true,
        //             autoClose: env_config.notifications_duree_affichage,
        //             color: 'green',
        //             // title: "Cellule désactivée",
        //             message: 'Cellule réactivée',
        //             icon: <IconeCheck/>,
        //         });
        //     }).catch(error => {
        //     console.log(error);
        //     set_loading_btn(false);
        // });
    }

    function calcDateFinEngagement() {
        if (!statutChoisi) return " /erreur/ ";
        let date = new DateTZ();
        date.setMonth(date.getMonth() + statutChoisi?.duree_engagement);
        return afficherDate(date.toISOString())
    }


    function getSelectedCapteur(): Capteur | null {
        // alert(selectedCapteur.value)
        return data_select_extension?.find(e => e.extension?.capteur?.uuid === selectedCapteur?.value)?.extension?.capteur ?? null
    }

    function getPromosDeCetteOrga(regle: string) {
        let cette_orga = organisations_state.list.find(o => o.id === cellule.zone.organisation_id);
        if (!cette_orga) {
            alert("Erreur : Organisation introuvable. Impossible de calculer les promotions.");
            return <p>Erreur : Organisation introuvable. Impossible de calculer les promotions.</p>;
        }
        // @ts-ignore
        return cette_orga.regles_paiements[regle] ?? organisations_state.regles_paiement_defaut[regle]
    }

    function afficher_cellules_capteur_promo(desactiver?: boolean) {
        if (!infos_capteur || !infos_capteur.extensions) return;
        if (!statutChoisi) return <p>Aucun abonnement choisi.</p>

        let cellules: Cellule[] = JSON.parse(JSON.stringify(infos_capteur.extensions.filter(e => e.cellule && e.cellule).map(e => e.cellule)));

        if (desactiver) {
            cellules = cellules.filter(c => c.id !== cellule.id)
        } else {
            cellules = cellules.map(c => {
                if (c.id === cellule.id) {
                    c.statut_actuel = {
                        cellule_id: cellule.id,
                        debut: "",
                        engage_jusqua: "",
                        est_actuel: false,
                        est_promo: false,
                        fin: "",
                        id: 0,
                        paiement_valide: false,
                        paye_jusqua: "",
                        statut: statutChoisi,
                        capteur: cellule.extension?.capteur.uuid ?? null,
                        payeLe: null,
                        payeMontant: 0,
                    }
                }

                return c;
            })
        }


        let premiere: { prix: number, cellule_id: number } = {prix: 0, cellule_id: 0}
        let deuxieme: { prix: number, cellule_id: number } = {prix: 0, cellule_id: 0}


        // Recup la 1er et 2e
        cellules.map(cell => {
            if (cell?.statut_actuel?.statut.prix_achat === undefined) return;
            if (cell?.id === undefined) return;
            let prix_actuel = Number(cell.statut_actuel.statut.prix_achat);
            if (prix_actuel > premiere.prix) {
                deuxieme = {...premiere};
                premiere = {prix: prix_actuel, cellule_id: cell.id}
            } else if (prix_actuel > deuxieme.prix) {
                deuxieme = {prix: prix_actuel, cellule_id: cell.id}
            } else {
                //Statut est 3e ou plus
            }
        })

        let cette_orga = organisations_state.list.find(o => o.id === cellule.zone.organisation_id);

        if (!cette_orga) {
            alert("Erreur : Organisation introuvable. Impossible de calculer les promotions.");
            return <p>Erreur : Organisation introuvable. Impossible de calculer les promotions.</p>;
        }

        let promo_premiere = cette_orga.regles_paiements["premiereCellule"] ?? organisations_state.regles_paiement_defaut["premiereCellule"];
        let promo_deuxieme = cette_orga.regles_paiements["deuxiemeCellule"] ?? organisations_state.regles_paiement_defaut["deuxiemeCellule"];
        let promo_autre = cette_orga.regles_paiements["autre_cellules"] ?? organisations_state.regles_paiement_defaut["autre_cellules"];

        let prix_mensuel = 0;
        cellules.map(cell => {
            if (cell?.statut_actuel?.statut.prix_achat === undefined) return;
            if (cell?.id === undefined) return;
            let prix_actuel = Number(cell.statut_actuel.statut.prix_achat);
            if (cell.id === premiere.cellule_id) {
                prix_mensuel += prix_actuel * (100 - promo_premiere) / 100
            } else if (cell.id === deuxieme.cellule_id) {
                prix_mensuel += prix_actuel * (100 - promo_deuxieme) / 100
            } else {
                prix_mensuel += prix_actuel * (100 - promo_autre) / 100
            }
        })

        let premiere_cellule = cellules.find(c => c?.id === premiere.cellule_id);
        let deuxieme_cellule = cellules.find(c => c?.id === deuxieme.cellule_id);
        let autres_cellules = cellules.filter(c => c && ![premiere.cellule_id, deuxieme.cellule_id].includes(c.id));

        return (
            <div className={"encart_detail_ordre_cellules"}>
                <p className={"en-ligne"}>
                    <div style={{cursor: "pointer"}} onClick={() => setModal_promo_openned(true)}><IconeCircleInfo/>
                    </div>
                    Promotions appliqués aux cellules de l'appareil {cellule.extension?.capteur.nom} :
                </p>

                <table>
                    {premiere_cellule && (
                        <tr className={"cellules"}>
                            <td>{premiere_cellule.nom} </td>
                            {promo_premiere === 0 ? (
                                <td>
                                    {Number(premiere_cellule.statut_actuel?.statut.prix_achat)}€HT/mois
                                    {" "}(1ère cellule de l'appareil)
                                </td>
                            ) : (
                                <td>
                                    <i style={{
                                        textDecoration: "line-through",
                                        fontSize: "0.8rem"
                                    }}>{premiere_cellule.statut_actuel?.statut.prix_achat}€ HT</i>
                                    {" "}<span
                                    style={{color: "var(--valid-color)"}}>{Number(premiere_cellule.statut_actuel?.statut.prix_achat) * (100 - promo_premiere) / 100}€ HT/mois</span>
                                    {" "}(1ère cellule de l'appareil, promotion de {promo_premiere}%)
                                </td>
                            )}

                        </tr>
                    )}
                    {deuxieme_cellule && (
                        <tr className={"cellules"}>
                            <td>{deuxieme_cellule.nom} </td>
                            {promo_deuxieme === 0 ? (
                                <td>
                                    {Number(deuxieme_cellule.statut_actuel?.statut.prix_achat)}€ HT/mois
                                    {" "}(2ème cellule de l'appareil)
                                </td>
                            ) : (
                                <td>

                                    <i style={{
                                        textDecoration: "line-through",
                                        fontSize: "0.8rem"
                                    }}>{deuxieme_cellule.statut_actuel?.statut.prix_achat}€ HT</i>
                                    {" "}<span
                                    style={{color: "var(--valid-color)"}}>{Number(deuxieme_cellule.statut_actuel?.statut.prix_achat) * (100 - promo_deuxieme) / 100}€ HT/mois</span>
                                    {" "}(2ème cellule, promotion de {promo_deuxieme}%)
                                </td>
                            )}

                        </tr>
                    )}
                    {autres_cellules.map(cell => cell && (
                        <tr className={"cellules"}>
                            <td>{cell.nom} </td>
                            {promo_autre === 0 ? (
                                <td>
                                    {Number(cell.statut_actuel?.statut.prix_achat)}€ HT/mois
                                </td>
                            ) : (
                                <td>
                                    <i style={{
                                        textDecoration: "line-through",
                                        fontSize: "0.8rem"
                                    }}>{cell.statut_actuel?.statut.prix_achat}€ HT</i>
                                    {" "}<span
                                    style={{color: "var(--valid-color)"}}>{Number(cell.statut_actuel?.statut.prix_achat) * (100 - promo_autre) / 100}€ HT/mois</span>
                                    {" "}(Promotion de {promo_autre}%)
                                </td>
                            )}


                        </tr>
                    ))}
                </table>


                <p><br/>Nouveau prix mensuel de l'appareil : {Math.round(prix_mensuel * 100) / 100}€ HT</p>
            </div>
        )
    }

    if (!data_select_extension) {
        return (
            <>
                <Modal opened={opened} onClose={close} title="Configurer la cellule">
                    <p className={"en-ligne"}><IconeLoader/> Chargement ...</p>
                </Modal>

                <button className={"sec"} onClick={open}>{children}</button>
            </>
        );
    }

    let statuts_admins = status?.filter(s => !s.is_affichable_achat);

    let cette_organisation = organisations_state.list.find(o => o.id === cellule.zone.organisation_id);

    return (
        <>
            <Modal opened={opened} onClose={close} withCloseButton={false}
                   className={"padding_bottom_modale_quand_boutons_fixed mesure_config_modale modale-fullscreen" + (hide_modale ? " modal_hide" : "")}
                   closeOnClickOutside={!forcer && cellule.nom !== "Nouvelle cellule"}
            >
                <div className={"modal-en-tete-custom"}>
                    <p className="titre">Configurer la cellule</p>
                    {cellule.extension && active < getID_step("resume_et_confirmation") && (
                        <>
                            {cellule.statut_actuel?.statut.slug === "pause" ? (
                                <>
                                    <Loading_button is_loading={btn_loading} className={""}
                                                    onClick={handleReactiverCellule}>
                                        <IconeToggleOn/>Réactiver la cellule
                                    </Loading_button>
                                    <Loading_button is_loading={btn_loading} className={"sec rouge"}
                                                    onClick={handleDesactiverCellule}>
                                        <IconeToggleOff/>Désactiver complètement la cellule
                                    </Loading_button>
                                </>
                            ) : (
                                <Loading_button is_loading={btn_loading} className={"sec rouge"}
                                                onClick={() => handlers_modale_desactiver_cellule.toggle()}
                                >
                                    <IconeToggleOff/>Mettre en pause
                                </Loading_button>
                            )}

                        </>
                    )}

                    <Group justify="center">
                        <CloseButton aria-label="Fermer la fenetre" onClick={() => {

                            if ((cellule.nom === "Nouvelle cellule" && !cellule.extension)) {
                                ajax.delete("/cellules/" + cellule.id).then(response => {
                                }).catch(error => {
                                });
                                navigate(-1)
                            } else {
                                close();
                            }

                        }}/>
                    </Group>
                </div>

                <Stepper active={active} onStepClick={setActive} color="var(--main-light-color)" radius="md" size="xs"
                         allowNextStepsSelect={false}>
                    <Stepper.Step label="Nom">
                        <TextInput type="text"
                                   label={"Le nom de la cellule"}
                                   description={"Généralement, il vaut mieux donner le nom de ce qui est mesuré, exemple \"Congélateur à poisson\""}
                                   value={nom}
                                   onChange={event => set_nom(event.target.value)}
                                   placeholder={"Nom de la cellule"}/>
                    </Stepper.Step>


                    <Stepper.Step label="Enregistreur">
                        {data_select_extension && data_select_capteur ? (
                            <>
                                <Combobox
                                    store={combobox_capteur}
                                    key={"combo_capteur"}
                                    withinPortal={false}
                                    onOptionSubmit={(val) => {
                                        combobox_capteur.closeDropdown();
                                        setValue_capteur(val);
                                        setValue_extension(null)
                                        setValue_sonde(null)
                                        setData_select_sonde(null)

                                    }}
                                >
                                    <Combobox.Target>
                                        <InputBase
                                            label={"Quel enregistreur doit être utilisé ?"}
                                            description={"Une cellule représente un ensemble de mesures. Indiquez ici l'appareil qui doit l'alimenter en données. "}
                                            component="button"
                                            type="button"
                                            pointer
                                            rightSection={<Combobox.Chevron/>}
                                            onClick={() => combobox_capteur.toggleDropdown()}
                                            rightSectionPointerEvents="none"
                                            multiline
                                        >
                                            {selectedCapteur ? (
                                                // <SelectItem {...selectedOption} />
                                                // selectedOption.label
                                                <Group wrap={"nowrap"}>
                                                    {/*<Avatar src={image} />*/}

                                                    <div>
                                                        <p>{selectedCapteur.label}</p>
                                                        <p className={"description"}>{selectedCapteur.description}</p>
                                                    </div>
                                                </Group>
                                            ) : (
                                                <Input.Placeholder>Merci de sélectionner l'appareil que vous souhaitez
                                                    connecter</Input.Placeholder>
                                            )}
                                        </InputBase>
                                    </Combobox.Target>

                                    <Combobox.Dropdown>
                                        <Combobox.Options>
                                            {data_select_capteur.length === 0 && (
                                                <Combobox.Empty>Aucun appareil n'est disponible</Combobox.Empty>
                                            )}
                                            {Array.from(new Set(data_select_capteur.map(d => d.group))).map(group => (
                                                    <Combobox.Group label={group}>
                                                        {data_select_capteur.filter(d => d.group === group).map(item => (
                                                            <Combobox.Option value={item.value} key={item.value}>
                                                                <Group wrap={"nowrap"}>
                                                                    {/*<Avatar src={image} />*/}

                                                                    <div>
                                                                        <p>{item.label}</p>
                                                                        <p className={"description"}>{item.description}</p>
                                                                    </div>
                                                                </Group>
                                                            </Combobox.Option>
                                                        ))}
                                                    </Combobox.Group>

                                                )
                                            )}
                                        </Combobox.Options>
                                    </Combobox.Dropdown>
                                </Combobox>


                                <Combobox
                                    store={combobox_extension}
                                    key={"combo_ext"}
                                    withinPortal={false}
                                    onOptionSubmit={(val) => {
                                        combobox_extension.closeDropdown();
                                        setValue_extension(val);
                                        setValue_sonde(null)
                                        let extension_choisi = extensions_toutes?.find(e => e.id + "" === val)
                                        if (sondes_toutes && extension_choisi) {
                                            // alert(JSON.stringify(extension_choisi))
                                            setSondesPourSelectedExtension(sondes_toutes, extension_choisi)
                                        }
                                    }}
                                >
                                    <Combobox.Target>
                                        <InputBase
                                            label={"Quel port d’extension voulez-vous utiliser pour cet enregistreur ?"}
                                            // description={"Une cellule représente un ensemble de mesures. Indiquez ici le port d'extension de l'enregistreur que vous souhaitez utiliser pour alimenter cette cellule en mesure. "}
                                            component="button"
                                            type="button"
                                            pointer
                                            rightSection={<Combobox.Chevron/>}
                                            onClick={() => combobox_extension.toggleDropdown()}
                                            rightSectionPointerEvents="none"
                                            multiline
                                        >
                                            {selectedExtension ? (
                                                // <SelectItem {...selectedOption} />
                                                // selectedOption.label
                                                <Group wrap={"nowrap"}>
                                                    {/*<Avatar src={image} />*/}

                                                    <div>
                                                        <p>{selectedExtension.label}</p>
                                                        <p className={"description"}>{selectedExtension.description}</p>
                                                    </div>
                                                </Group>
                                            ) : (
                                                <Input.Placeholder>Merci de selectionner le port
                                                    d'extension</Input.Placeholder>
                                            )}
                                        </InputBase>
                                    </Combobox.Target>

                                    <Combobox.Dropdown>
                                        <Combobox.Options>
                                            {data_select_extension.length === 0 && (
                                                <Combobox.Empty>Aucune extension n'est disponible</Combobox.Empty>
                                            )}
                                            {data_select_extension.filter(d => d.extension && d.extension.capteur.uuid === value_capteur).map(item => (
                                                <Combobox.Option value={item.value} key={item.value}>
                                                    <Group wrap={"nowrap"}>
                                                        {/*<Avatar src={image} />*/}

                                                        <div>
                                                            <p>{item.label}</p>
                                                            <p className={"description"}>{item.description}</p>
                                                        </div>
                                                    </Group>
                                                </Combobox.Option>
                                            ))}
                                        </Combobox.Options>
                                    </Combobox.Dropdown>
                                </Combobox>

                                {data_select_sonde && (
                                    <Combobox
                                        store={combobox_sonde}
                                        key={"combo_sonde"}
                                        withinPortal={false}
                                        onOptionSubmit={(val) => {
                                            combobox_sonde.closeDropdown();
                                            setValue_sonde(val);
                                        }}
                                    >
                                        <Combobox.Target>
                                            <InputBase
                                                label={selectedExtension?.extension?.extension_dispo?.categorie_de_sonde.nom === "Contacts secs" ? "Quel type de contact sec avez-vous branché sur ce port ?" : "Quel type de sonde avez-vous branchée sur ce port ?"}
                                                // description={"Une cellule représente un ensemble de mesures. Indiquez ici le port d'extension de l'enregistreur que vous souhaitez utiliser pour alimenter cette cellule en mesure. "}
                                                component="button"
                                                type="button"
                                                pointer
                                                rightSection={<Combobox.Chevron/>}
                                                onClick={() => combobox_sonde.toggleDropdown()}
                                                rightSectionPointerEvents="none"
                                                multiline
                                            >
                                                {selectedSonde ? (
                                                    // <SelectItem {...selectedOption} />
                                                    // selectedOption.label
                                                    <Group wrap={"nowrap"}>
                                                        {/*<Avatar src={image} />*/}

                                                        <div>
                                                            <p>{selectedSonde.label}</p>
                                                            <p className={"description"}>{selectedSonde.description}</p>
                                                        </div>
                                                    </Group>
                                                ) : (
                                                    <Input.Placeholder>Merci d'indiquer le type de
                                                        sonde</Input.Placeholder>
                                                )}
                                            </InputBase>
                                        </Combobox.Target>

                                        <Combobox.Dropdown>
                                            <Combobox.Options>
                                                {data_select_sonde.length === 0 && (
                                                    <Combobox.Empty>Aucune sonde n'est disponible pour ce port
                                                        d'extension</Combobox.Empty>
                                                )}
                                                {Array.from(new Set(data_select_sonde.map(d => d.group))).map(group => (
                                                        <Combobox.Group label={group}>
                                                            {data_select_sonde.filter(d => d.group === group).map(item => (
                                                                <Combobox.Option value={item.value} key={item.value}>
                                                                    <Group wrap={"nowrap"}>
                                                                        {/*<Avatar src={image} />*/}

                                                                        <div>
                                                                            <p>{item.label}</p>
                                                                            <p className={"description"}>{item.description}</p>
                                                                        </div>
                                                                    </Group>
                                                                </Combobox.Option>
                                                            ))}
                                                        </Combobox.Group>

                                                    )
                                                )}
                                            </Combobox.Options>
                                        </Combobox.Dropdown>
                                    </Combobox>
                                )}


                                {selectedSonde && selectedSonde.sonde && selectedSonde.sonde.type_de_donnee === "bool" && (
                                    <>
                                        {/*<div style={{fontSize: "var(--texte-size-petit)"}}>*/}
                                        {/*    <div className={"en-ligne"}>*/}
                                        {/*        Pour la sonde*/}
                                        {/*        <p className={"inline-tag"}>{selectedSonde.sonde.nom}</p>*/}
                                        {/*        l'affichage sera :*/}
                                        {/*    </div>*/}
                                        {/*    <p><i>"{affichage_vrai_input}"</i> quand le circuit électrique du*/}
                                        {/*        contact sec est fermé (les fils se touchent)</p>*/}
                                        {/*    <p><i>"{affichage_faux_input}"</i> quand le circuit électrique du*/}
                                        {/*        contact sec est ouvert (les fils ne se touchent pas)</p>*/}
                                        {/*</div>*/}
                                        <div>
                                            <TextInput
                                                label={"Quel affichage quand le circuit electrique du contact sec est fermé ? (les fils se touchent)"}
                                                value={affichage_vrai_input}
                                                onChange={(val) => {setAffichage_vrai_input(val.target.value)}}
                                            />
                                            <TextInput
                                                label={"Quel affichage quand le circuit electrique du contact sec est ouvert ? (les fils ne se touchent pas)"}
                                                value={affichage_faux_input}
                                                onChange={(val) => {setAffichage_faux_input(val.target.value)}}
                                            />
                                        </div>
                                    </>

                                )}

                            </>
                        ) : (
                            <div className={"en-ligne"}><IconeLoader/> Chargement...</div>
                        )}


                        {cellule.sonde_type && (
                            <span className={"inline-tag"} style={{marginTop: "1rem", display: "block"}}>
                                La cellule est bloqué sur les extensions de type <i
                                style={{fontWeight: "bold"}}>{cellule.sonde_type.affichage_nom_mesure}</i> car elle contient déjà des données de ce type.
                                <br/>
                                Si vous voulez enregistrer un autre type de données, vous pouvez désactiver cette cellule et en créer une nouvelle.
                            </span>
                        )}

                    </Stepper.Step>


                    <Stepper.Step label="Abonnement">
                        <h2>Choix de l'abonnement de la
                            cellule {permissionIsGranted(cellule.organisation.id, "ADMIN") && "(" + getSelectedCapteur()?.categorie_abonnement + ")"}</h2>
                        <p style={{fontSize: "var(--texte-size-petit)", fontStyle: "italic"}}>
                            {/*<IconeCircleInfo/>*/}
                            La durée de stockage des mesures ainsi que certaines fonctionnalités dépendent de
                            l'abonnement que vous choisissez. Des promotions pourront être appliqués :
                            <a style={{textDecoration: "underline", margin: "0 0.5rem"}}
                               onClick={() => setModal_promo_openned(true)} href="Users/Mesures/Modales#">En savoir
                                plus</a>
                        </p>
                        {!status && (
                            <Loading_div/>
                        )}
                        {offres_abonnements && offres_abonnements.length === 0 && (
                            <p className={"notice red"}>Aucun abonnement n'est disponible pour cette cellule, et vous ne
                                pouvez donc pas continuer sa configuration.
                                Contactez nous si vous pensez qu'il s'agit d'un soucis technique.
                                Cela peut être due à une mauvaise configuration de votre appareil sur nos système.</p>
                        )}
                        <div className={"tableauContainer"}>
                            {status && offres_abonnements && [...offres_abonnements].reverse().map(o => {
                                if (status.filter(s => s.offre_abonnement_id === o.id && s.is_affichable_achat).length === 0) return <></>
                                return (
                                    <div>
                                        <h2>{o.nom}</h2>
                                        <p>{o.informations}</p>
                                        <table className={"table_statuts"}>
                                            <tbody
                                                className={"hide_on_mobile"}
                                            >

                                            {status.filter(s => s.offre_abonnement_id === o.id && s.is_affichable_achat).map(statut => {
                                                return (
                                                    <tr key={statut.id}>
                                                        <td style={{width: "100%", textAlign: "left"}}>{statut.nom}</td>
                                                        <td>{statut.prix_achat}€ HT/mois</td>
                                                        <td>
                                                            <button
                                                                className={statutChoisi?.id === statut.id ? "vert" : "sec"}
                                                                onClick={() => {
                                                                    setStatutChoisi(statut)
                                                                }}
                                                                disabled={statut.unavailable_because_engagement}
                                                            >
                                                                {statutChoisi?.id === statut.id && <IconeCheck/>}
                                                                {cellule.statut_actuel?.statut?.id ? (
                                                                    cellule.statut_actuel.statut.id === statut.id ? "Conserver" : "Changer"
                                                                ) : "Choisir"}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}</tbody>
                                            <tbody
                                                className={"only_mobile_contents"}>{status.filter(s => s.offre_abonnement_id === o.id && s.is_affichable_achat).map(statut => {
                                                return (
                                                    <tr key={statut.id}>
                                                        <td className={"en-ligne spacebetween noMargin"} style={{
                                                            justifyContent: "space-between",
                                                            width: "calc(100% - 0.25rem)"
                                                        }}>
                                                            <span>{statut.nom}</span>
                                                            <span>{statut.prix_achat}€ HT/mois</span>
                                                        </td>
                                                        <td>
                                                            <button
                                                                className={statutChoisi?.id === statut.id ? "vert" : "sec"}
                                                                onClick={() => {
                                                                    setStatutChoisi(statut)
                                                                }}
                                                                disabled={statut.unavailable_because_engagement}
                                                            >
                                                                {statutChoisi?.id === statut.id && <IconeCheck/>}
                                                                {cellule.statut_actuel?.statut?.id ? (
                                                                    cellule.statut_actuel.statut.id === statut.id ? "Conserver" : "Changer"
                                                                ) : "Choisir"}

                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}</tbody>
                                        </table>

                                    </div>
                                )
                            })}


                        </div>


                        {statuts_admins && statuts_admins.length > 0 && (
                            <Accordion>
                                <Accordion.Item value={"Statut Admin"}>
                                    <Accordion.Control>Abonnements uniquement applicables en Admin</Accordion.Control>
                                    <Accordion.Panel>

                                        <TextInput
                                            placeholder={"Recherche d'un abonnement via nom..."}
                                            value={recherche_abonnement_admin}
                                            onChange={e => setRecherche_abonnement_admin(e.target.value)}
                                        />

                                        <div className={"tableauContainer"}>
                                            <table className={"table_statuts"}>
                                                <thead>
                                                <tr>
                                                    <th className={"no-wrap"}>Nom</th>
                                                    <th className={"no-wrap"}>Catégorie</th>
                                                    <th className={"no-wrap"}>Offre</th>
                                                    <th className={"no-wrap"}>Duree archivage</th>
                                                    <th className={"no-wrap"}>Prix</th>
                                                    <th className={"no-wrap flex_align_center"}>Choisir</th>
                                                </tr>
                                                </thead>
                                                <tbody>{statuts_admins.filter(s => texte_est_inclus(s.nom, recherche_abonnement_admin)).map(statut => {
                                                    return (
                                                        <tr key={statut.id}>
                                                            <td>{statut.nom}</td>
                                                            <td>{statut.categorie_abonnement}</td>
                                                            <td>{statut.offre_abonnement?.nom}</td>
                                                            <td>{statut.duree_archivage}jrs</td>
                                                            <td>{statut.prix_achat}€ HT/mois</td>
                                                            <td>
                                                                <button
                                                                    className={statutChoisi?.id === statut.id ? "vert" : "sec"}
                                                                    onClick={() => {
                                                                        setStatutChoisi(statut)
                                                                    }}>
                                                                    {statutChoisi?.id === statut.id &&
                                                                        <IconeCheck/>}
                                                                    {cellule.statut_actuel?.statut?.id ? (
                                                                        cellule.statut_actuel.statut.id === statut.id ? "Conserver" : "Changer"
                                                                    ) : "Choisir"}
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}</tbody>
                                            </table>

                                        </div>
                                    </Accordion.Panel>
                                </Accordion.Item>
                            </Accordion>
                        )}

                        {display_notice_abonnement("0")}


                    </Stepper.Step>


                    {!checkSiOrgaAMoyenDePaiement(cellule.organisation.id) && (

                        <Stepper.Step icon={<IconeCreditCard/>}>

                            {cette_organisation ? (
                                <>
                                    {!forcer_valide_paiement ? (
                                        <Modale_enregistrer_cb organisation={cette_organisation}
                                                               refresh={(resultat) => {
                                                                   setForcer_valide_paiement(resultat ?? false);
                                                               }} vue_config_cellule={true}/>
                                    ) : (
                                        <div className={"wrapper_enregistrer_cb"}>
                                            <p className={"en-ligne"}><IconeCheck/> Votre moyen de paiement est en cours
                                                de vérification. </p>
                                            <p>Merci de votre confiance, vous pouvez désormais continuer.</p>
                                        </div>

                                    )}

                                </>
                            ) : (
                                <p>Une erreur est survenue : aucune organisation. Merci de ré-essayer.</p>
                            )}


                        </Stepper.Step>

                    )}

                    {statutChoisi?.can_use_alertes && (

                        <Stepper.Step label="Anomalies" hidden={cellule.config_rapide}>

                            <Configurer_conditions_rapides cellule_nom={nom} cellule={cellule} cr_min_etat={cr_min_etat}
                                                           setCRMIN_Etat={setCRMIN_Etat}
                                                           cr_min={cr_min} setCRMIN={setCRMIN} cr_max_etat={cr_max_etat}
                                                           setCRMAX_Etat={setCRMAX_Etat} cr_max={cr_max}
                                                           setCRMAX={setCRMAX}
                                                           cr_etat_anormal_active={cr_etat_anormal_active}
                                                           setCR_Etat_anormal_active={setCR_Etat_anormal_active}
                                                           cr_etat_anormal={cr_etat_anormal}
                                                           setCR_Etat_anormal={setCR_Etat_anormal}
                                                           cr_duree={cr_duree} setCR_Duree={setCR_Duree}
                                                           cr_horaires={cr_horaires}
                                                           setCR_Horaires={setCR_Horaires}
                                                           set_hide_modale={set_hide_modale}
                                                           configRapide={configRapide}
                                                           sonde={sondes_toutes?.find(s => s.id + "" === value_sonde)}
                                                           setCR_Etat_anormal_texte={setCr_etat_anormal_texte}
                                                           cr_etat_anormal_texte={cr_etat_anormal_texte}
                                                           horaireInverser={horaireInverser} set_horaireInverser={set_horaireInverser}
                                                           affichage_faux={affichage_faux_input} affichage_vrai={affichage_vrai_input}
                            />

                            <Switch
                                label={"Passer en configuration avancé"}
                                description={"Le mode configuration avancé permet d'avoir des options supplémentaires pour les anomalies de cette cellule."}
                                checked={!configRapide}
                                onChange={e => setConfigRapide(!e.target.checked)}
                                style={{marginTop: "1rem"}}
                            />

                        </Stepper.Step>

                    )}


                    <Stepper.Completed>
                        <div style={{display: "flex", flexFlow: "column nowrap", gap: "1rem"}}>
                            <p>Configuration complète. Voici un récapitulatif : </p>
                            <p>Nom : {nom}</p>
                            <p className={"en-ligne"}>Appareil :
                                {extensions_toutes?.filter(e => e.id + "" === value_extension)[0] && (
                                    <>
                                        <i>
                                            {extensions_toutes?.find(e => e.id + "" === value_extension)?.capteur.nom} ({extensions_toutes?.filter(e => e.id + "" === value_extension)[0].capteur.uuid})
                                        </i>
                                        <IconeArrowRight/>
                                        <i>
                                            {extensions_toutes?.find(e => e.id + "" === value_extension)?.nom}
                                        </i>
                                        <IconeArrowRight/>
                                        <i>
                                            {sondes_toutes?.find(s => s.id + "" === value_sonde)?.nom}
                                        </i>
                                    </>
                                )}

                            </p>
                            <p>
                                {statutChoisi?.id === cellule.statut_actuel?.statut.id ? "Abonnement conservé : " : "Nouvel abonnement : "}{statutChoisi?.nom}
                                {(statutChoisi?.duree_engagement && statutChoisi?.duree_engagement > 0) && statutChoisi.id !== cellule.statut_actuel?.statut.id ? (
                                    " (Engagement jusqu'au " + calcDateFinEngagement() + ")"
                                ) : ""}
                            </p>


                            {afficher_cellules_capteur_promo()}


                            {statutChoisi?.id !== cellule.statut_actuel?.statut.id && (
                                <Switch
                                    label={"Je valide les informations et confirme que je comprends que mon solde mensuel va " +
                                        "augmenter suite à l'application de ce nouvel abonnement"}
                                    description={
                                        <p>
                                            L'ajout de cet abonnement à votre organisation modifiera le montant mensuel
                                            prélevé
                                            sur la réserve (balance) de votre organisation.
                                            {infos_capteur?.extensions?.filter(e => e.cellule) && infos_capteur?.extensions?.filter(e => e.cellule).length > 1 && (
                                                <span style={{color: "var(--error-color", fontWeight: "bold"}}>{" "}
                                                    ATTENTION : Plusieurs cellules sont activées sur cet enregistreur.
                                                    Je confirme et comprends que, en cas de modification de l'ordre des cellules,
                                                    l'application des promotions pourrait être affectée, comme précisé ci-dessus.
                                            </span>
                                            )}

                                        </p>
                                    }
                                    checked={clientValide}
                                    onChange={e => setClientValide(e.target.checked)}
                                />
                            )}


                        </div>
                    </Stepper.Completed>
                </Stepper>


                <div className={"en-ligne spacebetween boutons_bas_modale"}>
                    {active === getID_step("statut") && (
                        <>
                            {display_notice_abonnement("0.5rem")}
                        </>
                    )}

                    {active === 0 ? <p></p> : <button className={"sec noMargin big"} onClick={prevStep}>Retour</button>}

                    {active === getID_step("enregistrement_CB") && !checkSiOrgaAMoyenDePaiement(cellule.organisation.id) && (
                        <>
                            <p className={"inline-tag"}>Pour pouvoir continuer, merci de renseigner un moyen de
                                paiement.</p>
                        </>
                    )}

                    {(active >= getID_step("resume_et_confirmation")) ? (
                        <div className={"en-ligne"}>
                            <Loading_button is_loading={btn_loading} id={"btn_valider"}
                                            className={"noMargin big" + (checkNextStep() ? " gris" : "") + (ouvrirModaleAlertes && configRapide && statutChoisi?.can_use_alertes ? " sec" : "")}
                                            onClick={nextStep}>{<>Valider <IconeFloppyDisk/></>}</Loading_button>
                            {ouvrirModaleAlertes && configRapide && statutChoisi?.can_use_alertes ? (
                                <Loading_button is_loading={btn_loading}
                                                className={"noMargin big" + (checkNextStep() ? " gris" : "")}
                                                onClick={() => nextStep(true)}>{<>Valider et configurer les
                                    alertes <IconeBell/></>}</Loading_button>
                            ) : <></>}

                        </div>
                    ) : (
                        <Loading_button is_loading={btn_loading}
                                        className={"noMargin big" + (checkNextStep() ? " gris" : "")} id={"btn_suivant"}
                                        onClick={nextStep}>{<>Suivant <IconeArrowRight/></>}</Loading_button>
                    )}


                </div>


            </Modal>

            <Modal opened={modale_desactiver_cellule_opened} onClose={() => {
                handlers_modale_desactiver_cellule.close()
            }} title={"Mettre en pause la cellule"}>

                {cellule.statut_actuel ? (
                    <>
                        {(cellule.statut_actuel?.statut.duree_engagement && cellule.statut_actuel?.statut.duree_engagement > 0)
                        && new DateTZ(cellule.statut_actuel?.engage_jusqua).getTime() > new DateTZ().getTime()
                            ? (
                                <div style={{minWidth: "60vw"}}>
                                    <p className={"notice grey"} style={{marginTop: "0.5rem"}}>

                                        L'abonnement actuellement appliqué ({cellule.statut_actuel?.statut?.nom})
                                        comporte un engagement
                                        {/*de {cellule.statut_actuel?.statut.duree_engagement} jours*/}
                                        , ce qui
                                        veux dire que vous ne pourrez
                                        désactiver ou changer l'abonnement de votre cellule pour un statut inférieur
                                        avant
                                        le {afficherDate(cellule.statut_actuel?.engage_jusqua)}.
                                        {/*<a style={{color: "white", textDecoration: "underline", margin: "0 0.5rem"}} href="#">En savoir plus</a>*/}
                                    </p>
                                </div>

                            ) : ""}

                        {new DateTZ(cellule.statut_actuel?.engage_jusqua).getTime() <= new DateTZ().getTime() || permissionIsGranted(undefined, "ADMIN")
                            ? (
                                <div style={{minWidth: "60vw"}} className={"flex-col"}>
                                    <Switch
                                        label={"Je comprends que mon solde mensuel peut augmenter suite à une mise en pause ou désactivation de ma cellule"}
                                        description={
                                            <p>
                                                Mettre en pause ou désactiver cet abonnement peut changer la somme
                                                mensuelle prélevée dans la réserve (balance) de votre organisation.
                                                {infos_capteur?.extensions?.filter(e => e.cellule) && infos_capteur?.extensions?.filter(e => e.cellule).length > 1 && (
                                                    <span style={{color: "var(--error-color", fontWeight: "bold"}}>{" "}
                                                        ATTENTION : Plusieurs cellules sont activées sur cet enregistreur.
                                                En cas de modification de l'ordre des cellules, l'application des promotions pourrait être affectée.
                                            </span>
                                                )}

                                            </p>
                                        }
                                        checked={clientValideDesac}
                                        onChange={e => setClientValideDesac(e.target.checked)}
                                    />


                                    {clientValideDesac && (
                                        <>

                                            {afficher_cellules_capteur_promo(true)}

                                            <i className={"margintop"}>Conformément aux <a href={env_config.url_cg_vente} target="_blank">conditions
                                                générales de ventes</a>, la mise en pause ou désactivation sera effective à la fin de période actuelle, c'est à dire le {afficherDate(cette_organisation?.prochainPrelevement)}</i>


                                            <table style={{marginTop: "1rem"}}>
                                                <tr>
                                                    <td>
                                                        <p className={"texte-petit"}>Mettre en pause la cellule vous
                                                            permet de couper son abonnement
                                                            tout en gardant vos informations configurés (appareil,
                                                            anomalies, etc).
                                                            Cela vous permettra de la réactiver plus tard.</p>
                                                    </td>
                                                    <td style={{paddingLeft: "0.5rem"}}>
                                                        <Loading_button is_loading={btn_loading} className={"sec rouge"}
                                                                        onClick={handleMettreEnPauseCellule}
                                                                        disabled={!clientValideDesac}><IconeToggleOff/>Mettre
                                                            en
                                                            pause</Loading_button>
                                                    </td>


                                                </tr>
                                                <tr>
                                                    <td style={{paddingTop: "1rem"}}>
                                                        <p className={"texte-petit"}>Désactiver la cellule va couper
                                                            l'abonnement de la cellule et
                                                            libérer l'appareil.</p>
                                                    </td>
                                                    <td style={{paddingTop: "1rem", paddingLeft: "0.5rem"}}>
                                                        <Loading_button is_loading={btn_loading} className={"sec rouge"}
                                                                        onClick={handleDesactiverCellule}
                                                                        disabled={!clientValideDesac}><IconeToggleOff/>Désactiver
                                                            la
                                                            cellule</Loading_button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </>
                                    )}
                                </div>
                            ) : ""}
                    </>
                ) : (
                    <>
                        {cellule.extension ? (
                            <>
                                <Loading_button is_loading={btn_loading} className={"sec rouge"}
                                                onClick={handleDesactiverCellule}><IconeToggleOff/>Désactiver la
                                    cellule</Loading_button>
                            </>
                        ) : (
                            <p>Cellule déjà inactive.</p>
                        )}
                    </>
                )}


            </Modal>

            <Modal opened={modal_promo_openned} onClose={() => setModal_promo_openned(false)}
                   title={<p className={"en-ligne"}><IconeGift/> Informations promotionnelles</p>}>
                <div className={"paragraph_promo_dans_modal"}>

                    <p><i> Saviez-vous que vous pouvez tirer le meilleur parti de vos capteurs en activant plusieurs
                        cellules pour suivre différents types de mesures ?
                        Avec notre système d’abonnement flexible et avantageux, c’est facile :
                    </i></p>

                    <p>
                        <strong>Choisissez un abonnement pour chaque cellule : </strong>
                        selon vos besoins, optez pour le niveau qui vous offre
                        les fonctionnalités et la durée de stockage souhaitées.
                    </p>
                    <p>
                        <strong>Profitez de notre offre permanente multi-cellules :</strong>
                        <ul>
                            {getPromosDeCetteOrga("premiereCellule") > 0 && getPromosDeCetteOrga("premiereCellule") < 100 && (
                                <li>Une remise de -{getPromosDeCetteOrga("premiereCellule")}% appliquée dès votre 1ère
                                    cellule.
                                </li>
                            )}
                            {getPromosDeCetteOrga("premiereCellule") === 100 && (
                                <li>
                                    La 1ʳᵉ cellule de chaque appareil est offerte!
                                </li>
                            )}


                            {getPromosDeCetteOrga("deuxiemeCellule") > 0 && getPromosDeCetteOrga("deuxiemeCellule") < 100 && (
                                <li>Une remise de -{getPromosDeCetteOrga("deuxiemeCellule")}% appliquée à la 2ᵉ cellule
                                    de votre appareil.
                                </li>
                            )}
                            {getPromosDeCetteOrga("deuxiemeCellule") === 100 && (

                                <li>La 2ᵉ cellule de chaque appareil est offerte – vous ne payez rien pour l’abonnement
                                    de
                                    la cellule suivante.
                                </li>
                            )}

                            {getPromosDeCetteOrga("autre_cellules") > 0 && getPromosDeCetteOrga("autre_cellules") < 100 && (
                                <li>À partir de la 3ᵉ cellule, chaque abonnement est à
                                    -{getPromosDeCetteOrga("autre_cellules")}% du prix normal.</li>
                            )}
                            {getPromosDeCetteOrga("autre_cellules") === 100 && (

                                <li>À partir de la 3ᵉ cellule, chaque abonnement est offert – vous ne payez rien pour
                                    l’abonnement des
                                    cellules suivantes.
                                </li>
                            )}


                        </ul>
                    </p>
                    <p>
                        Pour garantir une répartition équitable, l'ordre des cellules (1ʳᵉ, 2ᵉ, etc.) est déterminé
                        automatiquement par le prix de l'abonnement choisi :
                        <ul>
                            <li>La cellule avec l'abonnement le plus cher est toujours considérée comme la 1ʳᵉ.</li>
                            <li>La 2ᵉ cellule correspond à l'abonnement actif suivant le plus
                                coûteux.
                            </li>
                            {getPromosDeCetteOrga("autre_cellules") === 0 && (

                                <li>Les cellules supplémentaires ne beneficierons pas d'une promotion.
                                </li>
                            )}
                            {getPromosDeCetteOrga("autre_cellules") > 0 && (
                                <li>Toutes les cellules supplémentaires bénéficient automatiquement de la
                                    réduction de {getPromosDeCetteOrga("autre_cellules")}%.
                                </li>
                            )}

                        </ul>
                    </p>
                </div>

                <div className={"en-ligne spacebetween"} style={{marginTop: "1rem"}}>
                    <Checkbox
                        label={"Ne plus afficher"}
                        checked={modal_promo_ne_plus_afficher}
                        onChange={e => {
                            dispatch(setNePlusAfficherModalePromoOrdreCellules(e.target.checked))
                        }
                        }
                    />
                    <button onClick={() => setModal_promo_openned(false)}>J'ai compris</button>
                </div>

            </Modal>


            <button className={"sec"} onClick={open}>{children}</button>


        </>
    );
}