import React, {forwardRef, ReactElement, RefAttributes, useEffect, useRef, useState} from 'react';
import './carte_mesure.scss'
import {Icone} from "../icone/icone";
import {Sonde} from "../../types/sonde";
import {Condition} from "../../types/condition";
import {Badge, Indicator, Tooltip} from "@mantine/core";
import {Icone_signal} from "../icone/icone_signal/Icone_signal";
import {Icone_batterie} from "../icone/icone_batterie/Icone_batterie";
import {useNavigate} from "react-router-dom";
import {Extension} from "../../types/extension";
import env_config from "../../env_config";
import {DateTZ, getCurrentDate} from "../../services/GestionDates";
import "bezier-easing/src/index"
import BezierEasing from 'bezier-easing/src/index';
import {Etalonnage} from "../../types/etalonnage";
import {IconeEyeSlash} from "../icone/solid_react/eye-slash";
import {IconeArrowDown} from "../icone/solid_react/arrow-down";
import {IconeArrowUp} from "../icone/solid_react/arrow-up";
import {IconeLinkSimple} from "../icone/solid_react/link-simple";
import {IconeLinkSimpleSlash} from "../icone/solid_react/link-simple-slash";
import {IconeTriangleExclamation} from "../icone/solid_react/triangle-exclamation";
import {IconeCircleExclamation} from "../icone/solid_react/circle-exclamation";
import {IconeSlider} from "../icone/solid_react/slider";
import {IconeArrowDownToLine} from "../icone/solid_react/arrow-down-to-line";
import {IconeArrowUpToLine} from "../icone/solid_react/arrow-up-to-line";
import {IconeBell} from "../icone/solid_react/bell";
import {IconeLinkSlash} from "../icone/solid_react/link-slash";
import {IconeSquareExclamation} from "../icone/solid_react/square-exclamation";
import {IconeTag} from "../icone/solid_react/tag";
import {IconeMap} from "../icone/solid_react/map";
import {IconeTemperatureThreeQuarters} from "../icone/solid_react/temperature-three-quarters";
import {IconePlug} from "../icone/solid_react/plug";
import {IconeBatteryThreeQuarters} from "../icone/solid_react/battery-three-quarters";
import {IconeHexagonXmark} from "../icone/solid_react/hexagon-xmark";
import {IconeBullseyePointer} from "../icone/solid_react/bullseye-pointer";
import {IconeBatteryLow} from "../icone/solid_react/battery-low";

export const Carte_mesure = forwardRef<HTMLDivElement, {
        titre: string,
        texte: string | undefined,
        sonde: Sonde | undefined | null,
        affichage_vrai?: string,
        affichage_faux?: string,
        handleClick: () => any,
        bg_color?: boolean,
        conditions?: Condition[],
        etalonnages?: Etalonnage[],
        afficherCondition?: boolean,
        afficherTypeSiPasIcone?: boolean,
        afficherEtatExtension?: boolean,
        extension?: Extension,
        restartTitreAnim?:string,
        date_mesure?:string,
        selected?:boolean,
        bouton_icone?:string,
        bouton_onclick?:() => void,
        className?:string
    }>((
    {
        titre,
        texte,
        sonde,
        handleClick,
        bg_color,
        affichage_vrai,
        affichage_faux,
        conditions,
        date_mesure,
        etalonnages,
        afficherCondition,
        afficherTypeSiPasIcone,
        afficherEtatExtension,
        extension,
        restartTitreAnim,
        selected,
        bouton_icone,
        bouton_onclick,
        className
    }, ref) => {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const titreRef = useRef<HTMLParagraphElement>(null);

    const [etalonnage_affichage, set_etalonnage_affichage] = useState<Etalonnage|null>(null)
    const [conditions_affichage, set_conditions_affichage] = useState<ReactElement[]>([])
    const [couleur, set_couleur] = useState<string>("green")
    const [texte_final, set_texte_final] = useState<ReactElement>(<>{texte}</>)
    const [icone_svg, set_icone_svg] = useState(<IconeTag />)
    const [titre_jsx, set_titre_jsx] = useState<ReactElement>(<>{titre}</>)
    const [indicateurs, set_indicateurs] = useState<ReactElement|null>(null)


    const navigate = useNavigate();


    useEffect(() => {
        const titreElement = titreRef.current;
        if (titreElement) {
            let is_overflowing = titreElement.scrollWidth > titreElement.clientWidth;
            // is_overflowing = false; //@todo supprimer cette ligne pour remettre animation
            setIsOverflowing(is_overflowing);
            if (is_overflowing) {
                const distanceToScroll = titreElement.scrollWidth - titreElement.clientWidth +5;
                const pxParFrame = 1  / 70; //pixel de déplacement par frame par milliseconde
                let reverse = true;
                let translateX = 0 -  + distanceToScroll;

                let easefn = BezierEasing(.4,0,.6,1);
                if (titre === "Humidite") {

                    // alert(distanceToScroll + " | " + startValue + " | " + endValue + " | " + translateX);
                    // console.log(distanceToScroll + " | " + startValue + " | " + endValue + " | " + translateX + " | " + progress)
                }

                let start:DOMHighResTimeStamp, previousTimeStamp:DOMHighResTimeStamp;
                const animateTitle = (timeStamp:DOMHighResTimeStamp) => {
                    if (start === undefined) {
                        start = performance.now();
                        previousTimeStamp = performance.now();
                    }

                    let temps_pause = 5;

                    let cette_frame_pxParFrame = pxParFrame * (timeStamp - previousTimeStamp);

                    if (titre === "Humidite") {
                        // alert(pxParFrame / 1000 * (timeStamp - previousTimeStamp))
                        // alert(distanceToScroll + " | " + startValue + " | " + endValue + " | " + translateX);
                        // console.log(distanceToScroll + " | " + startValue + " | " + endValue + " | " + translateX + " | " + progress)
                    }

                    if (reverse) translateX -= cette_frame_pxParFrame;
                    else translateX += cette_frame_pxParFrame;

                    const startValue = 0 -  + distanceToScroll
                    const endValue = 0
                    let progress = (translateX + startValue) / (startValue) -1
                    if (progress > 1) progress = 1
                    if (progress < 0) progress = 0


                    let ease = startValue + easefn(progress) * (endValue - startValue);

                    if (translateX > endValue) {
                        if (translateX > endValue + temps_pause) {
                            start = performance.now();
                            reverse = !reverse;
                        }
                        if (titreRef.current) titreRef.current.style.transform = `translateX(${startValue}px)`;
                    } else if (translateX < startValue) {
                        if (translateX < startValue - temps_pause) {
                            start = performance.now();
                            reverse = !reverse;
                        }
                        if (titreRef.current) titreRef.current.style.transform = `translateX(${endValue}px)`;
                    } else {
                        if (titreRef.current) titreRef.current.style.transform = `translateX(${ease}px)`;
                    }

                    previousTimeStamp = performance.now();
                    window.requestAnimationFrame(animateTitle);

                };

                window.requestAnimationFrame(animateTitle);

                // let interv = setInterval(animateTitle, 1000 / animationSpeedFPS);
                // animateTitle()
                // return () => clearInterval(interv)
            }
        }
    }, [isOverflowing]);

    useEffect(() => {
        setInterval(() => {
            const titreElement = titreRef.current;
            if (titreElement) {
                if (titreElement.scrollWidth > titreElement.clientWidth) {
                    setIsOverflowing(true)
                }
            }

        }, 1000)

    }, [restartTitreAnim])

    useEffect(() => {


        let _texte_final = texte_final;
        let _texte_final_sans_preffix = null;
        let _texte_preffix = <></>


        if (conditions) {
            let condi_tmp:ReactElement[] = []
            set_couleur("green");
            conditions.map(condition => {
                switch (condition.slug) {
                    case 0:
                        condi_tmp.push(<Tooltip label={"Valeur minimum"} key={condition.id}>
                            <div className={"item"} key={condition.id}><IconeArrowDownToLine/> {condition.valeur_a_comparer}{(sonde?.affichage_suffix ?? "")} </div>
                        </Tooltip>)
                        if (Number(texte) < Number(condition.valeur_a_comparer)) set_couleur("red")
                        if (Number(texte) < Number(condition.valeur_a_comparer)) _texte_preffix = <IconeArrowDown />
                        break;
                    case 1:
                        condi_tmp.push(<Tooltip label={"Valeur maximum"} key={condition.id}>
                            <div className={"item"} key={condition.id}><IconeArrowUpToLine/> {condition.valeur_a_comparer}{(sonde?.affichage_suffix ?? "")} </div>
                        </Tooltip>)
                        if (Number(texte) > Number(condition.valeur_a_comparer)) set_couleur("red")
                        if (Number(texte) > Number(condition.valeur_a_comparer)) _texte_preffix = <IconeArrowUp />
                        break;
                    case 2:
                        condi_tmp.push(<div className={"item"} key={condition.id}><IconeBell/> {affichage_vrai ?? "VRAI"} </div>)
                        if (texte == "0") set_couleur("red")
                        break;
                    case 3:
                        condi_tmp.push(<div className={"item"} key={condition.id}><IconeBell/> {affichage_vrai ?? "VRAI" } </div>)

                        if (texte == "1") set_couleur("red")
                        break;
                    case 8:
                        if (condition.is_hidden){
                            // condi_tmp.push(<Tooltip label={"Detection de deconnexion automatique"} key={condition.id}>
                            //         <div className={"item"} key={condition.id}><IconeLinkSlash/></div>
                            //     </Tooltip>
                            // )
                        }else{
                            condi_tmp.push(<Tooltip label={"Detection de deconnexion avancée"} key={condition.id}>
                                    <div className={"item"} key={condition.id}><IconeLinkSlash/></div>
                                </Tooltip>
                            )
                        }

                        break;
                    case 9:
                        condi_tmp.push(<Tooltip label={"Detection d'erreurs activée"} key={condition.id}>
                                <div className={"item"} key={condition.id}><IconeSquareExclamation/></div>
                            </Tooltip>
                        )
                        break;
                    case 11:
                        // condi_tmp.push(<Tooltip label={"Batterie de l'enregistreur faible"} key={condition.id}>
                        //         <div className={"item"} key={condition.id}><IconeBatteryLow/></div>
                        //     </Tooltip>
                        // )
                        break;
                    default:
                        set_couleur("blue")
                }
            })
            set_conditions_affichage(condi_tmp);
        }


        if (etalonnages && date_mesure){
            etalonnages.map(etal => {
                let fin = etal.fin;
                if (!fin) fin = "2099-01-01T14:46:38Z";
                if (new DateTZ(date_mesure) >= new DateTZ(etal.debut) && new DateTZ(date_mesure) < new DateTZ(fin))
                {
                    set_etalonnage_affichage({...etal});
                }
                return etal
            })
        }

        if (texte !== undefined) {
            if (texte === "D!") {
                set_couleur("orange");
                _texte_final = (
                    <Tooltip label={"La sonde est débranché sur le port d'extension de l'enregistreur"} multiline zIndex={1000000}>
                        <div style={{display: "flex", flexFlow: "column", alignItems: "center"}}>
                            <IconeLinkSimpleSlash/>
                            <div className={"inline-tag only_mobile"}>Sonde débranchée</div>
                        </div>
                    </Tooltip>
                )
            } else if (texte === "E!") {
                set_couleur("red");
                _texte_final = (
                    <Tooltip label={"La mesure reçue n'a pas pu être traitée par le système."} multiline>
                        <div>
                            <IconeTriangleExclamation/>
                        </div>

                    </Tooltip>
                )
            } else if (texte === "X!") {
                set_couleur("orange");
                _texte_final = (
                    <Tooltip label={"La mesure n'a aucun abonnement."} multiline>
                        <div>
                            <IconeEyeSlash />
                        </div>

                    </Tooltip>
                )
            } else if (texte === "H!") {
                set_couleur("orange");
                _texte_final = (
                    <Tooltip label={"Cette mesure vous est inaccessible."} multiline>
                        <div>
                            <IconeEyeSlash />
                        </div>

                    </Tooltip>
                )
            } else {
                let texte_tmp: string;
                if (sonde?.type_de_donnee === "bool") {
                    texte_tmp = texte == "0" ? (affichage_faux ?? "FAUX") : (affichage_vrai ?? "VRAI");
                } else texte_tmp = (sonde?.affichage_prefix ?? "") + texte + (sonde?.affichage_suffix ?? "");
                _texte_final = (<>{_texte_preffix}{texte_tmp}</>);
                _texte_final_sans_preffix = (<>{texte_tmp}</>);
            }

        } else {
            _texte_final = (<div className={"inline-tag"}>Aucune mesure</div>);
        }


        if (texte === undefined) set_couleur("grey")
        if (sonde?.affichage_nom_mesure === "GPS") set_couleur("gps")





        if (afficherEtatExtension) {
            if (!extension) {
                set_couleur("grey");
                _texte_final = (
                    <div className={"flex-col-center"}>
                        {_texte_final_sans_preffix ?? _texte_final}
                        <div className={"inline-tag"}>Cellule désactivée</div>
                    </div>
                );
            }

            // if (extension?.cellule?.last_mesures
            //     && new DateTZ(Date.parse(extension.cellule?.last_mesures?.enregistrement)) < new DateTZ(getCurrentDate().getTime() - env_config.duree_affichage_deconnexion_capteur)) {
            if (extension?.capteur.derniere_mesure
                && new DateTZ(Date.parse(extension.capteur.derniere_mesure)) < new DateTZ(getCurrentDate().getTime() - env_config.duree_affichage_deconnexion_capteur)) {
                set_couleur("grey")
                _texte_final = (
                    <div className={"flex-col-center"}>
                        {_texte_final_sans_preffix ?? _texte_final}
                        <div className={"inline-tag"}>Déconnecté</div>
                    </div>
                );
            }
        }



        if (sonde)
        {
            switch (sonde.slug_type) {
                case "gps":
                    set_icone_svg(<IconeMap />);
                    break;
                case "temperature":
                    set_icone_svg(<IconeTemperatureThreeQuarters />);
                    break;
                case "contact sec NO":
                    set_icone_svg(<IconePlug />);
                    break;
                case "contact sec NF":
                    set_icone_svg(<IconePlug />);
                    break;
                case "batterie":
                    set_icone_svg(<IconeBatteryThreeQuarters />);
                    break;
                case "aucune sonde":
                    set_icone_svg(<IconeHexagonXmark />);
                    break;
                default:
                    if (afficherTypeSiPasIcone) set_titre_jsx(<><i>{sonde.affichage_nom_mesure} </i>&nbsp;- {titre}</>);
                    else set_titre_jsx(<>{titre}</>);
            }
        }

        if (selected) set_icone_svg(<IconeBullseyePointer />);





        if (extension?.capteur.niveau_batterie || extension?.capteur?.rssi) {
            set_indicateurs(
                <>
                    <div className={"conditions"} style={{justifyContent: "space-between"}}>
                        {extension.capteur.niveau_batterie && (
                            <div className={"item"} onClick={(e) => {
                                e.stopPropagation();
                                navigate('/capteur/' + extension.capteur.uuid + "?cellule=" + extension.cellule?.id)
                            }}>
                                <Tooltip label={extension.capteur.niveau_batterie + "%"} position={"right"}>
                                    <div>
                                        <Icone_batterie niveau_batterie={extension.capteur.niveau_batterie}/>
                                    </div>
                                </Tooltip>
                            </div>
                        )}
                        {extension?.capteur?.rssi && (

                            <div className={"item"} onClick={(e) => {
                                e.stopPropagation();
                                navigate('/capteur/' + extension.capteur.uuid + "?cellule=" + extension.cellule?.id)
                            }}>
                                <Tooltip label={extension.capteur.rssi + "dBm"} position={"left"}>
                                    <div>
                                        <Icone_signal rssi={extension.capteur.rssi} snr={extension.capteur.snr}/>
                                    </div>
                                </Tooltip>
                            </div>
                        )}
                    </div>

                    {extension.capteur.error_code !== 0 && (
                        <div className={"conditions"} style={{justifyContent: "center", backgroundColor: "var(--error-color)", color: "White", width: "calc(100% - 1rem)"}}>
                            <div className={"item"} onClick={(e) => {
                                e.stopPropagation();
                                navigate('/capteur/' + extension.capteur.uuid + "?cellule=" + extension.cellule?.id)
                            }}>
                                <IconeCircleExclamation/> Capteur en erreur {extension.capteur.error_code}
                            </div>
                        </div>

                    )}
                </>


            )
        }

        set_texte_final(_texte_final);

    }, [afficherEtatExtension, afficherTypeSiPasIcone, conditions, date_mesure, etalonnages, extension, selected, sonde, texte, titre])


    

    return (
        <div className={"carte_mesure " + couleur + (bg_color ? " bg_color" : "") + (selected ? " selected" : "") + " " + className
        }
             onClick={handleClick}
             ref={ref}
             key={titre + texte + (extension?.id ?? 0)}
        >
            <div className={"titre"}>
                {icone_svg}
                <div className={"titre_container"}>
                    <p className={"titre_texte" + (isOverflowing ? ' slide' : '')} ref={titreRef}>{titre_jsx}</p>
                </div>
            </div>


            {bouton_icone && (
                <button className={"btn_custom_carte_mesure"} onClick={bouton_onclick}><Icone nom={bouton_icone} /></button>
            )}

            <p className={"valeur"}>{texte_final}</p>


            <div className={"en-ligne spacebetween"} style={{gap: "unset"}}>
            {conditions_affichage.length > 0 && afficherCondition ? (
                <>
                    <div className={"conditions"}>
                        {conditions_affichage}
                    </div>


                </>
            ) : <p></p>}
                {indicateurs}
            </div>
            {etalonnage_affichage && (
                <div className={"conditions"}>
                    <Tooltip label={"Etalonnage actif." }
                             position={"top"}
                             style={{fontSize: "var(--texte-size-petit)"}}
                    >
                        <IconeSlider />

                    </Tooltip>
                </div>

            )}

        </div>
    );
})