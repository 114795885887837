import React, {useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {Checkbox, Modal, MultiSelect, Select, Switch, Tooltip} from "@mantine/core";
import {afficherHeure, DateTZ} from "../../../services/GestionDates";
import {TimeInput} from "@mantine/dates";
import {horaire_maintenance} from "../../../types/condition";
import {IconeCheck} from "../../icone/solid_react/check";
import {IconeClock} from "../../icone/solid_react/clock";
import {IconeTrash} from "../../icone/solid_react/trash";
import {IconePlus} from "../../icone/solid_react/plus";
import {IconeCircleInfo} from "../../icone/solid_react/circle-info";
import {Cellule} from "../../../types/cellule";

export function Modale_horaire_maintenance({
                                               horaires,
                                               setHoraires,
                                               setFlou, disabled, inverserHoraire, setInverserHoraire,
                                           }: { horaires: horaire_maintenance[] | null, setHoraires: (horaire: horaire_maintenance[]) => void, setFlou: (etat: boolean) => void, disabled?: boolean, inverserHoraire:boolean, setInverserHoraire: (inverserHoraire:boolean) => void }) {
    const [opened, {open, close}] = useDisclosure(false);

    const [jours, setJours] = useState<string[]>([])
    const [debut, setDebut] = useState<string>("")
    const [fin, setFin] = useState<string>("")
    const [index, setIndex] = useState<number>(0)


    function ouvrirModale(horaire: horaire_maintenance, index: number) {
        if (disabled) return;
        setJours(horaire.j.map(jour => "" + jour))
        setDebut(convertMinutesToTime(horaire.d))
        setFin(convertMinutesToTime(horaire.f))
        setIndex(index);
        setFlou(true);

        open();
    }

    function fermerModale() {
        setFlou(false);
        close();
    }


    let now = new DateTZ();
    let UTC_offset = now.getTimezoneOffset();

    function ajouterHoraire() {
        if (disabled) return;
        if (!horaires) return;
        // let maj: horaire_maintenance[] = [...horaires];
        //
        // maj.push(
        //     {
        //         "j": [],
        //         "d": 0,
        //         "f": 0
        //     }
        // )
        //
        // setHoraires(maj);
        ouvrirModale({
            "j": [],
            "d": 0,
            "f": 0
        }, -1)
    }

    function handleValider() {
        if (!horaires) return;

        let maj: horaire_maintenance[];

        if (index === -1)
        {
            maj = [...horaires, {
                "j": jours.map(j => Number(j)),
                "d": convertTimeToMinutes(debut),
                "f": convertTimeToMinutes(fin)
            }]
        }
        else{
            maj = horaires.map((horaire, key) => {
                if (key === index) {
                    return {
                        "j": jours.map(j => Number(j)),
                        "d": convertTimeToMinutes(debut),
                        "f": convertTimeToMinutes(fin)
                    }
                }
                return horaire
            })
        }


        setHoraires(maj);

        fermerModale()
    }

    function handleSupprimer() {
        if (!horaires) return;
        let maj: horaire_maintenance[] = horaires.filter((horaire, key) => index !== key);

        setHoraires(maj);

        fermerModale()
    }

    function convertTimeToMinutes(time: string): number {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes + UTC_offset;
    }
    function convertMinutesToTime(minutes:number){
        let hor = minutes - UTC_offset;

        return afficherHeure(
            Math.floor(hor / 60),
            hor % 60
        )
    }

    const jours_label = ['zero','Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
        'Dimanche'];

    return (
        <>
            <Modal opened={opened} onClose={fermerModale} title="Horaires" closeOnClickOutside={false}>
                <div className={"form"}>

                    <Switch
                        label={"J'indique les horaires durant lesquelles les anomalies ne seront pas contrôlés. (s'applique pour toute la cellule)"}
                        checked={inverserHoraire}
                        onChange={e => setInverserHoraire(e.target.checked)}
                    />

                    {!inverserHoraire ? (
                        <p className={"inline-tag"}>Les horaires indiquée sont les horaires durant lesquelles les
                            anomalies ne seront pas contrôlées. (horaires de maintenances)</p>
                    ) : (
                        <p className={"inline-tag"}>Les horaires indiquée sont les horaires durant lesquelles les
                            anomalies seront contrôlées. (horaires de fonctionnement)</p>
                    )}


                    <MultiSelect
                        label="Jour de la semaine :"
                        data={[
                            {value: '1', label: 'Lundi'},
                            {value: '2', label: 'Mardi'},
                            {value: '3', label: 'Mercredi'},
                            {value: '4', label: 'Jeudi'},
                            {value: '5', label: 'Vendredi'},
                            {value: '6', label: 'Samedi'},
                            {value: '7', label: 'Dimanche'},
                        ]}
                        value={jours}
                        onChange={e => setJours(e)}
                    />
                    <TimeInput
                        label="Heure de début :"
                        rightSection={
                            <IconeClock/>
                        }
                        value={debut}
                        onChange={e => setDebut(e.currentTarget.value)}
                    />

                    <TimeInput
                        label="Heure de fin :"
                        rightSection={
                            <IconeClock/>
                        }
                        value={fin}
                        onChange={e => setFin(e.currentTarget.value)}
                    />

                    {fin < debut && (
                        <div className={"en-ligne"}>
                            <p className={"inline-tag"}>
                                L'heure de fin est inférieure à l'heure de début, et est donc considérée comme le
                                lendemain.
                                (Exemple de plage appliquée :
                                de {jours_label[Number(jours[0])]} {debut} à {jours_label[(Number(jours[0])%7) + 1]} {fin} )
                            </p>
                        </div>
                    )}


                    <div className={"en-ligne"}>
                        <button className={"sec"} onClick={handleSupprimer}><IconeTrash/>Supprimer</button>
                        <button onClick={handleValider} disabled={jours.length === 0}><IconeCheck/>Valider</button>
                    </div>
                </div>

            </Modal>

            <div style={{wordBreak: "break-word", lineHeight: "1.55"}}>
                <p style={{fontSize: "var(--texte-size-petit)"}}>
                    Horaires de maintenance :
                </p>
                <div className={"horaires_maintenance"} style={{color: disabled ? "grey" : "black"}}>
                    {horaires?.map((horaire, key) => {
                        return (
                            <div className={"horaire"}
                                 style={{cursor: disabled ? "not-allowed" : "pointer"}}
                                 onClick={() => ouvrirModale(horaire, key)} key={key}>
                                <div className={"jour"}>{horaire.j.map(value => {
                                    switch (value) {
                                        case 1:
                                            return " L";
                                        case 2:
                                            return " Ma";
                                        case 3:
                                            return " Me";
                                        case 4:
                                            return " J";
                                        case 5:
                                            return " V";
                                        case 6:
                                            return " S";
                                        case 7:
                                            return " D";
                                    }
                                    return "?";
                                })}</div>
                                <div className={"heure"}>{
                                    convertMinutesToTime(horaire.d)
                                } à {convertMinutesToTime(horaire.f)}</div>
                            </div>
                        )
                    })}
                    <button className={"sec"} onClick={ajouterHoraire} disabled={disabled}><IconePlus/></button>

                    <Tooltip className={"hide_on_mobile"} label={"Les horaires de maintenances indiquent les horaires durant lesquelles les " +
                        "anomalies ne seront pas controllées."} >
                        <div>
                            <IconeCircleInfo />
                        </div>

                    </Tooltip>
                </div>
            </div>
        </>
    );
}
