import React, {useEffect, useRef, useState} from "react";
import Joyride, {ACTIONS, CallBackProps, EVENTS, ORIGIN, STATUS, Step} from "react-joyride";
import {Organisation} from "../../../types/organisation";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {pasApasData, setPasApas, setPasApasTour} from "../../../store/pasApasSlice";
import {RootState} from "../../../store/store";
import {Pas_a_pas_services} from "../pas_a_pas_services";

export function Ajouter_un_appareil() {

    const [tourIntro, setTourIntro] = useState<Array<Step>>([]);
    const [tourStepIndex, setTourStepIndex] = useState<number>(0);
    const [tourRun, setTourRun] = useState<boolean>(true);

    const location = useLocation();

    const dispatch = useDispatch();

    const pasApas = useSelector<RootState, pasApasData>(state => state.pasApas)

    const organisations_compt = useSelector<RootState, number>(state => state.organisation.list.length)

    useEffect(() => {

        let run_etat:"keep"|"true"|"false"|"delay"|"delay+1" = "keep";
        let tour: Array<Step> = [];
        let index = tourStepIndex;

        // @ts-ignore
        run_etat = Pas_a_pas_services.gestion_etat_all();

        // @ts-ignore
        run_etat = Pas_a_pas_services.gestion_etat_all();

        if (location.pathname.startsWith("/organisation/") && location.pathname.split("/").length === 3) {
            Pas_a_pas_services.closeNav()
            run_etat = "false";


            tour = [
                {
                    target: '#zone-appareils',
                    content:
                        <>
                            <p>Voici la liste de vos appareils</p>
                        </>,
                    placement: 'bottom',
                    spotlightClicks: true,
                    disableScrolling: true,
                    disableBeacon: true,
                    // hideFooter: true,
                },
                {
                    target: "#btn-ajouter-appareil",
                    content:
                        <>
                            <p>Cliquez ici pour ajouter un nouvel appareil.</p>
                        </>,
                    placement: 'top',
                    spotlightClicks: true,
                },
            ];


            if (pasApas.etat === "page_orga_loaded" || document.querySelector("#carte_action_users")) {
                run_etat = "true";
                index = 0;
            }
            if (pasApas.etat === "modale_ajouter_appareil_open") {
                Pas_a_pas_services.reset()
            }
        }
        else{
            // run_etat = "delay"
            // tour = [
            //     {
            //         target: "#nav_accueil",
            //         content:
            //             <>
            //                 <p>Pour commencer, rendez vous à l'accueil</p>
            //             </>,
            //         disableBeacon: true,
            //         placement: 'right',
            //         spotlightClicks: true,
            //         // hideFooter: true,
            //     },
            // ];
            run_etat = "delay";
            index = 0;

            tour = [
                {
                    target: 'body',
                    content:
                        <>
                            <p>Nous allons ajouter un appareil à votre organisation.</p>
                        </>,
                    placement: 'center',
                    spotlightClicks: false,
                },
            ];


            index = Pas_a_pas_services.step_ouvrir_nav(tour, index);
            tour.push({
                target: "#nav_organisations",
                content:
                    <>
                        <p>Pour commencer, rendez vous sur la page de votre organisation</p>
                        {organisations_compt > 1 && (
                            <p>Cliquez sur le bouton Organisations et cliquez sur votre organisation dans le menu qui apparait</p>
                        )}
                        {/*<p>L'option pour déplacer une cellule dans une autre zone est dispo sur la page de la cellule, dans le menu de droite.</p>*/}
                    </>,
                placement: 'right',
                spotlightClicks: false,
            })
        }

        setTourIntro(tour)

        setTourStepIndex(index)

        switch (run_etat) {
            case "delay":
                setTourRun(false)
                // setTourStepIndex(0)
                setTimeout(() => {
                    setTourRun(true)
                }, Pas_a_pas_services.delay)
                break;


            // @ts-ignore
            case "delay+1":
                setTourRun(false)
                setTourStepIndex(tourStepIndex+1)
                setTimeout(() => {
                    setTourRun(true)
                }, Pas_a_pas_services.delay)
                break;


            // @ts-ignore
            case "false":
                setTourRun(false)
                setTourStepIndex(0)
                break;


            // @ts-ignore
            case "keep":
                // Rien faire
                break;


            // @ts-ignore
            case "true":
                // setTourStepIndex(0)
                setTourRun(true)
                break;
        }





    }, [location.pathname, pasApas]);

    // useEffect(() => {
    //     alert(pasApas.etat)
    // }, [pasApas.etat]);


    return (
        <Joyride
            steps={tourIntro}
            stepIndex={tourStepIndex}
            callback={(data) => Pas_a_pas_services.handleJoyrideCallback(data, setTourRun, setTourStepIndex)}
            run={tourRun}
            styles={Pas_a_pas_services.styles}
            locale={Pas_a_pas_services.locale}
            continuous
        />
    )
}