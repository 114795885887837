import {User, UserLight} from "../types/user";

export function getAffichageNom(user:User|UserLight)
{
    return user.nom_affichage + (user.nom_famille ?  " " + user.nom_famille : "");
}
export function getAffichageNomShort(user:User)
{
    return user.nom_affichage + " " + (user.nom_famille.length > 0 ? user.nom_famille.slice(0, 1) + "." : "");
}


export const tva_pourcent = 20;
export function tva(montant:number, returnNumber?:boolean, getHTfromTTC?:boolean)
{
    if (getHTfromTTC) {
        let ht = Math.ceil(montant / ((100+tva_pourcent)/100) );
        if (returnNumber) return ht;
        return ht + "€ HT";
    } else {
        let ttc = Math.ceil(montant * (100+tva_pourcent)) / 100;
        if (returnNumber) return ttc;
        return ttc + "€ TTC";
    }

}