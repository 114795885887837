import React, {useEffect, useRef, useState} from "react";
import Joyride, {ACTIONS, CallBackProps, EVENTS, ORIGIN, STATUS, Step} from "react-joyride";
import {Organisation} from "../../../types/organisation";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {pasApasData, setPasApas, setPasApasTour} from "../../../store/pasApasSlice";
import {RootState} from "../../../store/store";
import {Pas_a_pas_services} from "../pas_a_pas_services";
import {setMobile_sidebar_show, setNavMenuOpenedState} from "../../../store/interfaceSlice";

export function Ajouter_un_utilisateur() {

    const [tourIntro, setTourIntro] = useState<Array<Step>>([]);
    const [tourStepIndex, setTourStepIndex] = useState<number>(0);
    const [tourRun, setTourRun] = useState<boolean>(true);

    const location = useLocation();

    const dispatch = useDispatch();

    const pasApas = useSelector<RootState, pasApasData>(state => state.pasApas)
    const organisations_compt = useSelector<RootState, number>(state => state.organisation.list.length)


    useEffect(() => {

        let run_etat:"keep"|"true"|"false"|"delay"|"delay+1" = "keep";
        let tour: Array<Step> = [];
        let index = tourStepIndex;

        // @ts-ignore
        run_etat = Pas_a_pas_services.gestion_etat_all();

        if (location.pathname.startsWith("/organisation/") && location.pathname.split("/").length === 3) {
            Pas_a_pas_services.closeNav()
            run_etat = "false";



            if (pasApas.etat === "page_orga_loaded" || document.querySelector("#carte_action_users")) {
                run_etat = "delay";
                index = 0;
            }
            if (pasApas.etat === "modale_user_orga_loaded") {
                run_etat = "delay";
                index = 1;
            }
            if (pasApas.etat === "modale_ajout_user_open") {
                Pas_a_pas_services.reset()
            }


            index = Pas_a_pas_services.step_ouvrir_menu(tour, index)
            // if (Pas_a_pas_services.checkIfMenuIsOpen()) index++;
            tour = tour.concat([
                {
                    target: '#carte_action_users',
                    content:
                        <>
                            <p>Accédez à la liste des utilisateurs ici</p>
                        </>,
                    placement: 'left',
                    spotlightClicks: true,
                    disableBeacon: true,
                    hideFooter: true,
                },
                {
                    target: "#btn_ajout_user",
                    content:
                        <>
                            <p>Cliquez ici pour ajouter un nouvel utilisateur.</p>
                        </>,
                    spotlightClicks: true,
                    disableScrolling: true,
                    hideBackButton: true
                },
                {
                    target: ".modale_orga_utilisateurs",
                    content:
                        <>
                            <p>Pour modifier les alertes et permissions d'un utilisateur, vous devez d'abord le
                                cocher dans la liste</p>
                        </>,
                    placement: 'center',
                    spotlightClicks: true,
                },
            ]);
        }
        else{
            run_etat = "delay";
            index = 0;

            tour = [
                {
                    target: 'body',
                    content:
                        <>
                            <p>Nous allons ajouter ou modifier un utilisateur à votre organisation.</p>
                        </>,
                    placement: 'center',
                    spotlightClicks: false,
                },
            ];

            index = Pas_a_pas_services.step_ouvrir_nav(tour, index);
            tour.push({
                target: "#nav_organisations",
                content:
                    <>
                        <p>Pour commencer, rendez vous sur la page de votre organisation</p>
                        {organisations_compt > 1 && (
                            <p>Cliquez sur le bouton Organisations et cliquez sur votre organisation dans le menu qui apparait</p>
                        )}
                        {/*<p>L'option pour déplacer une cellule dans une autre zone est dispo sur la page de la cellule, dans le menu de droite.</p>*/}
                    </>,
                placement: 'right',
                spotlightClicks: false,
            })
        }

        setTourIntro(tour)

        setTourStepIndex(index)

        console.log("pasApas.etat : " + pasApas.etat)
        console.log("run_etat : " + run_etat)
        console.log("index : " + index)
        console.log("tour.length : " + tour.length)

        switch (run_etat) {
            case "delay":
                setTourRun(false)
                // setTourStepIndex(0)
                setTimeout(() => {
                    setTourRun(true)
                }, 500)
                break;


            // @ts-ignore
            case "delay+1":
                setTourRun(false)
                setTourStepIndex(tourStepIndex+1)
                setTimeout(() => {
                    setTourRun(true)
                }, Pas_a_pas_services.delay)
                break;


            // @ts-ignore
            case "false":
                setTourRun(false)
                setTourStepIndex(0)
                break;


            // @ts-ignore
            case "keep":
                // Rien faire
                break;


            // @ts-ignore
            case "true":
                // setTourStepIndex(0)
                setTourRun(true)
                break;
        }



    }, [location.pathname, pasApas]);

    // useEffect(() => {
    //     alert(pasApas.etat)
    // }, [pasApas.etat]);


    return (
        <Joyride
            steps={tourIntro}
            stepIndex={tourStepIndex}
            callback={(data) => Pas_a_pas_services.handleJoyrideCallback(data, setTourRun, setTourStepIndex)}
            run={tourRun}
            styles={Pas_a_pas_services.styles}
            locale={Pas_a_pas_services.locale}
            continuous
        />
    )
}