import env_config from "../env_config";
import {Row} from "@tanstack/react-table";
import {Notifications} from "../types/notifications";
import {afficherDateTime, DateTZ, getCurrentDate} from "./GestionDates";
import {Last_mesure} from "../types/mesure";
import {Cellule} from "../types/cellule";

export function texte_est_inclus(texte: string | undefined, recherche: string)
{
    if (texte === undefined) return false
    return texte_cleaner(texte).toLowerCase().trim().includes(texte_cleaner(recherche).toLowerCase().trim())
}

export function texte_cleaner(texte:string){
    return texte.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
}

export function texte_simple_compare_tableau(row: Row<any>,
                                             columnId: string,
                                             filterValue: string)
{
    //alert(filterValue)
    if (row.getValue(columnId) && typeof row.getValue(columnId) === "string")
    {
        return texte_est_inclus(row.getValue(columnId), filterValue)
    }
    return false;
}

export function texte_custom_compare_tableau(texte: string, recherche: string)
{
    // alert(filterValue)
    if (texte && typeof texte === "string")
    {
        return texte_est_inclus(texte, recherche)
    }
    return false;
}

export function texte_date_compare_tableau(row: Row<any>,
                                             columnId: string,
                                             filterValue: string)
{
    if (row.getValue(columnId)) {
        return texte_est_inclus(afficherDateTime(new DateTZ(row.getValue(columnId))), filterValue)
    }
    else return "Jamais".toLowerCase().includes(filterValue.toLowerCase())
}

export function last_mesure_get_couleur(cellule:Cellule)
{
    const regex = /^[a-zA-Z]!$/;
    let couleur = "grey-full"
    let texte = "#"
    if (cellule?.last_mesures){
        texte = cellule.sonde_type?.type_de_donnee === "bool" ? (
                (cellule?.last_mesures?.mesure === "1" ? cellule.affichage_vrai : cellule.affichage_faux)
            ) :
            "" + cellule?.last_mesures?.mesure + cellule.sonde_type?.affichage_suffix
        couleur = "green"
    } else texte = "#"

    if (cellule.last_mesures && regex.test(cellule.last_mesures?.mesure)) {
        couleur = "orange"
        texte = "Θ"
    }
    if (cellule.last_mesures && new DateTZ(Date.parse(cellule.last_mesures?.enregistrement)) < new DateTZ(getCurrentDate().getTime() - env_config.duree_affichage_deconnexion_capteur)){
        couleur = "grey-full";
    }

    if (texte === "#" || texte === "Θ") couleur = couleur + " no-italic";

    return {
        color: couleur,
        texte: texte
    };
}

export function removeDuplicates(a:string[]|undefined)
{
    if (a === undefined) return []
    var seen = {};
    return a.filter(function(item) {
        // @ts-ignore
        return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });
}