import {LineChart} from '@mantine/charts';
import React, {useEffect, useRef, useState} from "react";

import './Factures_pdf.scss'

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {User} from "../../../types/user";
import {Link, useParams} from "react-router-dom";
import logo_freshtime from './logo_freshtime.svg';
import {Cellule} from "../../../types/cellule";
import {Mesure} from "../../../types/mesure";
import ajax from "../../../services/AxiosInterceptor";
import {Capteur} from "../../../types/capteur";
import {Paiementtype_full} from "../../../types/organisation";
import {Loading_div} from "../../../components/loading_div/loading_div";
import {tva, tva_pourcent} from "../../../services/UserService";
import {afficherDate, afficherDateTime, DateTZ} from "../../../services/GestionDates";


export function Factures_pdf() {

    const [paiement, setPaiement] = useState<Paiementtype_full | null>(null);

    const [loading_fait, setLoading_fait] = useState(false);

    let transaction_id = useParams().id;
    let numero_facture = useParams().numero;

    useEffect(() => {
        const fetchData = () => {
            if (transaction_id && transaction_id != "") {

                ajax.get('/paiement-gen-facture/' + transaction_id)
                    .then((response) => {

                        setPaiement(response.data.paiement);

                        setLoading_fait(true)

                    }).catch(err => {
                    console.log(err);
                    throw new Error("AJAX 2 went wrong : " + err);
                })


                // setCellules(_cellules)
            } else {
                throw new Error("URL ARGS wrong");
            }
        }

        try {
            fetchData()
        } catch (e) {

            const event = new CustomEvent('PDFGenPageReady', {detail: 'error : ' + e});
            window.dispatchEvent(event);
        }


    }, []);


    useEffect(() => {
        if (loading_fait) {
            const event = new CustomEvent('PDFGenPageReady', {detail: 'success'});
            window.dispatchEvent(event);
        }
    }, [loading_fait]);

    if (!numero_facture) {
        throw new Error("numero_facture null");
    }

    return (
        <div className={"page-facture-pdf"}>

            {!paiement ? <Loading_div /> : (
                <section>
                    <div className={"en-ligne spacebetween"}>
                        <img src={logo_freshtime} alt="" className={"logoimg"}/>
                        <div className={"en-tete-pdf-droite"}>
                            <h2>Facture Freshtime</h2>
                            <h1>Organisation {paiement.organisation.nom}</h1>
                        </div>

                    </div>

                    <div className={"partie-freshtime"}>
                        <strong>FRESHTIME</strong>
                        <p>20, bd Princesse Charlotte</p>
                        <p>Le Roqueville</p>
                        <p>98000 MONACO</p>
                        <p>N° TVA Intracommunautaire : FR48000121359</p>
                        <p>N° de siret : 4618Z19509</p>
                    </div>

                    <div className={"partie-client"}>
                        <strong>{paiement.organisation.infosFacturation?.denominationSociale}</strong>
                        <p>{paiement.organisation.infosFacturation?.addresse?.rue}</p>
                        <p>{paiement.organisation.infosFacturation?.addresse?.ville}</p>
                        <p>{paiement.organisation.infosFacturation?.addresse?.code_postal} {paiement.organisation.infosFacturation?.addresse?.pays}</p>
                        {paiement.organisation.infosFacturation?.numero_tva && (
                            <p>N° TVA Intracommunautaire : {paiement.organisation.infosFacturation?.numero_tva}</p>
                        )}
                        {paiement.organisation.infosFacturation?.numero_siret && (
                            <p>N° de siret : {paiement.organisation.infosFacturation?.numero_siret}</p>
                        )}
                    </div>

                    <h3>Facture n°FSH-{numero_facture.padStart(6, '0')} du {afficherDate(new DateTZ())}</h3>
                    <table>
                        <tr>
                            <th>Montant net prélevé le {afficherDate(paiement.transaction?.date)}</th>
                            <th>{Number(paiement.transaction?.montant ?? paiement.montant).toFixed(2)} €</th>
                        </tr>
                        <tr className={"description"}>
                            <th>Montant facturé ({paiement.informations?.total ?? tva(paiement.montant, true, true)} € HT)</th>
                            <th>{Number(paiement.montant).toFixed(2)} € TTC</th>
                        </tr>
                        <tr className={"description"}>
                            <th>Dont TVA {tva_pourcent.toFixed(2)}%</th>
                            <th>{(Number(tva(paiement.montant, true)) - Number(paiement.montant)).toFixed(2)} €</th>
                        </tr>
                        <tr className={"description"}>
                        <th colSpan={2}>Moyen de paiement utilisé pour le prélèvement : Carte bancaire {paiement.organisation.cBLabel}</th>
                        </tr>
                    </table>

                    <h4>SOMMES FACTURÉES</h4>
                    <table>
                        <tr>
                            <td>{paiement.titre}</td>
                            <td>{paiement.informations?.total ?? tva(paiement.montant, true, true)}€ HT</td>
                            <td>{paiement.montant}€ TTC</td>
                        </tr>
                    </table>

                    {paiement.informations && (
                        <>
                            <h4>DÉTAIL DES SOMMES FACTURÉES (en HT)</h4>

                            <div className={"zones"} style={{marginTop: "1rem"}}>
                                <div className={"en-ligne"}>
                                    <i className="inline-tag">Total avance
                                        : {paiement.informations.avance && paiement.informations.avance.toFixed(2)}€
                                        HT</i>
                                    {!!paiement.informations.rattrapage && paiement.informations.rattrapage > 0 && (
                                        <i className="inline-tag">Total rattrapage
                                            : {paiement.informations.rattrapage && paiement.informations.rattrapage.toFixed(2)}€
                                            HT</i>
                                    )}
                                    {!!paiement.informations.remboursement && paiement.informations.remboursement > 0 && (
                                        <i className="inline-tag">Total remboursement
                                            : {paiement.informations.remboursement && paiement.informations.remboursement.toFixed(2)}€
                                            HT</i>
                                    )}
                                    {!!paiement.informations.organisation.balance && paiement.informations.organisation.balance > 0 && (
                                        <i className="inline-tag">Balance de l'organisation après prélèvement
                                            : {paiement.informations.organisation.balance.toFixed(2)}€ HT</i>
                                    )}


                                    {paiement.informations.total && (
                                        <>
                                        <i className="inline-tag">TOTAL HT
                                                : {paiement.informations.total.toFixed(2)} €
                                            </i>
                                            {paiement.informations.totalTTC && (
                                                <i className="inline-tag">TVA :
                                                    +{(paiement.informations.totalTTC - paiement.informations.total).toFixed(2)} €
                                                </i>
                                            )}
                                        </>
                                    )}


                                    {paiement.transaction && (
                                        <i className="inline-tag">Prélèvement bancaire
                                            : {paiement.transaction.montant.toFixed(2)}€</i>
                                    )}

                                    {paiement.informations.promotion !== undefined && paiement.informations.promotion > 0 && (
                                        <i className="inline-tag">Promotion
                                            de {paiement.informations.promotion}%
                                            ({paiement.informations.promotion_montant?.toFixed(2)}€)
                                            appliquée : {paiement.informations.promotion_texte}
                                        </i>
                                    )}
                                    {paiement.informations.SMS_a_crediter !== undefined && paiement.informations.SMS_a_crediter > 0 && (
                                        <i className="inline-tag">+{paiement.informations.SMS_a_crediter} SMS
                                            offerts
                                        </i>
                                    )}
                                    {paiement.informations.Tel_a_crediter !== undefined && paiement.informations.Tel_a_crediter > 0 && (
                                        <i className="inline-tag">+{paiement.informations.Tel_a_crediter} Appels
                                            offerts
                                        </i>
                                    )}
                                </div>
                                <div className={"tableauContainer"} style={{maxWidth: "unset"}}>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Cellule</th>
                                            <th>Détails</th>
                                            <th>Abonnement choisi</th>
                                            <th>Debut période</th>
                                            <th>Fin période</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {paiement.informations.statuts?.map(statut => (
                                            statut.cellule.statut_actuel &&
                                            <tr key={statut.cellule.extension?.capteur?.uuid}>
                                                <td>
                                                    <p>
                                                        {statut.cellule.nom}
                                                    </p>
                                                </td>
                                                <td style={{textAlign: "left"}} className={"texte-petit"}>
                                                    {statut.details.split("\n").map(ligne => <p
                                                        className={"espace-entre-items"}>{ligne}</p>)}
                                                </td>
                                                <td>
                                                    {statut.cellule.statut_actuel.statut.nom} ({statut.cellule.statut_actuel.statut.prix_achat}€ HT/mois)
                                                </td>
                                                <td>
                                                    {afficherDateTime(statut.cellule.statut_actuel.debut)}
                                                </td>
                                                <td>
                                                    {afficherDateTime(statut.cellule.statut_actuel.fin)}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </>
                    )}


                </section>
            )}


        </div>

    );
}
