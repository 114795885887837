import React, {useEffect, useState} from "react";

import ajax from "../../../../services/AxiosInterceptor";
import {Tooltip} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import {Tableau_helper, typeDonnee} from "../../../../components/tableau/Tableau_helper";
import {AxiosResponse} from "axios";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {IconeEye} from "../../../../components/icone/solid_react/eye";
import {Paiementtype_full} from "../../../../types/organisation";
import {User} from "../../../../types/user";
import {IconeEnvelope} from "../../../../components/icone/solid_react/envelope";
import {IconeDownload} from "../../../../components/icone/solid_react/download";

export function Factures_admin() {

    const [donnees, setDonnees] = useState<Paiementtype_full[] | null>(() => null);
    const [selection, setSelection] = useState<Paiementtype_full[] | null>(null);


    const Paiementtype_full_Tableau_admin:typeDonnee[] = [
        {key: "id", nom: "ID", noedit: true},
        {key: "organisation.nom", nom: "Organisation"},
        {key: "enregistrement", nom: "Date", type: "date"},
        {key: "titre", nom: "Titre"},
        {key: "facture.nom", nom: "Facture"},
        {key: "montant", nom: "Montant", suffix:"€ TTC"},
        // {key: "informations", wrapper: (texte:string) => (
        //         <Tooltip label={texte} onClick={() => alert(texte)}>
        //             <p className={"inline-tag"}><IconeEye /></p>
        //         </Tooltip>),
        //     spanClassname: "no-wrap flex_align_center",
        //     desc: "Description affichée au client",
        //     type: "textarea"
        // },
        // {action_editer: true},
    ]

    useEffect(() => {
        ajax.get("/be/factures").then(response => {
            setDonnees(response.data.factures.sort((a:Paiementtype_full, b:Paiementtype_full) => {
                if (a.enregistrement < b.enregistrement) return 1
                if (a.enregistrement > b.enregistrement) return -1
                return 0
            }));
        }).catch(err => {})
    }, []);

    async function getFactures() {
        try {
            // Make a POST request to the API
            const response = await ajax.post(
                "/be/dl_factures",
                {
                    paiements_ids: selection?.map(s => s.id)
                },
                {
                    responseType: "blob", // Important: Ensure the response is treated as a Blob
                }
            );

            // Extract the filename from the response headers (if available)
            const disposition = response.headers["content-disposition"];
            const fileName = disposition
                ? disposition
                    .split("filename=")[1]
                    .replace(/"/g, "") // Remove quotes around the filename
                : "Factures.zip";

            // Create a link to download the file
            const blob = new Blob([response.data], { type: "application/zip" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;

            // Programmatically click the link to trigger the download
            link.click();

            // Clean up the object URL
            window.URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error("Error downloading ZIP file:", error);
        }
    }


    return (

        <>
            <Tableau_helper
                typeDonnee={Paiementtype_full_Tableau_admin}
                donnees={donnees}
                // handleEnregistrer={handleEnregistrer}
                // formatNew={{
                //     id : -1,
                //     nom : "",
                //     informations : "",
                // }}
                setTable={(_table) => {

                    setSelection(_table.getSelectedRowModel().rows.map((row) => row.original));
                }}
                enable_selection={true}
                boutons={
                    <>
                        <button disabled={selection?.length === 0}
                                onClick={getFactures}>
                            <IconeDownload/>Télécharger les factures sélectionnées ({selection?.length})
                        </button>
                    </>
                }
            />
        </>



    );
}
