import {Capteur} from "./capteur";
import {Extension} from "./extension";

export type Sonde = {
        id: number,
        nom: string,
        type_de_donnee: string,
        categorie_de_sonde_id: number
        slug_type: string,
        affichage_nom_mesure: string,
        affichage_prefix: string,
        affichage_suffix: string,
        affichage_vrai_s: string,
        affichage_faux_s: string,
        test_deconnexion: string,
        calcul: string
        extensions?:Extension[]
}

export const gps_sonde: Sonde = {
        "id": 0,
        "nom": "GPS",
        "type_de_donnee": "gps",
        "slug_type": "gps",
        "categorie_de_sonde_id": 0,
        "affichage_nom_mesure": "GPS",
        "affichage_vrai_s": "",
        "affichage_faux_s": "",
        "affichage_prefix": "",
        "affichage_suffix": "",
        "calcul" : "",
        "test_deconnexion" : "",
}

export type categorie_de_sonde = {
        id :number,
        nom: string,
        description :string,
}