import React, {forwardRef, useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../../services/AxiosInterceptor";
import {Modal, NumberInput, Switch} from "@mantine/core";
import {Commercial, Resultat_calcul_commercial} from "../../../../types/commercial";
import {afficherDate, DateTZ} from "../../../../services/GestionDates";
import {DateInput} from "@mantine/dates";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {IconeEuroSign} from "../../../../components/icone/solid_react/euro-sign";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import {IconeCheck} from "../../../../components/icone/solid_react/check";


export function Modale_calcul_commercial({commercial, close, refresh}: {
    commercial: Commercial|null, close: () => void, refresh: () => void;
}) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading_calcul, set_btn_loading_calcul] = useState(false);

    const [loading_btn_enregistrer_paiement, setLoading_btn_enregistrer_paiement] = useState(false);

    useEffect(() => {
        if (commercial) handlers.open();
        else handlers.close();
    }, [commercial]);

    // const [commercial_id, setCommercial_id] = useState<string|null>((capteur?.commercial?.id ? capteur?.commercial?.id + "" : null) ?? null);

    const [resultat_calcul, setResultat_calcul] = useState<Resultat_calcul_commercial|null>(null);

    const [paiement_a_enregistrer, setPaiement_a_enregistrer] = useState(0);

    function handleCalcul() {
        set_btn_loading_calcul(true);
        ajax.post("/commercial/" + commercial?.user?.id + "/calcul_commission", {
            "dateInput": dateInput.toISOString(),
            "utiliserPrime": utiliserPrime,
        }).then(response => {
            setResultat_calcul(response.data.calcul);
            set_btn_loading_calcul(false);
        }).catch(error => {
            set_btn_loading_calcul(false);
        })

    }

    function handleEnregistrerPaiement() {
        setLoading_btn_enregistrer_paiement(true);
        ajax.post("/commercial/" + commercial?.user?.id + "/enregistrer_paiement", {
            "date": new DateTZ().toISOString(),
            "montant": paiement_a_enregistrer,
        }).then(response => {
            notifications.show({
                // id: 'enregistre',
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Paiement enregistré",
                message: '',
                icon: <IconeCheck/>,
                // className: 'my-notification-class',
                loading: false,
            });
            handlers.close();
            setTimeout(refresh, 500);
            setLoading_btn_enregistrer_paiement(false);
        }).catch(error => {
            setLoading_btn_enregistrer_paiement(false);
        })

    }

    const [utiliserPrime, setUtiliserPrime] = useState(false);
    const [dateInput, setdateInput] = useState(new DateTZ());


    return (
        <>
            <Modal opened={opened} onClose={() => {
                handlers.close()
                close();
            }} title={"Calcul commerciaux"}>

                <div className={"en-ligne"}>
                    <DateInput
                        label="Date du calcul"
                        description={"Les ventes et abonnements après cette date ne seront pas pris en compte"}
                        locale="fr"
                        valueFormat="DD/MM/YYYY"
                        value={dateInput}
                        onChange={e => {
                            if (e) {
                                let jus = new DateTZ(e.getTime());
                                jus.setHours(12);
                                setdateInput(jus);
                            } else {
                                setdateInput(new DateTZ())
                            }
                        }}
                    />
                    <Switch
                        label="Prendre en compte les primes ?"
                        checked={utiliserPrime}
                        onChange={e => {
                            setUtiliserPrime(e.target.checked);
                        }}
                    />
                    <Loading_button is_loading={btn_loading_calcul} onClick={handleCalcul}>Lancer le calcul</Loading_button>
                </div>


                {resultat_calcul && (
                    <>

                        <div className={"cartes_resultat_calcul"}>
                            <div className={"carte"} style={{width: "100%"}}>
                                <p>Résultats
                                    au {afficherDate(resultat_calcul.dateInput)}. {resultat_calcul.utiliserPrime ? "Primes appliqués." : ""}</p>
                            </div>
                        </div>
                        <div className={"cartes_resultat_calcul"}>
                            <div className={"carte"}>
                                <p>{resultat_calcul.capteurs_cette_annee.length} Enregistreurs vendu cette année</p>
                                <p className={"prix"}>{resultat_calcul.ventes}€</p>
                                <div className={"en-ligne spacebetween"}>
                                    <p>{resultat_calcul.comm_pourcentage_vente}%</p>
                                    <p>{resultat_calcul.comm_vente}€</p>
                                </div>
                            </div>
                            <div className={"carte"}>
                                <p>{resultat_calcul.paiements_abo_cette_annee?.length ?? 0} Abonnements des enregistreurs
                                    vendus cette année</p>
                                <p className={"prix"}>{resultat_calcul.abonnement_cette_annee}€</p>
                                <div className={"en-ligne spacebetween"}>
                                    <p>{resultat_calcul.comm_pourcentage_abonnement}%</p>
                                    <p>{resultat_calcul.comm_abonnement}€</p>
                                </div>
                            </div>
                        </div>
                        <div className={"cartes_resultat_calcul"}>
                            <div className={"carte"}>
                                <p>{resultat_calcul.capteurs_annee_precedentes.length} Enregistreurs vendu les années
                                    précédentes</p>
                                <p>{resultat_calcul.paiements_abo_annee_precedente.length} Abonnements des enregistreurs
                                    vendu les années précédentes</p>
                                <p className={"prix"}>{resultat_calcul.abonnement_annee_precedente}€</p>
                                <div className={"en-ligne spacebetween"}>
                                    <p>{resultat_calcul.comm_pourcentage_annee_precedentes}%</p>
                                    <p>{resultat_calcul.comm_annee_precedentes}€</p>
                                </div>

                            </div>
                            <div className={"carte"}>
                                <p>Total à payer pour l'année : {resultat_calcul.total_a_payer_au_commercial}€</p>
                                <p>{resultat_calcul.a_deja_ete_paye}€ déjà payé</p>
                                <p className={"prix"}>{resultat_calcul.restant_a_paye}€ restant à payer</p>
                            </div>
                        </div>
                        <div className={"en-ligne"}>

                        </div>

                        {/*<pre>{JSON.stringify(resultat_calcul, null, 2)}</pre>*/}


                    </>

                )}

                <div className={"enregistrer_un_paiement"}>
                    <strong>Enregistrer un paiement</strong>
                    <NumberInput
                        leftSection={<IconeEuroSign/>}
                        value={paiement_a_enregistrer}
                        onChange={e => setPaiement_a_enregistrer(Number(e))}
                        step={0.01}
                    />
                    <Loading_button disabled={paiement_a_enregistrer === 0} is_loading={loading_btn_enregistrer_paiement} onClick={handleEnregistrerPaiement} >Enregistrer le paiement</Loading_button>
                </div>

            </Modal>
        </>

    )
}