import React, {useEffect, useState} from "react";

import {Tableau} from "../../../../components/tableau/Tableau";
import ajax from "../../../../services/AxiosInterceptor";
import {
    ColumnDef,
    createColumnHelper,
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel, Row, SortingState,
    useReactTable
} from "@tanstack/react-table";
import {Accordion, Autocomplete, Modal, NativeSelect, Select, Tabs, TextInput, Tooltip} from "@mantine/core";
import {Link, useNavigate} from "react-router-dom";
import {Organisation} from "../../../../types/organisation";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import {
    removeDuplicates,
    texte_date_compare_tableau,
    texte_est_inclus,
    texte_simple_compare_tableau
} from "../../../../services/GestionTextes";
import {Offre_abonnement, Statut_cellule} from "../../../../types/statut_cellule";
import {Tableau_helper, typeDonnee} from "../../../../components/tableau/Tableau_helper";
import {AxiosResponse} from "axios";
import {Cellule_light_with_organisation} from "../../../../types/cellule";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {IconeEye} from "../../../../components/icone/solid_react/eye";
import {useDisclosure} from "@mantine/hooks";
import {Offre_abonnements_admin} from "./Offre_abonnements_admin";
import {IconeShop} from "../../../../components/icone/solid_react/shop";
import {IconeStore} from "../../../../components/icone/solid_react/store";
import {IconeStar} from "../../../../components/icone/solid_react/star";
import {IconeReceipt} from "../../../../components/icone/solid_react/receipt";
import {Regles_paiement_admin} from "./Regles_paiement_admin";
import {IconeEuroSign} from "../../../../components/icone/solid_react/euro-sign";
import {Codes_promo_admin} from "./Codes_promo_admin";
import {Loading_div} from "../../../../components/loading_div/loading_div";
import {Ajouter_un_capteur} from "../Outils_admin/Ajouter_un_capteur";
import {PrixSMSAdmin} from "./PrixSMSAdmin";
import {IconeMessageSms} from "../../../../components/icone/solid_react/message-sms";
import {Factures_admin} from "./Factures_admin";
import {IconeFileExport} from "../../../../components/icone/solid_react/file-export";
import {IconeFileInvoiceDollar} from "../../../../components/icone/solid_react/file-invoice-dollar";

export function Statut_cellule_admin() {

    const [donnees, setDonnees] = useState<Statut_cellule[] | null>(() => null);

    const [modale_cellules_recherche, setModale_cellules_recherche] = React.useState<string>("")
    const [cellules_modale, setCellules_modale] = React.useState<Cellule_light_with_organisation[]|null>(null)

    const [offres_abonnements, setOffres_abonnements] = useState<Offre_abonnement[]|null>(null);

    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list)

    const [activeTab, setActiveTab] = useState<string | null>('comm');

    const Statut_Cellule_Tableau_admin:typeDonnee[] = [
        {key: "id", nom: "ID", noedit: true},
        {key: "nom", nom: "Nom", desc:"Nom de l'abonnement"},
        {key: "slug", desc: "Le nom de l'abonnement , qui est un nom unique pour nous admin. (Attention pas d'espace ni d'accent)"},
        {key: "categorie_abonnement", type:"autocomplete", nom: "categorie_abonnement", desc: "Indique pour quelle catégorie d'appareil cet abonnement doit apparaitre.", select_data: removeDuplicates(donnees?.map(d => d.categorie_abonnement)).map(d => {
                return {value: d + "", label: d};
            })},
        {key: "offre_abonnement_id", nom: "Offre d'abonnement", desc: "La catégorie de cet abonnement", type:"select",
            select_data: offres_abonnements?.map(o => {
                return {value: o.id+"", label: o.nom}
            }), wrapper: (texte:number) => (
                <>{offres_abonnements?.find(o => o.id == texte)?.nom ?? texte}</>)},
        {key: "prix_achat", suffix:"€", desc:"Prix HT/mois"},
        {key: "is_degrade", type:"boolean",
            desc: "Si coché cet abonnement rendra les mesures caché pour le client. " +
                "Les abonnements dégradé sont utilisés pour modifier un abonnement qui périme (ou non payé)."},
        {key: "is_affichable_achat", type:"boolean", desc: "Si coché, cet abonnement sera visible par toutes les cellules avec la même Catégorie d'abonnement, dans toutes les organisations."},
        {key: "organisations_a_afficher", nom:"Organisations concernées", type: "multi-select", select_searchable: true,
            desc: "Si is_affichable_achat est décoché, seul les organisations indiquées ici auront accès à cet abonnement.",
            // wrapper: (orgas:number[]|null) => {
            //     return <>{orgas?.map(o_id => {
            //         return (organisations.find(o => o.id == o_id)?.nom ?? o_id);
            //     }).toString()}</>
            // },
            edit_display_value: (orgas:number[]|null) => {return orgas?.map(o_id => {
                return (organisations.find(o => o.id == o_id)?.nom ?? o_id);
            }).toString() ?? ""},
            select_data: organisations.map(o => {
                return {"value": o.id+"", "label" : o.nom}
            })
        },
        {key: "duree_archivage", suffix: " jours", desc: "La durée en jours durant laquelle les mesures sont conservés."},
        {key: "cellules", wrapper: (texte:any) => (
                <Tooltip label={texte.length + " cellules utilisant cet abonnement"} onClick={() => setCellules_modale(texte)}>
                    <p className={"inline-tag green"}>{texte.length} cellules</p>
                </Tooltip>),
            spanClassname: "no-wrap flex_align_center",
            noedit: true
        },
        {key: "only_derniere_mesure", type:"boolean", desc: "Si coché, uniquement la dernière mesure sera affiché et les précédentes cachés."},
        {key: "can_use_alertes", type:"boolean", desc: "Si coché, les anomalies et alertes seront autorisé dans la cellule"},
        {key: "can_use_fonctions_avancees", type:"boolean", desc: "Si coché, rend disponible les analyses intelligentes dans la cellule"},
        {key: "can_use_graphiques", type:"boolean", desc: "Si coché, la générations de graphiques et l'export sera disponible"},
        {key: "can_superposer_cellules", type:"boolean", desc: "Si coché, plusieurs cellules pourront être superposés sur lers graphiques et les exports"},
        {key: "credit_SMS"  , desc: "Combien de crédits SMS doivent être crédités dans l'organisation lors du renouvellement de cet abonnement ? (1 fois par mois)"},
        {key: "credit_Tel"  , desc: "Combien de crédits d'Appels doivent être crédités dans l'organisation lors du renouvellement de cet abonnement ? (1 fois par mois)"},
        {key: "statut_si_perime_id",
            desc: "Le ID unique de l'abonnement qui prendra la place de celui-ci quand celui-ci est impayé ou périmé " +
                "(Un abonnement dégradé généralement). Si on veut que la cellule se désactive plutot que passer à un autre statut, laisser vide."},
        {key: "duree_statut_si_perime", suffix: " mois", desc: "Durée en nombre de mois de l'abonnement suivant, une fois celui-ci impayé."},
        {key: "duree_engagement", suffix: " mois", desc: "Durée d'engagement en mois pendant lequel la cellule ne peut être changé pour un abonnement inférieur en prix. Mettre 0 pour désactiver l'engagement."},
        // {key: "informations", wrapper: (texte:string) => (
        //         <Tooltip label={texte} onClick={() => alert(texte)}>
        //             <p className={"inline-tag"}><IconeEye /></p>
        //         </Tooltip>),
        //     spanClassname: "no-wrap flex_align_center",
        //     desc: "Description affichée au client",
        //     type: "textarea"
        // },
        {key: "lock_admin", type:"boolean", noedit: true, desc: "Empeche la future modification de cet abonnement. Ne pas cocher sans être sûr."},
        {key: "note_admin", wrapper: (texte:string) => (
                <Tooltip label={texte} onClick={() => alert(texte)}>
                    <p className={"inline-tag"}><IconeEye /></p>
                </Tooltip>),
            spanClassname: "no-wrap flex_align_center",
            desc: "Note uniquement pour équipes Freshtime, non affiché aux clients",
            type: "textarea"
        },
        {action_editer: true, cacherDansTableSi: (row) => (row.lock_admin), action_dupliquer: (row:Statut_cellule) => {
            row.id = -1
            return row
            }},
    ]
    function handleEnregistrerStatut(statut:Statut_cellule|null, setIsLoading:(isLoading:boolean) => void) {

        if (!statut)
        {
            alert( "Erreur, abonnement cellule est : " + JSON.stringify(statut))
            return;
        }
        setIsLoading(true);
        let ajax_response = (response:AxiosResponse<any, any>) => {
            notifications.show({
                // id: 'enregistre',
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'L\'abonnement cellule est enregistré',
                icon: <IconeCheck/>,
                // className: 'my-notification-class',
                loading: false,
            });
            setIsLoading(false);

            if (statut.id < 0){
                let new_statut:Statut_cellule = response.data.statut;
                if (donnees) setDonnees([...donnees, new_statut])
            }else{
                if (donnees) setDonnees(donnees?.map(donnee => {
                    if (donnee.id === statut.id){
                        return statut
                    }
                    return donnee;
                }))
            }
        }
        if (statut?.id < 0)
        {
            ajax.post("/statutscellule", statut).then(ajax_response).catch(error => {
                setIsLoading(false);
            });
        } else{
            ajax.put("/statutscellule/" + statut.id, statut).then(ajax_response).catch(error => {
                setIsLoading(false);
            });
        }

    }

    useEffect(() => {
        ajax.get("/statutscellule").then(response => setDonnees(response.data.statutcellules)).catch(e => {
        })
    }, [])

    useEffect(() => {
        ajax.get("/offreabonnements").then(response => setOffres_abonnements(response.data.offre_abonnements)).catch(e => {
        })
    }, [])

    const format_new:Statut_cellule = {
        id : -1,
        nom : "",
        slug : "",
        duree_archivage : 0,
        statut_si_perime_id : 0,
        only_derniere_mesure : false,
        can_use_alertes : false,
        can_use_fonctions_avancees : false,
        can_use_graphiques : false,
        is_affichable_achat : false,
        can_superposer_cellules : false,
        duree_statut_si_perime : 0,
        duree_engagement : 0,
        credit_SMS : 0,
        credit_Tel : 0,
        is_degrade : false,
        prix_achat : "0.00",
        lock_admin : false,
        note_admin : "",
        categorie_abonnement : "",
        cellules: [],
        offre_abonnement: null,
        offre_abonnement_id: null,
        organisations_a_afficher: null,
    }


    return (
        <div className={"en-tete-pages"}>

            <Tabs defaultValue="status" value={activeTab} onChange={setActiveTab}>
                <Tabs.List>
                    <Tabs.Tab value="status">
                        <p className={"en-ligne"}><IconeEuroSign /> Abonnements</p>
                    </Tabs.Tab>
                    <Tabs.Tab value="offres">
                        <p className={"en-ligne"}><IconeStar /> Offres d'abonnements</p>
                    </Tabs.Tab>
                    <Tabs.Tab value="factures">
                        <p className={"en-ligne"}><IconeFileInvoiceDollar /> Factures</p>
                    </Tabs.Tab>
                    <Tabs.Tab value="regles">
                        <p className={"en-ligne"}><IconeStore /> Règles de paiements</p>
                    </Tabs.Tab>
                    <Tabs.Tab value="codes_promo">
                        <p className={"en-ligne"}><IconeReceipt /> Codes promo</p>
                    </Tabs.Tab>
                    <Tabs.Tab value="prixSMS">
                        <p className={"en-ligne"}><IconeMessageSms /> Prix SMS et Tel</p>
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="status">
                    <div className={"titre en-ligne en-tete-page"} style={{margin: "0rem 1rem 0 1rem"}}>
                        <h1>Abonnements cellule : </h1>
                    </div>

                    <Tableau_helper
                        typeDonnee={Statut_Cellule_Tableau_admin}
                        donnees={donnees}
                        handleEnregistrer={handleEnregistrerStatut}
                        formatNew={format_new}
                    />
                </Tabs.Panel>



                <Tabs.Panel value="offres">
                    <div className={"titre en-ligne en-tete-page"} style={{margin: "0rem 1rem 0 1rem"}}>
                        <h1>Offres d'abonnements : </h1>
                    </div>
                    <Offre_abonnements_admin offres={offres_abonnements} />
                </Tabs.Panel>

                <Tabs.Panel value="factures">
                    <div className={"titre en-ligne en-tete-page"} style={{margin: "0rem 1rem 0 1rem"}}>
                        <h1>Factures : </h1>
                    </div>
                    <Factures_admin />
                </Tabs.Panel>




                <Tabs.Panel value="regles">
                    <Regles_paiement_admin />
                </Tabs.Panel>
                <Tabs.Panel value="codes_promo">
                    {donnees ?
                        (
                            <Codes_promo_admin statuts={donnees} />
                        ) : (
                            <Loading_div />
                        )
                    }

                </Tabs.Panel>
                <Tabs.Panel value="prixSMS">
                    {activeTab === "prixSMS" && (
                        <PrixSMSAdmin />
                    )}

                </Tabs.Panel>
            </Tabs>


            <Modal opened={cellules_modale !== null} onClose={() => {setCellules_modale(null)}} title="Cellules utilisant cet abonnement">
                <TextInput
                    placeholder={"Recherche organisation..."}
                    value={modale_cellules_recherche}
                    onChange={e => setModale_cellules_recherche(e.target.value)}
                />
                {(() => {
                    if (cellules_modale){
                        let orgas: { nom: string; zones: string[] }[] = []
                        cellules_modale?.map(cell => {
                            let cette_orga = orgas.filter(o => o.nom.includes((cell.zone.organisation.nom)))
                            if (cette_orga.length === 0){
                                orgas.push({
                                    "nom" : cell.zone.organisation.nom,
                                    "zones": [cell.zone.nom]
                                })
                            } else {
                                if (!cette_orga[0].zones.includes(cell.zone.nom)){
                                    orgas = orgas.map(o => {
                                        if (o.nom === cette_orga[0].nom)
                                        {
                                            o.zones.push(cell.zone.nom)
                                        }
                                        return o;
                                    })
                                }
                            }
                        })
                        return (
                            // eslint-disable-next-line react/jsx-no-undef
                            <Accordion chevronPosition="left" defaultValue="customization">
                                {orgas.filter(o => texte_est_inclus(o.nom, modale_cellules_recherche)).map(orga => (
                                    <Accordion.Item value={orga.nom}>
                                        <Accordion.Control>{orga.nom}</Accordion.Control>
                                        <Accordion.Panel className={"accordion-flex-row"}>
                                            {orga.zones.map(zone => {
                                                return (
                                                    <>
                                                        <p style={{width: "100%"}}>{zone}</p>
                                                        {cellules_modale?.filter(
                                                            c => c.zone.organisation.nom === orga.nom
                                                            && c.zone.nom === zone
                                                        ).map(cell_zones => (
                                                            <Link to={"/cellule/" + cell_zones.id} className={"inline-tag"}>{cell_zones.nom}</Link>
                                                        ))}
                                                    </>
                                                )
                                            })}
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        )

                    }
                })()}
            </Modal>





        </div>


    );
}
